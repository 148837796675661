import {Icon} from "@hoylu/client-common";
import React, {useMemo} from "react";
import {HorizontalDivider} from "./HorizontalDivider";
import {getAllWorkspaces} from "../state/workspaces/workspaces.selector";
import {connect} from "react-redux";
import {RootState} from "typesafe-actions";
import WorkspaceCard from "./cards/WorkspaceCard/WorkspaceCard";

//TODO: Waiting for final design and missing icon, rotate arrow right is not ultimate approach :)
export const TeamsSidePanel: React.FunctionComponent<TeamsSidePanelProps> = ({workspaces}) => {

  const liveWorkspace = useMemo(() => workspaces.find(w => w.isLive), [workspaces]);
  if (!liveWorkspace) return <></>;
  return (
    <div className={"flex flex-col justify-start items-center gap-2 w-full h-full p-2"} style={{background: "#252732"}}>
      <Icon icon={"hoylu-ui-icons-arrowup"} style={{color: "#FA607B"}} disabled={true}/>
      <span style={{color:"#B4B0C3"}}>Use share button to share workspace in meeting</span>
      <HorizontalDivider/>
      <span style={{color:"white"}}>Selected Hoylu Workspace:</span>
      <WorkspaceCard workspaceDetails={liveWorkspace}
                     hide={true}
                     order={liveWorkspace.order}
                     key={liveWorkspace.workspaceId}
                     editWorkspaceAction={() => {}}

      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  workspaces: getAllWorkspaces(state),
});

type TeamsSidePanelProps = ReturnType<typeof mapStateToProps>
export default connect(mapStateToProps)(TeamsSidePanel)