import React, {Fragment} from "react";
import {Localized} from "../../../strings";
import {ShareOptionValue} from "./ShareType";

export type HeaderPermissionsProps = {
  enabledOption: ShareOptionValue;
  text: string;
  publicPermissionLevel?: ShareOptionValue;
}

export const HeaderPermissions: React.FC<HeaderPermissionsProps> = ({
  enabledOption,
  text,
  publicPermissionLevel
}) => {
  const strings = Localized.object("PERMISSION_BAR");

  const computeStyle = (option: ShareOptionValue) => {
    let isNotSelectedStyle = "bg-dark-primary-selection cursor-pointer text-light-primary ";
    if (!publicPermissionLevel || enabledOption.value >= publicPermissionLevel.value) {
      if (option === enabledOption) {
        return option.color;
      } else {
        return isNotSelectedStyle;
      }
    }

    if (option === publicPermissionLevel) {
      return option.color;
    } else if (option === enabledOption) {
      return isNotSelectedStyle + 'border border-solid ' + option.border;
    } else {
      return isNotSelectedStyle;
    }
  };
  const accessText: string = `${text} ${strings.HAS}`;
  return (
    <div className="flex flex-row">
      <div className="flex w-auto text-sm items-center">
        <div title={accessText}>{accessText}</div>
        <div className="flex items-center">
          <div className="text-black mr-1 sm:mx-2 flex">
            <Fragment>
              <button
                className={`rounded-md sm:rounded-lg h-6 w-6 ml-1 sm:mx-0 sm:w-12 cursor-default ${computeStyle(enabledOption)}`}
                disabled={true}>
                <div className="hidden sm:block">
                  {Localized.string(enabledOption.title)}
                </div>
              </button>
            </Fragment>
          </div>
          <div>{strings.ACCESS}</div>
        </div>
      </div>
    </div>
  );
};