import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { Modal } from '../Modal';
import { clearError } from '../../../state/mode/mode.actions';
import { Localized } from '../../../strings';

const mapStateToProps = (state: RootState) => ({
  errorMessage: checkIfDefaultError(state.context.error.message)
});

const mapDispatchToProps = {
  clearError
};

type ErrorDialogProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export const ErrorDialog: React.FC<ErrorDialogProps> = ({
  clearError,
  errorMessage
}) => {
  const strings = Localized.object("ERROR_DIALOG");
  return (
    <Modal>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-2xl">
          {errorMessage}
        </h1>
        <button
          autoFocus
          className="btn btn-primary float-right"
          onClick={() => clearError()}
        >
          {strings.OK}
        </button>
      </div>
    </Modal>
  );
};

function checkIfDefaultError(errorMessage: string) {
  if (errorMessage === "Something went wrong, our engineers are working on it!") {
    return Localized.string("ERROR.SOMETHING_WENT_WRONG")
  }
  return errorMessage;
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog);
