import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { cancelDashboardOption } from '../../state/workspaces/workspaces.actions';
import { getSelectedWorkspaceDetails } from '../../state/workspaces/workspaces.selector';
import { Localized } from '../../strings';
import {Modal} from "./Modal";
import {CloseIcon} from "./modalElements/CloseIcon";
import {Button, TextInput} from "@hoylu/client-common";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state)
});

const mapDispatchToProps = {
  cancelDashboardOption,
};

type ChangeMasterDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ChangeMasterDialog: React.FC<ChangeMasterDialogProps> = ({
  cancelDashboardOption
}) => {
  const strings = Localized.object("CHANGE_MASTER_DIALOG");

  const changeMaster = () => {};

  return (
    <Modal
      handleOutsideClick={cancelDashboardOption}
      padding={"p-3"}
      allowContentToOverflow={true}
    >
      <div className="w-100 h-auto" data-test-id="remove-template-dialog">
        <div className="w-full flex justify-between items-center font-lato mb-3">
          <div className="text-indicator font-bold text-base ">{strings.TITLE}</div>
          <CloseIcon onClose={cancelDashboardOption}/>
        </div>
        <div className={"flex flex-col gap-2 w-full font-lato flex text-sm p-2"}>
          <span>{strings.SUBTITLE}</span>
          <TextInput label={""} style={{ width: "100%" }} inputStyle={{ paddingBottom: "4px" }}/>
        </div>
        <div className={"flex mt-4 justify-end"}>
          <Button
            onClick={cancelDashboardOption}
            type="secondary"
            dataTestId={strings.CANCEL}
          >
            {strings.CANCEL}
          </Button>
          <Button
            type={"primary"}
            onClick={changeMaster}
            dataTestId={"change-linked-master"}
          >
            {strings.CHANGE_MASTER}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeMasterDialog);
