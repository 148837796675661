import React, { FormEvent, useState } from "react";
import { connect } from "react-redux";
import type { RootState } from "typesafe-actions";
import { duplicateApiSupportsPassword } from "../../state/config/config.selector";
import {
  isPasswordRequired,
  workspacesPrivateByDefault,
} from "../../state/user/user.selector";
import type { WorkspaceDetails } from "../../state/workspaces/types";
import {
  cancelDashboardOption,
  duplicateWorkspace,
} from "../../state/workspaces/workspaces.actions";
import { getSelectedWorkspaceDetails } from "../../state/workspaces/workspaces.selector";
import { Localized } from "../../strings";
import { Checkbox } from "../Checkbox";
import { Modal } from "./Modal";
import { GlobalAccess } from "../../services/workspaces/global.access";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
  isPasswordRequired: isPasswordRequired(state),
  workspacesPrivateByDefault: workspacesPrivateByDefault(state),
  duplicateApiSupportsPassword: duplicateApiSupportsPassword(state),
});

const mapDispatchToProps = {
  cancelDashboardOption,
  duplicateWorkspace: duplicateWorkspace.request,
};

type DuplicateProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const Duplicate: React.FC<DuplicateProps> = ({
  cancelDashboardOption,
  duplicateWorkspace,
  selectedWorkspaceDetails,
  isPasswordRequired,
  workspacesPrivateByDefault,
  duplicateApiSupportsPassword,
}) => {
  const strings = Localized.object("DUPLICATE_WORKSPACE_DIALOG");

  if (!selectedWorkspaceDetails) return null;

  return (
    <DuplicateWorkspaceBaseDialog
      cancel={cancelDashboardOption}
      apply={(params) =>
        duplicateWorkspace({
          workspaceId: selectedWorkspaceDetails.workspaceId,
          ...params,
        })
      }
      selectedWorkspaceDetails={selectedWorkspaceDetails}
      iconUrl={require("../../assets/images/icons/copy.svg")}
      isPasswordRequired={isPasswordRequired}
      workspacesPrivateByDefault={workspacesPrivateByDefault}
      duplicateApiSupportsPassword={duplicateApiSupportsPassword}
      strings={{
        shortTitle: strings.COPY_THIS_WORKSPACE,
        longTitle: `${strings.MAKE_COPY} "${selectedWorkspaceDetails.workspaceName}"`,
        newWorkspaceNameLabel: strings.NEW_WORKSPACE_NAME,
        newWorkspaceNameTooltip: strings.NEW_WORKSPACE_NAME,
        newWorkspaceNameDefault: `${strings.COPY_OF} "${selectedWorkspaceDetails.workspaceName}"`,
        passwordLabel: strings.PASSWORD,
        passwordTooltip: strings.PASSWORD,
        applyButtonText: strings.DUPLICATE,
        cancelButtonText: strings.CANCEL,
        newPasswordLabel: strings.NEW_PASSWORD,
        showPassword: strings.SHOW_PASSWORD,
        characters: strings.CHARACTERS,
        privateWorkspace: strings.PRIVATE_WORKSPACE,
        privateWorkspaceTooltip: strings.PRIVATE_WORKSPACE_TOOLTIP,
      }}
    />
  );
};

type ApplyParams = {
  workspaceName: string,
  globalAccess: GlobalAccess,
  password?: string
  newPassword?: string
};

export type DuplicateWorkspaceDialogProps = {
  cancel: () => void;
  apply: (params: ApplyParams) => void;
  alternate?: () => void;
  selectedWorkspaceDetails: WorkspaceDetails;
  iconUrl?: string; // This should be replaced by icon font for duplicate once we get the icon placement fixed
  isPasswordRequired: boolean;
  workspacesPrivateByDefault: boolean;
  duplicateApiSupportsPassword: boolean;
  strings: {
    shortTitle: string;
    longTitle: string;
    newWorkspaceNameLabel: string;
    newWorkspaceNameTooltip: string;
    newWorkspaceNameDefault: string;
    passwordLabel: string;
    passwordTooltip: string;
    applyButtonText: string;
    cancelButtonText: string;
    alternateButtonText?: string;
    alternateButtonTooltip?: string;
    newPasswordLabel: string;
    showPassword: string;
    characters: string;
    privateWorkspace: string;
    privateWorkspaceTooltip: string;
  };
};

// Note: This piece of duplicate workspace dialog is also used by CreateWorkspaceFromTemplateDialog
export const DuplicateWorkspaceBaseDialog: React.FC<DuplicateWorkspaceDialogProps> =
  ({
    cancel,
    apply,
    alternate,
    selectedWorkspaceDetails,
    iconUrl,
    isPasswordRequired,
    workspacesPrivateByDefault,
    duplicateApiSupportsPassword,
    strings,
  }) => {
    const [passwordField, setPasswordField] = useState("");
    const [isPrivate, setIsPrivate] = useState(workspacesPrivateByDefault);
    const [newPassword, setPassword] = useState("");
    const [showPassword, showPasswordCall] = useState(false);

    const submitAction = (e: FormEvent) => {
      e.preventDefault();

      const form = new FormData(e.target as HTMLFormElement);
      let name = form.get("newName")?.toString();

      let password = "";
      if (selectedWorkspaceDetails?.hasPassword) {
        password = form.get("password")?.toString() || "";
      }

      apply({
        workspaceName: name || "",
        password,
        globalAccess: isPrivate ? GlobalAccess.NONE : GlobalAccess.WRITE,
        newPassword
      });
      setPasswordField("");
    };

    return (
      <Modal>
        <div className="flex flex-col">
          <div className="flex pb-4">
            {iconUrl && (
              <img
                className="pr-2 w-10"
                src={iconUrl}
                alt={strings.shortTitle}
              />
            )}
            <h1 className="contents lg:hidden xxs:text-lg xs:text-xl sm:text-2xl">
              {strings.shortTitle}
            </h1>
            <h1 className="hidden lg:block text-2xl truncate">
              {strings.longTitle}
            </h1>
          </div>

          <form onSubmit={submitAction}>
            <div className="input-container modal-component">
              <label className="input-label">
                {strings.newWorkspaceNameLabel}
              </label>
              <input
                className="input"
                type="text"
                autoFocus
                name="newName"
                title={strings.newWorkspaceNameTooltip}
                defaultValue={strings.newWorkspaceNameDefault}
                onFocus={(e) => {
                  e.target.select();
                }}
              />
            </div>
            {selectedWorkspaceDetails?.hasPassword && (
              <div className="input-container modal-component mt-2">
                <label className="input-label">{strings.passwordLabel}</label>
                <input
                  className="input"
                  type="password"
                  autoFocus
                  name="password"
                  value={passwordField}
                  title={strings.passwordTooltip}
                  onChange={(e) => setPasswordField(e.target.value)}
                />
              </div>
            )}
            {isPasswordRequired && duplicateApiSupportsPassword && (
              <div className="mt-2 modal-component input-container">
                <div className="">
                  <label className="input-label" htmlFor="password">
                    {strings.newPasswordLabel}
                  </label>
                  <div className="flex justify-between">
                    <input
                      className="input"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      autoFocus
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <img
                      className="mr-2 mb-1"
                      src={
                        showPassword
                          ? require("../../assets/images/icons/eyeclosed.svg")
                          : require("../../assets/images/icons/eyeopen.svg")
                      }
                      onClick={() => showPasswordCall(!showPassword)}
                      alt={strings.showPassword}
                    />
                  </div>
                </div>
                <div className="text-light-primary italic text-xs">
                  {strings.characters}
                </div>
              </div>
            )}
            {duplicateApiSupportsPassword && (
              <div title={strings.privateWorkspaceTooltip} className="mt-4 ml-2 flex items-center xxs:text-sm xs:text-base">
                <Checkbox
                  checked={isPrivate}
                  onChange={() => setIsPrivate((prevState) => !prevState)}
                />
                <label className="ml-1" htmlFor="private">
                  {strings.privateWorkspace}
                </label>
              </div>
            )}
            <div>
              <button
                className="btn btn-primary mt-4 float-right"
                type="submit"
                title={strings.applyButtonText}
              >
                {strings.applyButtonText}
              </button>
              <button
                className="btn btn-negative mt-4 float-right"
                onClick={cancel}
                type="button"
                title={strings.cancelButtonText}
              >
                {strings.cancelButtonText}
              </button>
              {strings.alternateButtonText && alternate && (
                <button
                  className="btn btn-secondary mt-4 float-right"
                  onClick={alternate}
                  type="button"
                  title={strings.alternateButtonTooltip}
                >
                  {strings.alternateButtonText}
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    );
  };

export default connect(mapStateToProps, mapDispatchToProps)(Duplicate);
