import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import {
  cancelDashboardOption,
  populateAllWorkspaces,
  updateWorkspaceLabels,
} from "../../state/workspaces/workspaces.actions";
import {
  getAllLabels,
  getSelectedWorkspaceDetails,
} from "../../state/workspaces/workspaces.selector";
import { Localized } from "../../strings";
import { Modal } from "./Modal";
import { CloseIcon } from "./modalElements/CloseIcon";
import { WorkspaceLabels } from "../../state/workspaces/types";
import { EditLabelContent } from "./EditLabelContent";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
  allLabels: getAllLabels(state),
});

const mapDispatchToProps = {
  cancelDashboardOption,
  updateLabels: updateWorkspaceLabels.request,
};

type DuplicateProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const EditLabel: React.FC<DuplicateProps> = ({
  cancelDashboardOption,
  allLabels,
  selectedWorkspaceDetails,
  updateLabels,
}) => {
  const strings = Localized.object("EDIT_LABEL_DIALOG");

  // on component load, check to see if all ws have been loaded, if not, make call to populate all to ensure we have all workspace labels
  const isLoadingAll = useSelector((state: RootState) => state.context.workspaces.isLoadingAll)
  const hasPopulatedAllWorkspaces = useSelector((state: RootState) => state.context.workspaces.hasPopulatedAllWorkspaces)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!hasPopulatedAllWorkspaces) {
      dispatch(populateAllWorkspaces.request())
    }
  });

  const requestUpdateLabels = (labels: WorkspaceLabels) => {
    updateLabels({
      workspaceId: selectedWorkspaceDetails!.workspaceId,
      labels,
    });
  };

  return (
    <Modal padding="p-3" handleOutsideClick={cancelDashboardOption}>
      <div className="flex flex-col font-lato xxs:w-full sm:w-100 sm:max-w-100">
        <div className="flex pb-3">
          <h1 className="text-base font-bold leading-8 truncate text-indicator">
            {strings.LABELS}
          </h1>
          <CloseIcon onClose={cancelDashboardOption} />
        </div>
        {!isLoadingAll ? (
          <EditLabelContent
          allLabels={allLabels}
          selected={selectedWorkspaceDetails?.labels}
          updateSelected={requestUpdateLabels}
        />
        ) : 
        <h1 className="text-base font-bold leading-8 truncate text-indicator">
            {strings.LOADING_WORKSPACES}
        </h1>
      }
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLabel);
