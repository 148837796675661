export enum DashboardOption {
  HIDE = "HIDE",
  LEAVE = "LEAVE",
  DELETE = "DELETE",
  REMOVE = "REMOVE",
  SHARE = "SHARE",
  ADVANCED_SHARE = "ADVANCED_SHARE",
  CREATE = "CREATE",
  OPEN = "OPEN",
  OPTIONS = "OPTIONS",
  OPTIONS_FAVORITE = "OPTIONS_FAVORITE",
  UPSELL = "UPSELL",
  DUPLICATE = "DUPLICATE",
  ADD_LABEL = "ADD_LABEL",
  ASSIGN_PROJECT = "ASSIGN_PROJECT",
  INVITE = "INVITE",
  PERMISSIONS = "PERMISSIONS",
  REMOVE_FROM_PROJECT = "REMOVE_FROM_PROJECT",
  ASSET_PANEL = "ASSET_PANEL",
  CREATE_FROM_TEMPLATE = "CREATE_FROM_TEMPLATE",
  DOWNLOAD = "DOWNLOAD",
  UPLOAD = "UPLOAD",
  INFO = "INFO",
  MANAGE_ACCOUNT="MANAGE_ACCOUNT",
  CONFIRM_ACCOUNT_DELETE="CONFIRM_ACCOUNT_DELETE",
  REQUESTING_SNAPSHOT = "REQUESTING_SNAPSHOT",
  LINKED_MASTER = "LINKED_MASTER",
  CHANGE_MASTER = "CHANGE_MASTER",
  DELETE_TEMPLATE = "DELETE_TEMPLATE",
  FILTER = "FILTER",
  SEARCH_WORKSPACE = "SEARCH_WORKSPACE"
}
