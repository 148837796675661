import React from "react";

type ButtonProps = {
  label: string,
  onButtonClick: (e?: any) => void,
  disabled?: boolean,
  additionalStyling?: string,
  children?: React.ReactNode
}

// TODO: Remove this or ModalButton component once we switch to new design. Also update below comment.
/** This is substitute for ModalButton component. It uses new button styles */
export const Button: React.FC<ButtonProps> = ({
  label,
  onButtonClick,
  disabled,
  additionalStyling = "btn-secondary",
  children
}) => {
  return (
    <button
      className={"truncate new-btn " + additionalStyling}
      disabled={disabled}
      type="submit"
      title={label}
      onClick={onButtonClick}>
      {children}
    </button>
  );
};

