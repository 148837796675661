import React, { useEffect, useState } from "react";
import { Localized } from "../../../strings";
import { Dropdown, Option } from "../../Dropdown";

type ConfigurationProps = {
  updateConfiguration: (configuration: AgilePlanningConfigurationProps) => any;
};

type AgilePlanningConfigurationProps = {
  preset: string;
};

export const AgilePlanningConfiguration: React.FC<ConfigurationProps> = ({
  updateConfiguration
}) => {
  const strings = Localized.object("AGILE_PLANNING");

  let [configuration, setConfiguration] = useState<AgilePlanningConfigurationProps>({
    preset: "kanban"
  });

  useEffect(() => {
    updateConfiguration(configuration);
  }, [configuration])


  const options: Option<string>[] = [
    { label: "Kanban", value: "kanban"},
    { label: "PI Planning", value: "piplanning"},
  ]

  return (
    <div className="inline-flex justify-between items-baseline mt-4 ml-1 w-full">
      <label htmlFor="columns" className="pl-1">
        {strings.PRESET}
      </label>
      <Dropdown
        value={configuration.preset}
        label={"Preset"}
        options={options}
        onChange={(preset => setConfiguration({preset}))}
      />
    </div>
  );
};
