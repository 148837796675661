import LazyLoad from "react-lazy-load";
import React, {useState} from "react";
import {RootState} from "typesafe-actions";
import {connect} from "react-redux";
import {WorkspaceDetails} from "../../state/workspaces/types";
import * as workspaceActions from "../../state/workspaces/workspaces.actions";
import {DashboardOption} from "../../state/workspaces/DashboardOption";
import {getProjectFromIdFillingUnkownOnes} from "../../state/workspaces/projects.selector";
import CustomWorkspacePreview, {CustomWorkspacePreviewProps} from "./WorkspaceCard/CustomWorkspacePreview";
import DefaultWorkspacePreview from "./WorkspaceCard/DefaultWorkspacePreview";

const SimpleWorkspaceCard = ( {children, ...props}: React.PropsWithChildren<Omit<CustomWorkspacePreviewProps, "setIsVisible">> ) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div
      className={"rounded-lg overflow-hidden font-body shadow-lg"}
      style={{ height: "170px" }}
    >
      <div
        className={"relative pointer-events-none"}
        style={{ width: "200px", height: "130px" }}
      >
        <LazyLoad>
          <CustomWorkspacePreview {...props} setIsVisible={setIsVisible} />
        </LazyLoad>
        {!isVisible && <DefaultWorkspacePreview {...props} />}
      </div>
      <div
        className={`p-2 bg-theme-thumbnails-footer text-theme-primary-text w-full h-1/4 flex items-center pointer-events-auto`}
        style={{ maxWidth: "200px" }}
      >
        {children}
      </div>
    </div>
  );
}


const mapStateToProps = (
  state: RootState,
  ownProps: { workspaceDetails: WorkspaceDetails }
) => ({
  token: state.context.user.token,
  showProjectOptions: state.context.config.featureFlags.projectLicensing,
  showWorkspaceWeight: state.context.config.featureFlags.showWorkspaceWeight,
  project: getProjectFromIdFillingUnkownOnes(
    state,
    ownProps?.workspaceDetails?.containerId
  ),
});

const mapDispatchToProps = {
  activateDashboardOption: workspaceActions.activateDashboardOption,
  toggleWorkspaceFavorite: workspaceActions.markWorkspaceAsFavorite
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: React.PropsWithChildren<{
    workspaceDetails: WorkspaceDetails
  }>
) => {
  return {
    ...stateProps,
    ...ownProps,
    showEditLabelPrompt: () =>
      dispatchProps.activateDashboardOption({
        optionType: DashboardOption.ADD_LABEL,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      }),
    setWorkspaceFavorite: () =>
      dispatchProps.toggleWorkspaceFavorite(
        ownProps.workspaceDetails.workspaceId
      ),
    canEditLabels: ownProps.workspaceDetails.isAdmin,
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SimpleWorkspaceCard)