import { useDispatch } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "./Modal";
import { cancelDashboardOption } from "../../state/workspaces/workspaces.actions";
import { Localized } from "../../strings";
import {usePasteOnlyEmail, useValidatedState} from "@hoylu/client-common";
import { Button } from "./modalElements/Button";
import { inviteUsers } from "../../state/user/user.actions";
import { CloseButton } from "./modalElements/CloseButton";
import { emailRegex } from "../../utils/emailRegex";


const reg = /\s+/;
export const InviteUserDialog = () => {
  const dispatch = useDispatch();
  const strings = Localized.object("INVITE_USERS");

  const ref = usePasteOnlyEmail(" ");
  const emailValidator = (emails: string[]|undefined) => {
    if (emails && !emails?.every(e => !!e.match(emailRegex))){
      return strings.ERR;
    }
  }

  const quantityValidator = (emails: string[]|undefined) => {
    if (emails && emails.length > 10){
      return strings.ERR_QTY
    }
  }

  const [emailList, setEmailList, emailListErr] = useValidatedState<string[]>([], [emailValidator, quantityValidator]);
  const [emails, setEmails] = useState("");

  useEffect(() => {
      setEmailList(emails.split(reg).filter(e => e))
  }, [emails])

  const inviteUsersHandler = () => {
    dispatch(inviteUsers.request({emails: emailList, reason: "Invite to join Hoylu"}))
    dispatch(cancelDashboardOption())
  }

  const textAreaStyle = useMemo(() => {
    const style = ["bg-dark-selected textarea rounded p-4 mt-3 mb-3/2 h-48 w-full"]
    if (emailListErr){
      style.push("border-solid border-2 border-red-600")
    } else {
      style.push("border-none")
    }
    return style;
  }, [emailListErr])

  return (
    <Modal handleOutsideClick={() => dispatch(cancelDashboardOption())}>
      <div className="relative">
        <div className="close-container">
          <h1 className="text-base pb-2">{strings.INVITE_USERS_HEADER}</h1>
          <CloseButton closeModal={() => dispatch(cancelDashboardOption())} style="text-accent pb-4 w-4" />
        </div>
        <div className="flex items-center text-sm whitespace-pre-wrap xxs:pr-1 sm:pr-16">
          {strings.INVITE_USERS_DESCRIPTION}
        </div>
        <textarea
          ref={ref}
          className={
            textAreaStyle.join(" ")
          }
          value={emails}
          onChange={(e) => {
            setEmails(e.target.value);
          }}
          title={emailListErr ?? strings.HINT}
        />
        <div className={"flex items-center justify-end text-sm"}>
          <Button
            label={strings.CANCEL}
            onButtonClick={() => dispatch(cancelDashboardOption())}
            additionalStyling="btn-negative mt-0 mb-0 "
          >
            {strings.CANCEL}
          </Button>
          <Button
            label={strings.INVITE}
            onButtonClick={() => inviteUsersHandler()}
            disabled={emailList.length===0 || !!emailListErr}
            additionalStyling={
              "btn-primary mt-0 mb-0 mr-0 "
            }
          >
            {strings.INVITE}
          </Button>
        </div>
      </div>
    </Modal>)
}