import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { Root } from "react-dom/client";
import { Provider, connect } from "react-redux";
import { Store } from "redux";
import { NotificationsContextProvider } from "@hoylu/notifications";
import { RootState } from "typesafe-actions";
import { login } from "../state/user/user.actions";
import LoginPrompt from "./prompts/LoginPrompt";
import { isWorkspaceFullscreen } from "../state/workspaces/workspaces.selector";
import Modals from "./modals";
import WorkspaceContent from "./WorkspaceContent";
import { AppMode } from "../state/mode/mode.reducer";
import BetaDisabledContent from "./BetaDisabledContent";
import AccountVerificationError from "./AccountVerificationError";
import { Localized } from "../strings";
import { getAppColorScheme, setAppColorScheme } from "../utils/color.scheme";
import { ThemeContext } from "./ThemeContext";
import { WorkspaceLoading } from "./WorkspaceLoading";
import SimplifiedHeader from "./Header/SimplifiedHeader";
import { getSelectedProjectColor } from "../state/workspaces/projects.selector";
import { hexToRgba } from "../utils/hexToRgba";
import { notificationsService } from "../state/config/config.selector";
import { userToken } from "../state/user/user.selector";

const App: React.FC<AppProps> = ({
  isLoggedIn,
  showWorkspaceLoadingOverlay,
  isWorkspaceFullscreen,
  projectThemeColor,
  appMode,
  notificationServiceUrl,
  token
}) => {
  const [theme, setTheme] = useState(getAppColorScheme());
  const strings = Localized.object("APP");
  // We aim to dynamically adjust the app's content background gradient according to the chosen project color.
  const gradient = useMemo(() => projectThemeColor
      ? ({
        backgroundImage: `linear-gradient(to bottom,
          ${hexToRgba(projectThemeColor)} 0%,
          var(--gradient-from-color-theme) 50%,
          var(--gradient-to-color-theme) 100%
        )`
      })
      : ({
        backgroundImage: "linear-gradient(to bottom, var(--gradient-color-stops))"
      }),
    [projectThemeColor]) as CSSProperties;

  useEffect(() => {
    setAppColorScheme(theme);
  }, [theme]);

  useEffect(() => {
    const html = document.getElementsByTagName("html")[0]
    if (isWorkspaceFullscreen){
      html.className = "overflow-hidden"
    } else {
      html.className = "overflow-auto"
    }
  }, [isWorkspaceFullscreen]);

  if (appMode === AppMode.BETA_NOT_ALLOWED) return <BetaDisabledContent />;

  if (
    [
      AppMode.NOT_VERIFIED_POLICY_RESTRICTED,
      AppMode.SKIPPABLE_VERIFIED_POLICY_RESTRICTED,
    ].includes(appMode)
  )
    return <AccountVerificationError />;

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <NotificationsContextProvider serverUrl={notificationServiceUrl} token={token}>
        <div
          data-test-id="app-root"
          className={
            `${theme} flex flex-col bg-fixed min-h-full` + (isWorkspaceFullscreen || isLoggedIn === "UNKNOWN"
              ? "h-screen overflow-hidden"
              : "")
          }
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => e.preventDefault()}
          style={gradient}
        >
          <Modals />
          {isLoggedIn === "UNKNOWN" && (
            <>
              <SimplifiedHeader />
              <div className="text-primary-text flex justify-center items-center w-full bg-dark-primary overflow-hidden h-screen">
                {strings.LOADING_YOUR_WORKSPACE}
              </div>
            </>
          )}
          {isLoggedIn !== "UNKNOWN" &&
            (isLoggedIn ? <WorkspaceContent /> : <LoginPrompt />)}
          {showWorkspaceLoadingOverlay && <WorkspaceLoading />}
        </div>
      </NotificationsContextProvider>
    </ThemeContext.Provider>
  );
};

const mapStateToProps = (state: RootState) => ({
  appMode: state.mode,
  isLoggedIn: state.context.user.isLoggedIn,
  isWorkspaceFullscreen: isWorkspaceFullscreen(state),
  projectThemeColor: getSelectedProjectColor(state),
  showWorkspaceLoadingOverlay:
    (!!state.context.workspaces.waitingToEditID ||
      state.context.workspaces.isCreatingNewWorkspaceUrl) &&
    (state.mode === AppMode.LOADING_DASHBOARD ||
      state.mode === AppMode.DASHBOARD),
  notificationServiceUrl: notificationsService(state),
  token: userToken(state)
});

const mapDispatchToProps = {
  login: login.request
};

type AppProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export function renderApp(rootElement: Root, reduxStore: Store) {
  rootElement.render(
    <Provider store={reduxStore}>
      <ConnectedApp />
    </Provider>
  );
}

export default renderApp;
