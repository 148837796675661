import React, { useEffect, useReducer, useRef } from "react";
import { Modal } from "../Modal";
import { connect } from "react-redux";
import { Localized } from "../../../strings";
import { Progress } from "./Progress";
import { SelectFile } from "./SelectFile";
import { Error } from "./Error";
import {
  cancelDashboardOption,
  queueEditWorkspace,
} from "../../../state/workspaces/workspaces.actions";
import { parseNDJSON, readableStreamToAsyncGenerator } from "./uploadUtils";
import { CloseIcon } from "../modalElements/CloseIcon";
import { RootState } from "typesafe-actions";
import { transferService } from "../../../state/config/config.selector";
import { authorizationHeader } from "../../../state/user/user.selector";

const mapStateToProps = (state: RootState) => ({
  transferServiceUrl: transferService(state),
  authHeader: authorizationHeader(state),
  fetchApi: window.fetch,
});

const mapDispatchToProps = {
  openWorkspace: queueEditWorkspace,
  cancelDashboardOption,
};

type UploadDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const UploadDialog: React.FC<UploadDialogProps> = ({
  openWorkspace,
  cancelDashboardOption,
  transferServiceUrl,
  authHeader,
  fetchApi,
}) => {
  const strings = Localized.object("UPLOAD_DIALOG");

  const [state, dispatch] = useReducer(uploadReducer, {
    stage: UploadStage.SELECT,
  });

  const abortControllerRef = useRef<AbortController | null>(null);
  const isActive = useRef(true);
  useEffect(() => {
    return () => {
      isActive.current = false;
      abortControllerRef.current?.abort();
    };
  }, []);

  const onSubmit = async (file: File) => {
    abortControllerRef.current = new AbortController();
    dispatch(startUpload(file.name));

    const formData = new FormData();
    formData.append("file", file);
    const init: RequestInit = {
      method: "POST",
      mode: "cors",
      body: formData,
      headers: authHeader,
      signal: abortControllerRef.current.signal,
    };
    try {
      const response = await fetchApi(
        `${transferServiceUrl}/upload/Progress/`,
        init
      );

      if (!response.ok) {
        if (isActive.current) dispatch(error());
        console.warn(response.statusText);
        return;
      }

      const progressStream = response
        .body!.pipeThrough(new TextDecoderStream())
        .pipeThrough(parseNDJSON());
      const progressSteps = readableStreamToAsyncGenerator(progressStream);

      for await (const step of progressSteps) {
        if (!isStreamStep(step)) continue;
        if (!isActive.current) return;
        switch (step.type) {
          case "progress":
            dispatch(progress(1, step.value));
            break;
          case "complete":
            abortControllerRef.current = null;
            openWorkspace({ workspaceId: step.value.workspaceId });
            break;
          case "error":
            abortControllerRef.current = null;
            dispatch(error());
            console.warn(step);
            return;
          default:
            break;
        }
      }
    } catch (e) {
      abortControllerRef.current = null;
      if (isActive.current) dispatch(error());
      console.warn(e);
    }
  };

  return (
    <Modal>
      <div
        className="flex flex-col relative justify-center items-center"
        onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
          if (state.stage !== UploadStage.SELECT) return;
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e: React.DragEvent<HTMLDivElement>) => {
          if (state.stage !== UploadStage.SELECT) return;
          e.preventDefault();
          e.stopPropagation();

          if (
            e.dataTransfer.files.length !== 1 ||
            e.dataTransfer.files[0].type !== "application/zip"
          ) {
            console.warn("invalid upload");
            return;
          }
          onSubmit(e.dataTransfer.files[0]);
        }}
      >
        <div className="flex pb-3">
          <h1 className="block text-2xl text-center whitespace-no-wrap truncate break-all w-full">
            {strings.UPLOADING_WORKSPACE}
          </h1>
          <CloseIcon onClose={cancelDashboardOption} />
        </div>
        {state.stage === UploadStage.SELECT && (
          <SelectFile onSubmit={onSubmit} strings={strings} />
        )}
        {state.stage === UploadStage.PROGRESS && (
          <Progress strings={strings} {...state} />
        )}
        {state.stage === UploadStage.ERROR && <Error strings={strings} />}
      </div>
    </Modal>
  );
};

enum UploadStage {
  SELECT,
  PROGRESS,
  ERROR,
}

enum UploadActionType {
  START = "START",
  PROGRESS = "PROGRESS",
  ERROR = "ERROR",
}

const progress = (step: number, progress: number) => ({
  type: UploadActionType.PROGRESS as const,
  payload: {
    step,
    progress,
  },
});
function startUpload(title: string) {
  return { type: UploadActionType.START as const, payload: { title } };
}
function error() {
  return { type: UploadActionType.ERROR as const, payload: undefined };
}

type UploadAction =
  | ReturnType<typeof startUpload>
  | ReturnType<typeof progress>
  | ReturnType<typeof error>;

type UploadState =
  | { stage: UploadStage.SELECT | UploadStage.ERROR }
  | {
      stage: UploadStage.PROGRESS;
      title: string;
      step: number;
      progress: number;
    };

function uploadReducer(state: UploadState, action: UploadAction): UploadState {
  if (action.type === UploadActionType.ERROR)
    return { stage: UploadStage.ERROR };

  switch (state.stage) {
    case UploadStage.SELECT:
      if (action.type === UploadActionType.START) {
        return {
          stage: UploadStage.PROGRESS,
          title: action.payload.title,
          progress: 0,
          step: 0,
        };
      }
      return state;
    case UploadStage.PROGRESS:
      if (action.type === UploadActionType.PROGRESS) {
        return { ...state, ...action.payload };
      }
      return state;
  }

  return state;
}

type ProgressStep = { type: "progress"; value: number };
type CompleteStep = { type: "complete"; value: { workspaceId: string } };
type ErrorStep = { type: "error"; value: string };
type StreamStep = ProgressStep | CompleteStep | ErrorStep;
const isStreamStep = (input: any): input is StreamStep =>
  input && "type" in input && "value" in input;

export default connect(mapStateToProps, mapDispatchToProps)(UploadDialog);
