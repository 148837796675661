import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PermissionsBar } from "./PermissionsBar";
import type {
  WorkspacePermissionsAndUsers,
  WorkspaceDetails,
  WorkspaceDetailsWithUserRoles,
} from "../../../state/workspaces/types";
import { sendEmail, sortUsers } from "../../../utils/sharing.helpers";
import { ShareOptionValue, ShareType, User } from "./ShareType";
import { Localized } from "../../../strings";
import { Button } from "../modalElements/Button";
import { Checkbox } from "../../Checkbox";

import { emailRegex } from "../../../utils/emailRegex";
import {
  Contact,
  FetchProps,
  makeContact,
  RecipientInput,
} from "../../RecipientInput";
import {
  importProjectUsers,
  inviteToWorkspace,
  removeWorkspaceUsers,
  upsertWorkspaceUsers,
} from "../../../state/workspaces/workspaces.actions";
import { useDispatch } from "react-redux";
import { UpsertWorkspaceUsersPermissions } from "../../../services/workspaces/api/workspaces.v3.types";
import { useLocalStorage } from "@hoylu/client-common";

const RECENT_EMAILS = "__recent_emails";

const userAccessOptions: ShareOptionValue[] = [
  ShareType.ADMIN,
  ShareType.EDIT,
  ShareType.READ,
  ShareType.NO_ACCESS,
];

type WorkspacePermissionsProps = {
  workspaceDetails: WorkspaceDetails;
  permissionLevelSets: WorkspacePermissionsAndUsers;
  email: string;
  publicPermissionLevel: ShareOptionValue;
  importProjectUsers: typeof importProjectUsers | undefined;
  isUpdatingWorkspace: boolean;
  permissionsIsOpen: boolean;
  shareLink: string;
  showOrgAdminPolicy: boolean;
  showProjectAdmin: boolean;
  selectedContacts: Contact[];
  setSelectedContacts: (contacts: Contact[]) => void;
  isAddingUsersFromExternalSource: boolean;
};

function shareTypeToUpsertPermission(shareType: ShareOptionValue) {
  switch (shareType) {
    case ShareType.ADMIN:
      return UpsertWorkspaceUsersPermissions.Administer;
    case ShareType.EDIT:
      return UpsertWorkspaceUsersPermissions.Write;
    case ShareType.READ:
      return UpsertWorkspaceUsersPermissions.Read;
    case ShareType.NO_ACCESS:
      return UpsertWorkspaceUsersPermissions.None;
    default:
      throw new Error(`Unknown share type ${shareType}`);
  }
}

export const WorkspacePermissions: React.FC<WorkspacePermissionsProps> = ({
  workspaceDetails,
  permissionLevelSets,
  email,
  publicPermissionLevel,
  importProjectUsers,
  isUpdatingWorkspace,
  permissionsIsOpen,
  shareLink,
  showOrgAdminPolicy,
  showProjectAdmin,
  selectedContacts,
  setSelectedContacts,
  isAddingUsersFromExternalSource,
}) => {
  const dispatch = useDispatch();

  const strings = Localized.object("WORKSPACE_PERMISSIONS");
  const [isAddingUsers, toggleAddUser] = useState(
    isAddingUsersFromExternalSource
  );
  const [newUsersPermission, setNewUsersPermission] = useState(
    ShareType.EDIT as ShareOptionValue
  );
  const [notifyUsers, setNotifyUsers] = useState(false);
  const [pendingImport, setPendingImport] = useState(false);

  const [recentEmails, setRecentEmails] = useLocalStorage<string[]>(
    RECENT_EMAILS,
    []
  );

  const recentContacts = useMemo(() => {
    const counts = recentEmails.reduce<Record<string, number>>((map, val) => {
      map[val] = (map[val] || 0) + 1;
      return map;
    }, {});
    return Object.entries(counts)
      .sort((a, b) => b[1] - a[1])
      .map(([v]) => makeContact(v));
  }, [recentEmails]);
  const fetchContactsLocal = (value: string, props?: FetchProps) => {
    return fetchContacts(value, { ...props, allContacts: recentContacts });
  };
  const [requestedEmails, setRequestedEmails] = useState<string[]>([]);

  const isAdmin = !!workspaceDetails.isAdmin;
  const roles = workspaceDetails.roles;
  const arePermissionsAndUsersLoaded = !!roles;

  let users = sortUsers(permissionLevelSets);

  if (!isAdmin) {
    let user = users.find((user) => user.email === email);
    users = permissionLevelSets.administrators.map((user) => ({
      email: user,
      permission: ShareType.ADMIN,
    }));
    if (user) {
      users.push(user);
    }
  }

  const emailsWithoutAccount = requestedEmails.filter(
    (re) => !users.find((u) => u.email === re)
  );
  const clearRequestedEmails = () => setRequestedEmails([]);

  useEffect(() => {
    setPendingImport(false);
  }, [workspaceDetails]);

  const upsertUsers = useCallback(
    (userEmails: string[], permission: UpsertWorkspaceUsersPermissions) => {
      clearRequestedEmails();
      dispatch(
        upsertWorkspaceUsers.request({
          workspaceId: workspaceDetails.workspaceId,
          userEmails,
          permission,
        })
      );
    },
    [workspaceDetails.workspaceId, dispatch, clearRequestedEmails]
  );

  const removeUser = useCallback(
    (email: string) => {
      clearRequestedEmails();
      dispatch(
        removeWorkspaceUsers.request({
          workspaceId: workspaceDetails.workspaceId,
          userEmails: [email],
        })
      );
    },
    [workspaceDetails.workspaceId, dispatch, clearRequestedEmails]
  );

  const evaluateEmailList = useCallback(() => {
    if (!arePermissionsAndUsersLoaded) return;

    const userIsNotInWorkspace = (userEmail: string) =>
      !users.find((u) => u.email === userEmail);

    const emailList = selectedContacts
      .map((e) => e.email.toLowerCase())
      .filter((e) => e.match(emailRegex)) // filter out invalid emails
      .filter(userIsNotInWorkspace); // filter out existing emails, because adding new users should not overwrite the permission of a user already in the workspace

    const index = emailList.indexOf(email.trim());
    if (index > -1) {
      emailList.splice(index, 1);
    }
    if (emailList.length !== 0) {
      setRecentEmails((re) => [...emailList, ...re].slice(0, 100));
      upsertUsers(emailList, shareTypeToUpsertPermission(newUsersPermission));
      setRequestedEmails(emailList);
      if (notifyUsers) {
        sendEmail(workspaceDetails.workspaceName ?? "", shareLink, emailList);
        setNotifyUsers(false);
      }
    }
    toggleAddUser(false);
    setSelectedContacts([]);
  }, [
    arePermissionsAndUsersLoaded,
    newUsersPermission,
    notifyUsers,
    selectedContacts,
    shareLink,
    upsertUsers,
    workspaceDetails.workspaceName,
    users,
  ]);

  const onCommitUpdate = useCallback(
    (user: User, shareOption: ShareOptionValue) => {
      if (shareOption === ShareType.REMOVE) {
        removeUser(user.email);
      } else {
        upsertUsers([user.email], shareTypeToUpsertPermission(shareOption));
      }
    },
    [upsertUsers, removeUser]
  );

  return (
    <div className={permissionsIsOpen ? "" : "hidden"}>
      {!isAddingUsers ? (
        <Fragment>
          <div
            className={
              "max-h-2xs sm:max-h-xs overflow-y-auto overflow-x-hidden"
            }
          >
            {arePermissionsAndUsersLoaded &&
              users.map((user: User, index) => (
                <div className={index != 0 ? "pt-1" : ""} key={user.email}>
                  <PermissionsBar
                    isAdmin={isAdmin}
                    currentUser={email.trim()}
                    text={user.email.trim()}
                    enabledOption={user.permission}
                    publicPermissionLevel={publicPermissionLevel}
                    commitUpdate={(shareOption: ShareOptionValue) =>
                      onCommitUpdate(user, shareOption)
                    }
                    options={userAccessOptions}
                    isUpdating={isUpdatingWorkspace}
                  />
                </div>
              ))}
            {showOrgAdminPolicy && (
              <div className={"pt-1"}>
                <PermissionsBar
                  isAdmin={false}
                  commitUpdate={() => {}}
                  enabledOption={ShareType.ADMIN}
                  options={userAccessOptions}
                  text={strings.BY_POLICY_ANY_ORG_ADMIN}
                  currentUser="org admin"
                />
              </div>
            )}
            {showProjectAdmin && (
              <div className={"pt-1"}>
                <PermissionsBar
                  isAdmin={false}
                  commitUpdate={() => {}}
                  enabledOption={ShareType.ADMIN}
                  options={userAccessOptions}
                  text={strings.PROJECT_ADMINS}
                  currentUser="project admin"
                />
              </div>
            )}
          </div>
          {!isUpdatingWorkspace && emailsWithoutAccount.length > 0 && (
            <EmailsWithoutAccount
              workspaceId={workspaceDetails.workspaceId}
              {...{
                emailsWithoutAccount,
                newUsersPermission,
                clearRequestedEmails,
                strings,
              }}
            />
          )}
          <div className="flex justify-end">
            {isAdmin && importProjectUsers && arePermissionsAndUsersLoaded && (
              <Button
                label={strings.IMPORT_PROJECT_USERS}
                onButtonClick={async () => {
                  setPendingImport(true);
                  importProjectUsers(
                    workspaceDetails as WorkspaceDetailsWithUserRoles
                  );
                }}
                additionalStyling="btn-primary mb-0 mt-3/2 mr-0"
                disabled={pendingImport}
              >
                {strings.IMPORT_PROJECT_USERS}
              </Button>
            )}
            {isAdmin && (
              <Button
                label={strings.ADD_USERS}
                onButtonClick={() => {
                  clearRequestedEmails();
                  toggleAddUser(true);
                }}
                additionalStyling="btn-primary mb-0 mt-3/2 mr-0"
              >
                {strings.ADD_USERS}
              </Button>
            )}
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={"pt-3/2 flex flex-col gap-1"}>
            <PermissionsBar
              text={strings.USER_PERMISSION}
              enabledOption={newUsersPermission}
              commitUpdate={(shareOption: ShareOptionValue) =>
                setNewUsersPermission(shareOption)
              }
              options={userAccessOptions}
              isAdmin={isAdmin}
            />
            <RecipientInput
              onChange={(selection) => setSelectedContacts(selection)}
              selectedContacts={selectedContacts}
              fetchContacts={fetchContactsLocal}
              strings={strings}
            />
            <div className="flex justify-between text-sm">
              <div className={"flex items-center"}>
                <Checkbox
                  checked={notifyUsers}
                  onChange={() => {
                    setNotifyUsers(!notifyUsers);
                  }}
                />
                <span>{strings.NOTIFY_USERS}</span>
              </div>
              <div className={"flex items-center text-sm"}>
                <Button
                  label={strings.CANCEL}
                  onButtonClick={() => {
                    toggleAddUser(false);
                    setSelectedContacts([]);
                  }}
                  additionalStyling="btn-negative mt-0 mb-0 "
                >
                  {strings.CANCEL}
                </Button>
                <Button
                  label={`${strings.ADD_WITH} ${Localized.string(
                    newUsersPermission.title
                  )} ${strings.ACCESS}`}
                  onButtonClick={evaluateEmailList}
                  additionalStyling={
                    "btn-primary mt-0 mb-0 mr-0 " + newUsersPermission.color
                  }
                >
                  {`${strings.ADD_WITH} ${Localized.string(
                    newUsersPermission.title
                  )} ${strings.ACCESS}`}
                </Button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

type EmailsWithoutAccountProps = {
  emailsWithoutAccount: string[];
  workspaceId: string;
  newUsersPermission: ShareOptionValue;
  clearRequestedEmails: () => void;
  strings: any;
};

const EmailsWithoutAccount: React.FC<EmailsWithoutAccountProps> = ({
  emailsWithoutAccount,
  workspaceId,
  newUsersPermission,
  clearRequestedEmails,
  strings,
}) => {
  const dispatch = useDispatch();

  const sendInvite = () => {
    dispatch(
      inviteToWorkspace.request({
        invite: {
          emails: emailsWithoutAccount,
          reason: "Invite to join Hoylu",
        },
        workspaceId,
        newUsersPermission: shareTypeToUpsertPermission(newUsersPermission),
      })
    );
    clearRequestedEmails();
  };

  return (
    <>
      <div>{strings.NO_ACCOUNTS_ERROR}</div>
      <ul>
        {emailsWithoutAccount.map((email) => (
          <li key={email}>{email}</li>
        ))}
      </ul>
      <div>
        <Button label={strings.INVITE_AND_ADD} onButtonClick={sendInvite}>
          {strings.INVITE_AND_ADD}
        </Button>
        <Button label={strings.CANCEL} onButtonClick={clearRequestedEmails}>
          {strings.CANCEL}
        </Button>
      </div>
    </>
  );
};

function getContacts(
  searchValue: string,
  { omitContacts = [], limit, allContacts = [] }: FetchProps = {}
) {
  const remainingContacts = allContacts.filter(
    (c) => !omitContacts.some((sc) => sc && sc.id === c.id)
  );
  const sortedContacts = searchValue
    ? remainingContacts.filter((c) => c.name.includes(searchValue))
    : remainingContacts;
  const limitedContacts = limit
    ? sortedContacts.slice(0, limit)
    : sortedContacts;
  return limitedContacts;
}

function fetchContacts(
  searchValue: string,
  { omitContacts, limit, requestId, allContacts }: FetchProps = {}
) {
  return Promise.resolve({
    response: {
      data: getContacts(searchValue, { omitContacts, limit, allContacts }),
      requestId,
    },
  });
}
