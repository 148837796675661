import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { Localized } from "../strings";
import { connect, useDispatch } from "react-redux";
import { activateDashboardOption } from "../state/workspaces/workspaces.actions";
import { DashboardOption } from "../state/workspaces/DashboardOption";
import { RootState } from "typesafe-actions";
import {
  enableWorkspaceExport,
  managementPortal,
  showCreditLimitUi,
} from "../state/config/config.selector";
import { logout, toggleUserDetails } from "../state/user/user.actions";
import { isOrganizationAdmin } from "../utils/sharing.helpers";
import { getAppColorScheme, getCheckedTheme, isDarkTheme } from "../utils/color.scheme";
import {
  effectiveTonnageUsage,
  getUserLicenseType,
  isCreditLimitReached,
  isUserLicensed
} from "../state/workspaces/workspaces.selector";
import { CircularProgressBar } from "./CircularProgressBar";
import { ThemeContext } from "./ThemeContext";
import { getUserMarbleColor } from "../state/user/user.selector";
import UserProfileMarble from "./UserProfileMarble";

const UserProfileMenu: React.FC<UserProfileDialogProps> = ({
  userEmail,
  isOrgAdmin,
  logout,
  managementPortalUrl,
  isWorkspaceExportEnabled,
  toggleUserDetails,
  activateDashboardOption,
  isCreditLimitReached,
  userColor,
  licenseType,
  isUserLicensed,
  profile,
  showCreditLimitUi,
  effectiveTonnageUsage
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const strings = Localized.object("USER_PROFILE_WIDGET");
  const usageNote = isCreditLimitReached
    ? strings.USAGE_OVER_LIMIT
    : Localized.string("USER_PROFILE_WIDGET.USAGE_UNDER_LIMIT", effectiveTonnageUsage);

  const [isDarkThemeSelected, toggleDarkTheme] = useState(isDarkTheme(getAppColorScheme()));

  const usageValueStyles:CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 1.25,
    width: "2rem",
    height: "2rem",
    fontSize: "var(--global--size-h1)",
    fontWeight: "700",
  };

  const showUpload = () => {
    toggleUserDetails();
    activateDashboardOption({
      optionType: DashboardOption.UPLOAD,
    });
  }

  const invite = () =>
    activateDashboardOption({
      optionType: DashboardOption.INVITE,
    });

  useEffect(() => {
    themeContext.setTheme(getCheckedTheme(isDarkThemeSelected));
  }, [isDarkThemeSelected]);

  return (
      <div
        className="absolute cursor-auto bg-theme-user-profile-menu shadow-lg rounded-lg top-32 right-5"
      >
      <div className="text-center text-theme-user-profile-dropdown-item-text p-4">
        {showCreditLimitUi && (
          <div className={"flex items-center justify-between"}>
            <CircularProgressBar percentage={effectiveTonnageUsage} themeColor={userColor}>
              {isUserLicensed
                ? <UserProfileMarble profile={profile} userColor={userColor}/>
                : (
                  <div style={{...usageValueStyles}}>
                    {`${effectiveTonnageUsage}%`}
                  </div>
                )}
            </CircularProgressBar>
            {isUserLicensed ? (
              <div className={"flex flex-col justify-start"}>
                <span className={"text-left"}>{profile.firstName} {profile.lastName}</span>
                <span className={"text-light-primary text-left"}>{Localized.string("USER_PROFILE_WIDGET.PLAN_TITLE", licenseType)}</span>
              </div>
              ) : (
              <span className="whitespace-pre-line overflow-hidden">{usageNote}</span>
              )}
          </div>
        )}
        <ul style={{ minWidth: "10.5rem" }}>
          <li
            title={userEmail}
            className={`flex items-center justify-center py-2 border-b-1 border-theme-user-profile-dropdown-border-top-bottom whitespace-no-wrap`}
          >
            {userEmail}
          </li>
          {!isUserLicensed && showCreditLimitUi &&
            <li
              title={strings.UPGRADE}
              className={`flex items-center py-1 cursor-pointer ${isCreditLimitReached ? 'text-dark-primary bg-notification' : ''}`}
              onClick={() => window.open("https://manage.hoylu.com/u/billing", "_blank")}
              data-test-id={"user-menu-upgrade"}
            >
              <div className="hoylu-ui-icons-star-filled text-xl pr-2"></div>
              <div className="whitespace-no-wrap">
                {strings.UPGRADE}
              </div>
            </li>
          }
          <li
            title={strings.MANAGE_ACCOUNT}
            className="flex items-center py-1 cursor-pointer"
            onClick={() => {
              dispatch(activateDashboardOption({
                optionType: DashboardOption.MANAGE_ACCOUNT,
              }))
            }}
          >
            <div className="hoylu-ui-icons-head text-xl pr-2"></div>
            <div className="whitespace-no-wrap">
              {strings.MANAGE_ACCOUNT}
            </div>
          </li>
          {isOrgAdmin && !!managementPortalUrl && (
            <li
              title={strings.MANAGEMENT_PORTAL}
              className="flex items-center py-1 cursor-pointer"
              onClick={() => {
                window.open(encodeURI(managementPortalUrl), "_newtab");
              }}
            >
              <div className="hoylu-ui-icons-construction2 text-xl pr-2"></div>
              <div className="whitespace-no-wrap">
                {strings.MANAGEMENT_PORTAL}
              </div>
            </li>
          )}
          {isWorkspaceExportEnabled && (
            <li className="flex items-center py-1">
              <button
                title={strings.UPLOAD}
                className="flex items-center"
                onClick={showUpload}
              >
                <div className="hoylu-ui-icons-upload text-xl pr-2"></div>
                <div className="whitespace-no-wrap">
                  {strings.UPLOAD}
                </div>
              </button>
            </li>
          )}
          <li className="flex items-center py-1">
            <button
              className="flex items-center"
              title={strings.INVITE_USER}
              onClick={invite}
            >
              <div className="hoylu-ui-icons-send text-xl pr-2"></div>
              <div className="whitespace-no-wrap">
                {strings.INVITE_USER}
              </div>
            </button>
          </li>
          <li className="flex items-center px-4 py-2 justify-between border-t-1 border-b-1 border-theme-user-profile-dropdown-border-top-bottom">
            {isDarkThemeSelected ? (
              <div
                className={`text-theme-user-profile-dropdown-not-chosen-option`}
              >
                {strings.THEME_LIGHT_TITLE}
              </div>
            ) : (
              <div
                className={`text-theme-user-profile-dropdown-chosen-option`}
              >
                {strings.THEME_LIGHT_TITLE}
              </div>
            )}
            <label className="switch">
              <input
                title={strings.SWITCH_COLOR_SCHEME}
                className="cursor-pointer"
                type="checkbox"
                checked={isDarkThemeSelected}
                onChange={() => toggleDarkTheme(!isDarkThemeSelected)}
              />
              <span className="slider round cursor-pointer"></span>
            </label>
            {isDarkThemeSelected ? (
              <div
                className={`text-theme-user-profile-dropdown-chosen-option`}
              >
                {strings.THEME_DARK_TITLE}
              </div>
            ) : (
              <div
                className={`text-theme-user-profile-dropdown-not-chosen-option`}
              >
                {strings.THEME_DARK_TITLE}
              </div>
            )}
          </li>
          <li className="flex items-center py-1">
            <button
              title={strings.LOGOUT}
              className="flex items-center"
              onClick={logout}
            >
              <div className="hoylu-ui-icons-export text-xl pr-2"></div>
              <div className="whitespace-no-wrap">
                {strings.LOGOUT}
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  logout: logout.request,
  toggleUserDetails,
  activateDashboardOption,
};

const mapStateToProps = (state: RootState) => ({
  userEmail: state.context.user.profile.email,
  isProfileOpen: state.context.user.isProfileOpen,
  profile: state.context.user.profile,
  token: state.context.user.token,
  managementPortalUrl: managementPortal(state),
  isWorkspaceExportEnabled: enableWorkspaceExport(state),
  isOrgAdmin: isOrganizationAdmin(state.context.user.token),
  isCreditLimitReached: isCreditLimitReached(state),
  effectiveTonnageUsage: effectiveTonnageUsage(state),
  userColor: getUserMarbleColor(state),
  licenseType: getUserLicenseType(state),
  isUserLicensed: isUserLicensed(state),
  showCreditLimitUi: showCreditLimitUi(state)
});

type UserProfileDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileMenu);