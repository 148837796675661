import React, { useState } from 'react';
import { Modal } from './Modal';
import {
  cancelDashboardOption,
  createWorkspace
} from '../../state/workspaces/workspaces.actions';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { defaultModule } from '../../utils/defaultModule';
import { KanbanConfiguration } from './moduleConfigurations/kanbanConfiguration';
import { Checkbox } from '../Checkbox';
import { PIPlanningConfiguration } from './moduleConfigurations/PI-PlanningConfiguration';
import { GlobalAccess } from "../../services/workspaces/global.access";
import { Localized } from '../../strings';
import { AgilePlanningConfiguration } from "./moduleConfigurations/agileConfiguration";

function mapStateToProps (state: RootState) {
  let workspaces = state.context.workspaces;
  let useCaseName = defaultModule;
  if (
    workspaces.creationParameters &&
    workspaces.creationParameters.useCaseName
  ) {
    useCaseName = workspaces.creationParameters.useCaseName;
  }
  return {
    useCaseName: useCaseName,
    defaultWorkspaceName: workspaces.defaultWorkspaceName,
    requiresPassword: !!state.context.user.requiresPassword,
    isPrivateChecked: !!state.context.user.policies?.workspacesPrivateByDefault
  };
}

const mapDispatchToProps = {
  handleClose: cancelDashboardOption,
  createWorkspace: createWorkspace.request
};

type NewWorkspaceDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const NewWorkspaceDialog: React.FC<NewWorkspaceDialogProps> = ({
  handleClose,
  createWorkspace,
  requiresPassword,
  useCaseName,
  defaultWorkspaceName,
  isPrivateChecked
}) => {
  const strings = Localized.object("NEW_WORKSPACE_DIALOG");
  let [name, setName] = useState('');
  let [isPrivate, setIsPrivate] = useState(isPrivateChecked);
  let [disabled, setDisabled] = useState(false);
  let [password, setPassword] = useState('');
  let [showPassword, showPasswordCall] = useState(false);
  let [moduleConfiguration, setConfiguration] = useState<any>();

  function handleContinue (e: any) {
    e.preventDefault();
    if (disabled || (requiresPassword && password.length < 8)) {
      return;
    }

    createWorkspace({
      globalAccess: isPrivate ? GlobalAccess.NONE : GlobalAccess.WRITE,
      module: { name: useCaseName, configuration: moduleConfiguration },
      ...(password && { password }),
      workspaceName: name ?? defaultWorkspaceName,
    });
    setDisabled(true);
  }

  function renderModuleConfiguration (useCaseName: string) {
    switch (useCaseName) {
      case 'kanban':
        return <KanbanConfiguration updateConfiguration={setConfiguration} />;
      case 'pi-planning':
        return (
          <PIPlanningConfiguration updateConfiguration={setConfiguration} />
        );
      case 'agile-planning':
        return (
          <AgilePlanningConfiguration updateConfiguration={setConfiguration}/>
        );
      default:
        return null;
    }
  }

  return (
    <Modal handleOutsideClick={handleClose}>
      <div className="relative">
        <h1 className="text-2xl leading-none">{strings.CREATE_WORKSPACE}</h1>
        <form className='mt-4 sm:w-80' onSubmit={handleContinue}>
          <div className="input-container">
            <label className="input-label">{strings.NAME}</label>
            <input
              className="input"
              type="text"
              autoFocus
              name="name"
              onChange={e => setName(e.target.value)}
              defaultValue={defaultWorkspaceName}
              onFocus={e => {
                e.target.select();
                setName(e.target.value);
              }}
              disabled={disabled}
            />
          </div>
          {requiresPassword && (
            <div className="mt-2">
              <div className="input-container">
                <label
                  className="input-label"
                  htmlFor="password"
                >
                  {strings.PASSWORD}
                </label>
                <div className="flex justify-between">
                  <input
                    className="input"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    autoFocus
                    onChange={e => setPassword(e.target.value)}
                  />
                  <img
                    className="mr-2 mb-1"
                    src={
                      showPassword
                        ? require('../../assets/images/icons/eyeclosed.svg')
                        : require('../../assets/images/icons/eyeopen.svg')
                    }
                    onClick={() => showPasswordCall(!showPassword)}
                    alt={strings.SHOW_PASSWORD}
                  />
                </div>
              </div>
              <div className="text-light-primary italic text-xs">
                {strings.CHARACTERS}
              </div>
            </div>
          )}
          {renderModuleConfiguration(useCaseName)}
          <div title={strings.PRIVATE_WORKSPACE_TOOLTIP} className='mt-4 ml-1 flex items-center xxs:text-sm xs:text-base'>
            <Checkbox
              checked={isPrivate}
              onChange={() => setIsPrivate(prevState => !prevState)}
            />
            <label className='ml-1' htmlFor="private">{strings.PRIVATE_WORKSPACE}</label>
          </div>
          <footer className='flex justify-end w-full xxs:text-sm xs:text-base'>
            <button
              type="button"
              className="btn btn-negative"
              onClick={handleClose}
              title={strings.CANCEL}
            >
              {strings.CANCEL}
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={disabled || (requiresPassword && password.length < 8)}
              title={disabled ? strings.OPENING : strings.CONTINUE}
            >
              {disabled ? strings.OPENING : strings.CONTINUE}
            </button>
          </footer>
        </form>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewWorkspaceDialog);
