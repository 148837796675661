import React, { CSSProperties, FC, useState } from 'react';
import { NotificationCenter, NotificationIcon } from "@hoylu/notifications";
import { Localized } from "../../strings";
import { generateDeepLink } from "../../utils/url.helpers";

const iconStyles: CSSProperties = {
  color: "var(--theme-title)",
  lineHeight: 1,
  paddingRight: 0
};

const NotificationMenu: FC = () => {
  const strings = Localized.object("NOTIFICATIONS");
  const [isNotificationCenterOpen, setNotificationCenterVisibility] = useState(false);

  const navigateToElement = (triggerId: string) => {
    const ids = triggerId.split("_");
    const deepLink = generateDeepLink("element", ids[0], ids[1]);

    deepLink ? window.open(deepLink, "_blank") : null;
  }

  return (
    <div className="hidden xs:flex relative"
       aria-label={strings.TITLE}
       tabIndex={0}
    >
      <NotificationIcon
        title={strings.TITLE}
        styles={iconStyles}
        onClick={() => setNotificationCenterVisibility(prev => !prev)}
        showValue={false}
      />
      {isNotificationCenterOpen &&
        <NotificationCenter
          initialPosition={{ top: 70, right: 0}}
          workspaceId={undefined}
          navigateToElement={navigateToElement}
          onClose={() => setNotificationCenterVisibility(false)}
          notificationIconStyles={{ lineHeight: 1 }}
          locales={{
            TITLE: strings.TITLE,
            NAVIGATE_TO_ELEMENT: strings.NAVIGATE_TO_ELEMENT,
            CLOSE_BUTTON: strings.CLOSE_BUTTON,
            SHOW_ALL_MESSAGES: strings.SHOW_ALL_MESSAGES,
            SHOW_UNREAD_MESSAGES_ONLY: strings.SHOW_UNREAD_MESSAGES_ONLY,
            NOTIFICATION_BADGE: strings.NOTIFICATION_BADGE,
            UNSUBSCRIBE: strings.UNSUBSCRIBE,
            SUBSCRIBE: strings.SUBSCRIBE,
            NO_MORE_MESSAGES: strings.NO_MORE_MESSAGES
          }}
        />
      }
    </div>
  )
}

export default NotificationMenu;