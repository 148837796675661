import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { getSelectedWorkspaceDetails } from '../../state/workspaces/workspaces.selector';
import { Localized } from '../../strings';
import { Modal } from "./Modal";
import { CloseIcon } from "./modalElements/CloseIcon";
import { Button, RadioWithLabel, Icon} from "@hoylu/client-common";
import * as workspaceActions from '../../state/workspaces/workspaces.actions';
import { DashboardOption } from "../../state/workspaces/DashboardOption";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
});

const mapDispatchToProps = {
  activateDashboardOption: workspaceActions.activateDashboardOption
};

type RemoveWorkspaceDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

enum ActionType {
  HIDE = 'HIDE',
  LEAVE = 'LEAVE',
  DELETE = 'DELETE',
}

export const RemoveWorkspaceDialog: React.FC<RemoveWorkspaceDialogProps> = ({
  activateDashboardOption,
  selectedWorkspaceDetails,
}) => {
  const strings = Localized.object('INFO_SECTION_REMOVE_WORKSPACE_DIALOG');
  const canLeaveWorkspace = !!selectedWorkspaceDetails?.canLeave;
  const canHideWorkspace = !!selectedWorkspaceDetails?.isFromMyWorkspaces && !selectedWorkspaceDetails.isHidden;

  const selectInitialOption = () => {
    if (canHideWorkspace) return ActionType.HIDE;
    if (canLeaveWorkspace) return ActionType.LEAVE;
    return ActionType.DELETE;
  }
  const [actionType, setActionType] = useState<ActionType>(selectInitialOption);
  const isDeleteAction = actionType === ActionType.DELETE;
  const iconStyles = {
    padding: "0",
    fontSize: "var(--global--size-h1)",
    color: isDeleteAction
      ? "var(--global--text--Color)"
      : "var(--global--dark-primary--Color)",
    marginRight: "var(--global--spacing--medium)"
  };
  const buttonStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }

  const onClose = () => {
    activateDashboardOption({
      optionType: DashboardOption.INFO,
      workspaceId: selectedWorkspaceDetails?.workspaceId,
    });
  }

  const onSubmit = () => {
    switch (actionType) {
      case ActionType.HIDE:
        activateDashboardOption({
          optionType: DashboardOption.HIDE,
          workspaceId: selectedWorkspaceDetails?.workspaceId,
        });
        break;
      case ActionType.LEAVE:
        activateDashboardOption({
          optionType: DashboardOption.LEAVE,
          workspaceId: selectedWorkspaceDetails?.workspaceId,
        })
        break;
      case ActionType.DELETE:
        activateDashboardOption({
          optionType: DashboardOption.DELETE,
          workspaceId: selectedWorkspaceDetails?.workspaceId,
        });
        break;
      default:
        return;
    }
    return;
  }

  const renderInputs = () => {
    const inputs = [];

    for(let action in ActionType) {
      /*
        Skip "hide" option for hidden workspaces.
        Skip "leave" option for the last admin.
        Skip "delete" option for non admin.
       */
      if (
        (action === ActionType.HIDE && !canHideWorkspace) ||
        (action === ActionType.LEAVE && !canLeaveWorkspace) ||
        (action === ActionType.DELETE && !(!!selectedWorkspaceDetails?.isAdmin))
      ) {
        continue;
      }

      const identity = `${action.toLowerCase()}-workspace`;

      let input =
        <RadioWithLabel
          key={identity}
          id={identity}
          label={strings[`${action}_WORKSPACE`]}
          value={identity}
          checked={actionType === action}
          handleChange={() => setActionType(ActionType[action as keyof typeof ActionType])}
        />

      inputs.push(input);
    }

    return inputs;
  }

  const mapButtonIcon = useMemo(() => {
    switch (actionType) {
      case ActionType.HIDE:
        return "hoylu-ui-icons-indicator-hide";
      case ActionType.LEAVE:
        return "hoylu-ui-icons-leave";
      case ActionType.DELETE:
        return "hoylu-ui-icons-trash";
      default:
        return "";
    }
  }, [actionType]);

  if (!selectedWorkspaceDetails) return null;

  return (
    <Modal
      handleOutsideClick={onClose}
      padding={"p-3"}
      allowContentToOverflow={true}
      zIndex={"z-removeWsDialog"}
    >
      <div className="w-120 h-auto" data-test-id="remove-ws-info-section">
          <div className="w-full flex justify-between items-center font-lato">
            <div className="text-indicator font-bold text-base ">{strings.TITLE}</div>
            <CloseIcon onClose={onClose}/>
          </div>
          <div className="flex flex-col gap-2 my-9 mb-6 px-3 radioCheckboxFix">
            {renderInputs()}
          </div>
        <div className={"flex justify-end"}>
          <Button
            onClick={onClose}
            type="secondary"
            dataTestId={strings.CANCEL}
          >
            {strings.CANCEL}
          </Button>
          <Button
            type={isDeleteAction ? "attention" : "primary"}
            onClick={onSubmit}
            dataTestId={strings[actionType]}
            additionalStyles={buttonStyles}
          >
            <Icon
              icon={mapButtonIcon}
              style={iconStyles}
              hoverStyle={{ backgroundColor: "none", color: "unset" }}
            />
            {strings[actionType]}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveWorkspaceDialog);
