import React, {Dispatch, SetStateAction} from "react";

export interface ThemeContextType {
  theme: string;
  setTheme: Dispatch<SetStateAction<string>>;
}

export const ThemeContext = React.createContext<ThemeContextType>(
  {} as ThemeContextType
);
ThemeContext.displayName = "ThemeContext";