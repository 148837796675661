import React from "react";
import {arrowDown, arrowUp} from "../../../assets/images/icons/sharingIcons";
import {StandaloneIcon} from "./icons/StandaloneIcon";

export type ShareSectionHeaderProperties = {
  sectionIcon: () => JSX.Element,
  sectionTitle: string,
  onHeaderClick: () => void;
  isSectionVisible: boolean;
  icons?: JSX.Element[],
  headerContent?: JSX.Element,
  alwaysShowIcons?: boolean
}

export const ShareSectionHeader: React.FC<ShareSectionHeaderProperties> = ({
  sectionIcon,
  sectionTitle,
  onHeaderClick,
  isSectionVisible,
  icons,
  headerContent,
  alwaysShowIcons
}) =>{
  const arrowUpIcon: JSX.Element = <StandaloneIcon icon={arrowUp()} />;
  const arrowDownIcon: JSX.Element = <StandaloneIcon icon={arrowDown()} />
  return (
    <div
      className={"flex flex-row justify-between items-center p-1 cursor-pointer"}
      onClick={() => onHeaderClick()}
    >
      <div className={"flex flex-row items-center text-base font-bold"}>
        {sectionIcon()}
        {sectionTitle}
      </div>
      {!isSectionVisible && headerContent}
      <div className={"flex flex-row items-center"}>
        {(!isSectionVisible || alwaysShowIcons) && icons &&  icons.map(icon => icon)}
        {isSectionVisible ? arrowUpIcon : arrowDownIcon}
      </div>
    </div>
  );
}