import React from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { toggleUserDetails } from "../state/user/user.actions";
import { activateDashboardOption } from "../state/workspaces/workspaces.actions";
import { CircularProgressBar } from "./CircularProgressBar";
import UserProfileMenu from "./UserProfileMenu";
import { effectiveTonnageUsage } from "../state/workspaces/workspaces.selector";
import { getUserMarbleColor } from "../state/user/user.selector";
import UserProfileMarble from "./UserProfileMarble";

export const UserProfileWidget: React.FunctionComponent<HeaderProps> = ({
  profile,
  isProfileOpen,
  toggleUserDetails,
  userColor,
  effectiveTonnageUsage
}) => (
    <>
      <div className="flex text-base">
        <CircularProgressBar percentage={effectiveTonnageUsage} themeColor={userColor} warningHandler={toggleUserDetails}>
          <UserProfileMarble profile={profile} onClick={toggleUserDetails} userColor={userColor} />
        </CircularProgressBar>
        <div className="hidden lg:flex ml-2 flex-col justify-around text-theme-user-profile-firstname-lastname text-sm whitespace-no-wrap">
          <div>{profile.firstName}</div>
          <div>{profile.lastName}</div>
        </div>
      </div>
      {isProfileOpen && (
        <div className="flex">
          <button
            className="fixed inset-0 w-full h-full cursor-auto"
            onClick={toggleUserDetails}
            tabIndex={-1}
          />
          <UserProfileMenu />
        </div>
      )}
    </>
  );

const mapStateToProps = (state: RootState) => ({
  profile: state.context.user.profile,
  isProfileOpen: state.context.user.isProfileOpen,
  effectiveTonnageUsage: effectiveTonnageUsage(state),
  userColor: getUserMarbleColor(state)
});

const mapDispatchToProps = {
  toggleUserDetails,
  activateDashboardOption,
};

type HeaderProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileWidget);
