import { WorkspaceDetails, WorkspacePermissionsAndUsers } from "../../../state/workspaces/types";
import { WorkspaceType } from "../../../state/workspaces/WorkspaceType";
import {
  DocumentMetadataV1Response,
  MyWorkspaceMetadataV1,
} from "./workspaces.v1.types";
import { MyWorkspaceMetadataV3 } from "./workspaces.v3.types";

/**
 * Converts a v1 document metadata response object to a WorkspaceDetails object.
 * @param response The v1 document metadta response object to convert.
 * @param moduleName Overwrite for module name to associate with the workspace.
 * @returns A WorkspaceDetails object representing the converted workspace metadata.
 */
export function toWorkspaceDetails(
  response: DocumentMetadataV1Response,
  moduleName?: string
): WorkspaceDetails {
  const {
    documentId,
    documentName,
    documentType,
    createTime,
    module,
    administrators,
    readers,
    writers,
    unspecifiedUsers,
    globalAccess,
    labels,
    orgs,
    owners,
    lastModified,
    hasPassword,
    ...otherProps
  } = response;
  return {
    workspaceId: documentId,
    workspaceName: documentName,
    workspaceType: WorkspaceType[documentType],
    createTime: new Date(createTime),
    lastModified: lastModified ? new Date(lastModified) : undefined,
    module: {
      ...module,
      name: moduleName ? moduleName : module.name,
    },
    roles: {
      administrators: administrators,
      readers: readers,
      writers: writers,
      unspecifiedUsers: unspecifiedUsers,
      globalAccess: globalAccess,
    },
    labels: labels ?? [],
    orgId: orgs?.[0],
    hasPassword,
    ...otherProps,
  };
}

/**
 * Converts a v1 myworkspace metadata response object to a WorkspaceDetails object.
 * @param w The v1 myworkspace metadata response object to convert.
 * @returns A WorkspaceDetails object representing the converted workspace metadata.
 */
export function metadataV1toWorkspaceDetails(
  w: MyWorkspaceMetadataV1
): WorkspaceDetails {
  const {
    workspaceid,
    lastaccess,
    lastModified,
    createTime,
    workspaceType,
    labels,
    isHidden,
    containerId,
    id,
    orgs,
    permissions,
    ...props
  } = w;
  const details: WorkspaceDetails = {
    ...props,
    id: id ?? undefined,
    workspaceType: WorkspaceType[workspaceType],
    workspaceId: workspaceid,
    lastAccess: lastaccess ? new Date(lastaccess) : undefined,
    lastModified: new Date(lastModified),
    isFromMyWorkspaces: !containerId,
    containerId: containerId,
    roles: permissions,
    labels: labels ?? [],
    orgId: orgs?.[0],
    isHidden: isHidden ?? undefined
  };
  if (createTime) details.createTime = new Date(createTime);
  return details;
}

export const metadataV3toWorkspaceDetails = (
  w: MyWorkspaceMetadataV3,
  roles?: WorkspacePermissionsAndUsers
): WorkspaceDetails => {
  const {
    workspaceId,
    workspaceName,
    lastAccess,
    lastModified,
    createTime,
    workspaceType,
    labels,
    containerId,
    templateId,
    hasPassword,
    isHidden,
    orgId,
    id,
    module,
    ...props
  } = w;
  const details: WorkspaceDetails = {
    ...props,
    id: id ?? undefined,
    workspaceName: workspaceName ?? undefined, // ?? -> map null to undefined
    workspaceType: WorkspaceType[workspaceType],
    workspaceId,
    lastAccess: lastAccess ? new Date(lastAccess) : undefined,
    lastModified: new Date(lastModified),
    isFromMyWorkspaces: !containerId,
    labels: labels ?? [],
    containerId: containerId ?? undefined,
    templateId: templateId ?? undefined,
    isHidden: isHidden ?? undefined,
    orgId: orgId ?? undefined,
    module: {
      ...module,
      configuration: module.configuration ?? undefined
    },
    roles,
    hasPassword
  };
  if (createTime) details.createTime = new Date(createTime);
  return details;
};
