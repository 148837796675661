import React from 'react';
import { connect } from 'react-redux';
import { CenteredContentWithLogo } from './layouts/CenteredContentWithLogo';
import {sendValidationEmail, skipValidationEmail} from '../state/user/user.actions';
import { Localized } from '../strings';
import { RootState } from 'typesafe-actions';
import {AppMode} from "../state/mode/mode.reducer";

const mapDispatchToProps = {
  sendValidationEmailAction: sendValidationEmail.request,
  skip: skipValidationEmail.request
};

const mapStateToProps = (state: RootState) => ({
    skippable: AppMode.SKIPPABLE_VERIFIED_POLICY_RESTRICTED === state.mode
})


type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

export const AccountVerificationError: React.FC<Props> = ({sendValidationEmailAction, skip, skippable= false}) => {
  const strings = Localized.object("ACCOUNT_VERIFICATION_ERROR");
  
  return (
    <CenteredContentWithLogo>
      <h1 className="text-primary-text text-xl m-8 text-center">
      {strings.WE_NOTICED}<br></br> {strings.WITHOUT_VERIFICATION}
      </h1>
      <div className="flex justify-center">
        <button
          onClick={sendValidationEmailAction}
          className="btn btn-negative"
        >
            {strings.VERIFIY_EMAIL}
        </button>
          {skippable &&
        <button
              onClick={skip}
              className="btn btn-negative"
        >
              {strings.SKIP}
        </button>}
      </div>
    </CenteredContentWithLogo>
  );
} 

export default connect(mapStateToProps, mapDispatchToProps)(AccountVerificationError);