import React from "react";

type CheckboxProps = {
  checked: boolean;
  onChange: () => void;
  styles?: any;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  styles,
}) => {
  return (
    <label className={`checkContainer mr-1 ${styles ?? ""}`}>
      <input
        id="checkbox"
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className="checkmark"></span>
    </label>
  );
};
