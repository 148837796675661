import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { Localized } from "../strings";
import SearchIconGray from "../assets/images/icons/search-icon.svg";
import SearchIconBlackDefault from "../assets/images/icons/search-icon_black.svg";
import { isDarkTheme } from "../utils/color.scheme";
import { ThemeContext } from "./ThemeContext";
import { activateDashboardOption, populateAllWorkspaces, setSearchTerm } from "../state/workspaces/workspaces.actions";
import { Icon } from "@hoylu/client-common";
import { DashboardOption } from "../state/workspaces/DashboardOption";

export const FilterByWorkspaceName: React.FC<{ searchTerm: string }> = ({
  searchTerm,
}) => {

  const strings = Localized.object("FILTER_BY_NAME");
  const themeContext = useContext(ThemeContext);
  let searchIcon = SearchIconBlackDefault;
  if (isDarkTheme(themeContext.theme)) {
    searchIcon = SearchIconGray;
  }

  const searchIconStyle = { color: "var(--theme-title)" };

  const isLoadingAll = useSelector((state: RootState) => state.context.workspaces.isLoadingAll)
  const hasPopulatedAllWorkspaces = useSelector((state: RootState) => state.context.workspaces.hasPopulatedAllWorkspaces)
  const dispatch = useDispatch()

  const openSearchWorkspaceDialog = () => dispatch(activateDashboardOption({
      optionType: DashboardOption.SEARCH_WORKSPACE
  }));

  const handleChange = (e: any) => dispatch(setSearchTerm(e.target.value));

  return (
    // For iOS Sarafi: we need to use text-base (min 16px) to prevent zooming in on input on focus
    <>
      <div
        className="font-lato hidden items-center md:flex text-base relative bg-theme-header-button rounded ml-4 sm:ml-0 xxs:w-1/2 xs:w-1/2 md:w-48"
        onMouseOver={() => {
          if (!hasPopulatedAllWorkspaces) {
            dispatch(populateAllWorkspaces.request());
          }
        }}
      >
        <img
          className="absolute left-3 w-4"
          src={searchIcon}
          alt={strings.MAGNIFYING_GLASS}
        />
        <input
          data-test-id="search-workspace"
          className={`input flex pl-8 text-theme-input-text font-bold placeholder-${themeContext.theme} rounded h-8 w-full`}
          type="text"
          value={searchTerm}
          onChange={handleChange}
          placeholder={isLoadingAll ? strings.LOADING_WORKSPACES : strings.SEARCH}
          disabled={isLoadingAll}
        />
      </div>
      <div className={"block md:hidden"}>
        <Icon icon={"hoylu-ui-icons-search"}
              style={searchIconStyle}
              hoverStyle={searchIconStyle}
              onClick={openSearchWorkspaceDialog}
              testId={"search-workspace"}/>
      </div>
    </>
  );
};