import React, { useState, useEffect } from 'react';

export const FlatRippleSpinner: React.FC = () => {

  // Workaround to avoid showing elements with delay in the spinner before initialization of the animation
  const [isFirstElVisible, setFirstElVisibility] = useState(false);
  const [isSecondElVisible, setSecondElVisibility] = useState(false);

  useEffect(() => {
    const el1Delay = 600; // cover difference between delay-1/2
    const el2Delay = 1100; // cover difference between delay-1

    const timer1 = setTimeout(() => {
      setFirstElVisibility(true);
    }, el1Delay);

    const timer2 = setTimeout(() => {
      setSecondElVisibility(true);
    }, el2Delay);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  return (
    <div className="absolute bottom-0 inline-block w-full h-px mb-1">
      <div className="ripple-2 border-2" />
      <div className="ripple-2 border-2 delay-1/2" style={{ opacity: isFirstElVisible ? 1 : 0}} />
      <div className="ripple-2 border-2 delay-1" style={{ opacity: isSecondElVisible ? 1 : 0}} />
    </div>
  );
}

export const SmallRippleSpinner: React.FC = () => (
  <div className="inline-block w-4 h-4 relative mr-1">
    <div className="ripple-1 border-1 border-accent" />
    <div className="ripple-2 delay-1" />
    <div className="ripple-1 border-1 delay-1/2 border-indicator" />
  </div>
);

export const MediumRippleSpinner: React.FC = () => (
  <div className="inline-block w-6 h-6 relative mt-1 mr-1">
    <div className="ripple-1 border-1 border-accent" />
    <div className="ripple-2 delay-1" />
    <div className="ripple-1 border-1 delay-1/2 border-indicator" />
  </div>
);
