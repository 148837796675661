import React from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { ConfirmationDialog } from "./ConfirmationDialog";
import {
  cancelDashboardOption,
  removeWorkspaceFromProject,
} from "../../state/workspaces/workspaces.actions";
import { getSelectedWorkspaceDetails } from "../../state/workspaces/workspaces.selector";
import { Localized } from "../../strings";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
});

const mapDispatchToProps = {
  cancelDashboardOption,
  removeAction: removeWorkspaceFromProject.request,
};

type RemoveWorkspaceFromProjectDialogProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

export const RemoveWorkspaceFromProjectDialog: React.FC<RemoveWorkspaceFromProjectDialogProps> =
  ({ cancelDashboardOption, removeAction, selectedWorkspaceDetails }) => {
    const t = (localAddress: string, ...params: any) =>
      Localized.string(
        "REMOVE_WORKSPACE_FROM_PROJECT_DIALOG." + localAddress,
        ...params
      );

    const submitClicked = () => {
      if (!selectedWorkspaceDetails?.containerId) return;
      if (!selectedWorkspaceDetails?.workspaceId) return;
      removeAction({
        projectId: selectedWorkspaceDetails.containerId,
        workspaceId: selectedWorkspaceDetails.workspaceId,
      });
      cancelDashboardOption();
    };

    return (
      <ConfirmationDialog
        icon={require("../../assets/images/icons/hide-icon.svg")}
        title={t("TITLE", selectedWorkspaceDetails?.workspaceName)}
        message={t("MESSAGE", selectedWorkspaceDetails?.workspaceName)}
        buttonText={t("BUTTON")}
        submitAction={submitClicked}
        cancelAction={cancelDashboardOption}
      />
    );
  };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveWorkspaceFromProjectDialog);
