import React, { useState, useEffect } from "react";
import { ShareOptionValue, ShareType } from "./ShareType";
import { SmallRippleSpinner } from "../../RippleSpinner";
import { Localized } from "../../../strings";
import { CloseIcon } from "../modalElements/CloseIcon";

type PermissionsBar = {
  commitUpdate: (shareOption: ShareOptionValue) => void;
  enabledOption: ShareOptionValue;
  options: ShareOptionValue[];
  isUpdating?: boolean;
  text: string;
  currentUser?: string;
  publicPermissionLevel?: ShareOptionValue;
  isAdmin: boolean;
};

export const PermissionsBar: React.FC<PermissionsBar> = ({
  commitUpdate,
  enabledOption,
  options,
  isUpdating,
  text,
  currentUser,
  publicPermissionLevel,
  isAdmin,
}) => {
  const strings = Localized.object("PERMISSION_BAR");
  const [showLoadingSpinner, toggleLoadingSpinner] = useState(false);
  const setAccessOption = (
    option: ShareOptionValue,
    enabledOption: ShareOptionValue
  ): void => {
    if (option !== enabledOption && currentUser !== text) {
      if (currentUser) {
        toggleLoadingSpinner(true);
      }
      commitUpdate(option);
    }
  };
  const isUserPermission = text.indexOf(" ") === -1;

  useEffect(() => {
    if (!isUpdating) {
      toggleLoadingSpinner(false);
    }
  }, [isUpdating]);

  const computeStyle = (option: ShareOptionValue, isAdmin: boolean) => {
    const STYLE_SHARED: string = `rounded-md sm:rounded-lg h-6 w-6 ml-1 sm:mx-0 sm:w-12`;
    const STYLE_SELECTED: string = `${STYLE_SHARED} text-black cursor-default`;
    const STYLE_ENABLED: string = `${STYLE_SHARED} bg-dark-primary-selection cursor-pointer text-light-primary`;
    const STYLE_DISABLED: string =
      `${STYLE_SHARED} text-primary-selection bg-transparent ` +
      `box-border border border-solid border-current cursor-default`;
    const STYLE_NOT_SELECTED: string = isAdmin ? STYLE_ENABLED : STYLE_DISABLED;

    if (
      !publicPermissionLevel ||
      enabledOption.value >= publicPermissionLevel.value
    ) {
      if (option === enabledOption) {
        return `${STYLE_SELECTED} ${option.color}`;
      } else {
        return STYLE_NOT_SELECTED;
      }
    }

    if (option === publicPermissionLevel) {
      return `${STYLE_SELECTED} ${option.color}`;
    } else if (option === enabledOption) {
      return `${STYLE_NOT_SELECTED} border border-solid ${option.border}`;
    } else {
      return STYLE_NOT_SELECTED;
    }
  };

  const shouldRemoveBeDisplayed =
    text.indexOf(" ") === -1 && isAdmin && currentUser !== text;

  // TODO: Add tooltip for org permission once it is available
  const isOrgOnly = false;
  const permissionTooltip = !isOrgOnly
    ? text
    : publicPermissionLevel?.value !== 0
    ? strings.AUTHENTICATED_ACCOUNTS_READ_ONLY_ACCESS
    : strings.AUTHENTICATED_ACCOUNTS_NO_ACCESS;

  return (
    <div className="flex">
      <div className="bg-dark-selected relative flex justify-between rounded xs:p-1 xs:pl-2 w-full h-10 text-sm">
        <div
          title={permissionTooltip}
          className="flex items-center w-56 truncate"
        >
          {" "}
          {text}
        </div>
        <div className="flex flex-row items-center">
          {isUserPermission && (
            <span
              className={`flex items-center hidden sm:block ${
                showLoadingSpinner ? "" : "invisible"
              }`}
            >
              <SmallRippleSpinner />
            </span>
          )}
          {!isUserPermission && (
            <span
              className={`flex items-center ${
                showLoadingSpinner ? "" : "invisible"
              }`}
            >
              <SmallRippleSpinner />
            </span>
          )}
          {currentUser && <div>{strings.HAS}</div>}
          <div
            className="text-black mr-1 sm:mx-2 flex"
            title={strings.ONLY_ADMINS_CHANGE_ACCESS}
          >
            {options.map((option, index) => {
              return (
                <PermissionOption
                  key={index}
                  isAdmin={isAdmin}
                  index={index}
                  option={option}
                  enabledOption={enabledOption}
                  setAccessOption={setAccessOption}
                  computeStyle={computeStyle}
                />
              );
            })}
          </div>
          <div className={`${shouldRemoveBeDisplayed ? "mr-2" : "mr-10"}`}>
            {strings.ACCESS}
          </div>
          {shouldRemoveBeDisplayed && (
            <CloseIcon
              onClose={() => setAccessOption(ShareType.REMOVE, enabledOption)}
              title={strings.REMOVE_USER}
            />
          )}
        </div>
      </div>
    </div>
  );
};

type PermissionOptionProperties = {
  isAdmin: boolean;
  index: number;
  option: ShareOptionValue;
  enabledOption: ShareOptionValue;
  setAccessOption: (
    option: ShareOptionValue,
    enabledOption: ShareOptionValue
  ) => void;
  computeStyle: (
    option: ShareOptionValue,
    isAdmin: boolean
  ) => string | undefined;
};

const PermissionOption: React.FC<PermissionOptionProperties> = ({
  isAdmin,
  index,
  option,
  enabledOption,
  setAccessOption,
  computeStyle,
}) => {
  const connectorColor = isAdmin
    ? "text-dark-primary-selection"
    : "text-primary-selection";

  return (
    <>
      {index !== 0 && (
        <svg
          className={`${connectorColor} fill-current hidden sm:block mt-24/10`}
          width="10"
          height="5"
        >
          <rect width={10} height={5} />
        </svg>
      )}
      <button
        data-test-id={option.title}
        className={computeStyle(option, isAdmin)}
        disabled={!isAdmin}
        onClick={() => {
          if (!isAdmin) return;
          setAccessOption(option, enabledOption);
        }}
      >
        <div className="hidden sm:block">{Localized.string(option.title)}</div>
      </button>
    </>
  );
};
