import React from "react";

export enum SectionPosition {
  TOP,
  MIDDLE,
  BOTTOM,
}

export type ShareSectionProps = React.PropsWithChildren<{
  testId: string;
  sectionPosition?: SectionPosition;
}>;

export const ShareSection: React.FC<ShareSectionProps> = ({
  sectionPosition = SectionPosition.MIDDLE,
  testId,
  children,
}) => {
  const getContainerMargin = (sectionPosition: SectionPosition) => {
    switch (sectionPosition) {
      case SectionPosition.TOP:
        return "mb-3";
      case SectionPosition.BOTTOM:
        return "mt-3 ";
      case SectionPosition.MIDDLE:
      default:
        return "mt-3 mb-3";
    }
  };
  const margin = getContainerMargin(sectionPosition);

  return (
    <div
      className={`${margin} bg-primary-selection rounded-lg`}
      data-test-id={testId}
    >
      {children}
    </div>
  );
};
