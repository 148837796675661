import React, { FormEvent, useEffect, useState } from "react";
import { Localized } from "../../strings";
import { Checkbox } from "../Checkbox";
import { Modal } from "./Modal";

type ConfirmationDialogProps = {
  icon: string;
  cancelAction: () => void;
  title: string;
  message: string;
  submitAction: (e: FormEvent) => void;
  buttonText: string;
  passwordRequired?: boolean;
  confirmWithCheckmark?: boolean;
  confirmDelete?: string;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  icon,
  cancelAction,
  title,
  message,
  submitAction,
  buttonText,
  passwordRequired = false,
  confirmWithCheckmark = false,
  confirmDelete = "",
}) => {
  const strings = Localized.object("CONFIRMATION_DIALOG");
  const [checked, setChecked] = useState(false);
  const toggleChecked = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    setChecked(false);
  }, []);

  const buttons = () => (
    <div
      className={`flex ${
        confirmWithCheckmark ? "justify-between" : "justify-end"
      }`}
    >
      {confirmWithCheckmark && (
        <div className="flex items-center pt-2">
          <Checkbox checked={checked} onChange={toggleChecked} />
          <label className="ml-1 text-accent" htmlFor="checkbox">
            {confirmDelete}
          </label>
        </div>
      )}
      <div>
        <button
          className="btn btn-primary mt-4 float-right"
          type={passwordRequired ? "submit" : "button"}
          onClick={passwordRequired ? () => {} : submitAction}
          title={buttonText}
          disabled={confirmWithCheckmark ? !checked : false}
        >
          {buttonText}
        </button>
        <button
          className="btn btn-negative mt-4 float-right"
          onClick={cancelAction}
          type="button"
          title={strings.CANCEL}
        >
          {strings.CANCEL}
        </button>
      </div>
    </div>
  );

  return (
    <Modal>
      <div className="flex flex-col">
        <div className="flex pb-4">
          <img className="pr-2 w-10" src={icon} alt={strings.OPTION_ICON} />
          <h1 className="text-2xl truncate">{title}</h1>
        </div>
        <h3 className="text-lg whitespace-pre-line">{message}</h3>

        {passwordRequired ? (
          <form onSubmit={submitAction}>
            <div className="input-container modal-component">
              <label className="input-label">{strings.PASSWORD}</label>
              <input
                className="input"
                type="password"
                autoFocus
                name="password"
              />
            </div>
            {buttons()}
          </form>
        ) : (
          buttons()
        )}
      </div>
    </Modal>
  );
};
