import React from "react";
import { Modal } from "./Modal";
import { CloseButton } from "./modalElements/CloseButton";
import { connect } from "react-redux";
import {
  cancelDashboardOption,
  queueEditWorkspace,
} from "../../state/workspaces/workspaces.actions";
import Cleave from "cleave.js/react";
import { Localized } from "../../strings";

const mapDispatchToProps = {
  handleClose: cancelDashboardOption,
  queueEditWorkspace: queueEditWorkspace,
};

type SearchWorkspaceDialogProps = typeof mapDispatchToProps;

const workspaceIDLength = 9;

export function rawValue(value: string) {
  let result = "";

  for (let i = 0; i < value.length; i++) {
    if (isNaN(parseInt(value.charAt(i)))) continue;
    result += value.charAt(i);
  }

  return result.slice(0, workspaceIDLength);
}

export const OpenSharedWorkspaceDialog: React.FC<SearchWorkspaceDialogProps> = ({
  handleClose,
  queueEditWorkspace,
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const strings = Localized.object("SHARED_WORKSPACE");
  const closeStyle: string = "text-accent pb-4 w-4";

  return (
    <Modal handleOutsideClick={handleClose}>
      <div className="relative">
        <div className="close-container">
          <h1 className="text-base pb-2">{strings.OPEN_SHARED_WORKSPACE}</h1>
          <CloseButton closeModal={handleClose} style={closeStyle} />
        </div>
        <div className="flex items-center text-sm xxs:pr-1 sm:pr-16">
          <div className="pr-1">
            <img
              src={require("../../assets/images/globe-icon.svg")}
              alt={strings.MAGNIFYING_GLASS}
            />
          </div>
          {strings.ENTER_ID}
        </div>
        <form
          className="flex pt-2"
          onSubmit={(e) => {
            e.preventDefault();
            queueEditWorkspace({ workspaceId: searchTerm }); // Note: queueEdit will show the Upsell modal if needed
          }}
        >
          <Cleave
            className="modal-component border-solid rounded-t-sm rounded-b-none input-container text-base w-5/6 px-2 py-1"
            type="text"
            // Cleave needs some sort of initial value for it's slice function when pasting on mobile
            // This initial value should never appear since it's set to numericOnly
            value="ID"
            options={{
              delimiters: ["-", "-"],
              blocks: [3, 3, 3],
              numericOnly: true,
            }}
            onChange={(e: any) => {
              let newValue = rawValue(e.target.value);
              if (newValue !== searchTerm) {
                setSearchTerm(newValue);
              }
            }}
            autoFocus
          />
          <button
            data-test-id="open-workspace-button"
            className="btn btn-primary"
            disabled={searchTerm.length !== workspaceIDLength}
            type="submit"
            title={strings.OPEN}
          >
            <div className="text-sm">{strings.OPEN}</div>
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default connect(null, mapDispatchToProps)(OpenSharedWorkspaceDialog);
