import React, { useEffect } from "react";
import { RootState } from "typesafe-actions";
import {
  addWorkspaceToProject,
  assignableProjects,
  cancelDashboardOption,
} from "../../state/workspaces/workspaces.actions";
import { connect } from "react-redux";
import { getSelectedWorkspaceDetails } from "../../state/workspaces/workspaces.selector";
import { getAssignableProjects } from "../../state/workspaces/projects.selector";
import { Localized } from "../../strings";
import { ShareHeader } from "./sharing/ShareHeader";
import { Modal } from "./Modal";
import { Dropdown } from "../Dropdown";
import { Project, ProjectStatus } from "../../state/workspaces/types";
import { hiconToHoyluUiIconsCentered } from "../../utils/icon.map";
import { ProjectStatuses } from "../../state/workspaces/workspaces.reducer";
import { hexToRgba } from "../../utils/hexToRgba";

export function ProjectSelectorLabel(
  icon: string,
  name: string,
  status: ProjectStatus | null,
  color?: string
) {
  const isProjectInactive = status === ProjectStatuses.Inactive;
  const displayedName = isProjectInactive
    ? `${Localized.string("FILTER_BY_NAME.INACTIVE")} - ${name}`
    : name;
  const colorVariant = isProjectInactive
    ? hexToRgba(color ?? '', 0.5, "#716C83")
    : color;

  return (
    <div className="flex">
      <div
        style={{ color: colorVariant }}
        className={`${
          hiconToHoyluUiIconsCentered(icon) || "hoylu-ui-icons-box"
        } pr-2`}
      />
      <div className={`truncate ${isProjectInactive ? "text-light-primary" : ""}`}>{displayedName}</div>
    </div>
  );
}

export const AssignProjectDialog: React.FC<ProjectProps> = ({
  cancelDashboardOption,
  workspaceDetails,
  addToProject,
  assignableProjects,
  updateAssignableProjects,
}) => {
  const strings = Localized.object("ASSIGN_PROJECT_DIALOG");

  // update assignableProjects on load (or when the workspace details change)
  useEffect(() => {
    updateAssignableProjects();
  }, [workspaceDetails]);

  const setProject = (selectedProjectId: string | undefined) => {
    if (!workspaceDetails) return;
    if (!selectedProjectId) return;
    addToProject({
      workspaceId: workspaceDetails.workspaceId,
      projectId: selectedProjectId,
    });
    cancelDashboardOption();
  };

  const projectToOption = (p: Project) => {
    return {
      label: ProjectSelectorLabel(p.icon, p.name, p.status, p.color),
      value: p.id,
    };
  };

  const options = assignableProjects.map(projectToOption);

  return (
    <Modal
      handleOutsideClick={cancelDashboardOption}
      padding={"p-3"}
      /* need this for the dropdown to be rendered visually outside of the box */
      allowContentToOverflow={true}
    >
      <div
        className="flex flex-col font-lato xxs:w-full sm:w-68 sm:max-w-68"
        data-test-id="assign-project-dialog"
      >
        <div className={"relative font-lato"}>
          <ShareHeader
            modalTitle={strings.ASSIGN_TO_PROJECT}
            closeModal={cancelDashboardOption}
          />
          <div className="ml-3 mb-2">{strings.ASSIGN_PROJECT_DESCRIPTION}</div>
          <Dropdown
            value={undefined}
            label={strings.SELECT_A_PROJECT}
            options={options}
            onChange={(id) => setProject(id)}
            classes={
              "flex font-lato relative bg-dark-selected rounded-lg w-full"
            }
          />
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  cancelDashboardOption,
  addToProject: addWorkspaceToProject.request,
  updateAssignableProjects: assignableProjects.request,
};

const mapStateToProps = (state: RootState) => ({
  workspaceDetails: getSelectedWorkspaceDetails(state),
  assignableProjects: getAssignableProjects(state),
});

type ProjectProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignProjectDialog);
