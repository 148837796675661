import { user } from "../../state/user/user.selector";
import {useDispatch, useSelector} from "react-redux";
import { Modal } from "./Modal";
import React, {useCallback, useState} from "react";
import {CloseIcon} from "./modalElements/CloseIcon";
import {Localized} from "../../strings";
import {cancelDashboardOption} from "../../state/workspaces/workspaces.actions";
import {Button} from "./modalElements/Button";
import {requestDelete} from "../../state/user/user.actions";
import {Checkbox} from "@hoylu/client-common";

export const ConfirmAccountDelete = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(user)
  const [confirm, setConfirm] = useState(false)
  const strings = Localized.object("CONFIRM_ACCOUNT_DELETE");
  const close = useCallback(() => dispatch(cancelDashboardOption()), [dispatch])
  return (
    <Modal
      handleOutsideClick={close}
      padding={"p-3"}
    >
      <div className="w-full inline-flex justify-end items-center pb-3">
        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-row">
            <div className="text-indicator font-bold text-base ">{strings.TITLE + currentUser.profile.email}</div>
          </div>
        </div>
        {<CloseIcon onClose={close}/>}
      </div>
      <div className="p-1 pb-4 flex flex-col w-full justify-between gap-4">
        <span>{strings.P1}</span>
        <ul className="list-disc list-inside">
          <li className="px-2 py-1">{strings.POINT1}</li>
          <li className="px-2 py-1">{strings.POINT2}</li>
        </ul>
        <span className="whitespace-pre-line">{strings.P2}</span>
      </div>
      <div className="p-1 flex justify-between items-center">
        <div className="flex items-center pt-2">
          <Checkbox checked={confirm} onChange={setConfirm} />
          <label className="ml-1 text-accent" htmlFor="checkbox" onClick={() => setConfirm(c => !c)}>
            {strings.CONFIRM}
          </label>
        </div>
        <div className={"flex justify-end items-center"}>
          <Button label={strings.CANCEL} onButtonClick={close} additionalStyling={"btn-primary"}
          >{strings.CANCEL}</Button>
          <Button label={strings.DELETE} onButtonClick={() => dispatch(requestDelete.request())} additionalStyling={"btn-warning"} disabled={!confirm}
          >{strings.DELETE}</Button>
        </div>
      </div>

    </Modal>
  )
}