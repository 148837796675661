import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { AppMode } from "../state/mode/mode.reducer";
import { Localized } from "../strings";
import { selectProject } from "../state/workspaces/workspaces.actions";
import LogoOnlyDark from "../assets/images/logos/logo-only-theme-dark.svg";
import LogoOnlyLight from "../assets/images/logos/logo-only-theme-light.svg";

const mapStateToProps = (state: RootState) => ({
  isEditing: state.mode === AppMode.EDITING,
  isLoading: state.mode === AppMode.LOADING_WORKSPACE,
});

type LogoProps = ReturnType<typeof mapStateToProps>;

export const HoyluLogo: React.FC<LogoProps> = ({ isEditing, isLoading }) => {
  const dispatch = useDispatch();
  const strings = Localized.object("HOYLU_LOGO")["HOYLU_LOGO"];
  const largeLogoClasses = "hidden sm:block w-fill h-10 z-50";
  const smallLogoClasses = "sm:hidden pr-3 w-fill h-10 z-50";
  const isProjectSelected = useSelector((state: RootState) => state.context.workspaces.selectedProject);
  const redirectToDashboard = () => isProjectSelected ? dispatch(selectProject(null)) : null;

  return !isEditing && !isLoading ? (
    <div
      className={`w-fill h-18 flex items-center ${
        isProjectSelected ? "cursor-pointer" : ""
      }`}
      onClick={redirectToDashboard}
      tabIndex={0}
      title={"Hoylu Logo"}
    >
      {/* logo for medium+ screen sizes */}
      <img
        data-test-id="main-logo-only-dark"
        className={largeLogoClasses}
        src={LogoOnlyDark}
        alt={strings}
      />
      <img
        data-test-id="main-logo-only-light"
        className={largeLogoClasses}
        src={LogoOnlyLight}
        alt={strings}
      />
      {/* logo for small screen sizes */}
      <img
        data-test-id="main-logo-only-dark"
        className={smallLogoClasses}
        src={LogoOnlyDark}
        alt={strings}
      />
      <img
        data-test-id="main-logo-only-light"
        className={smallLogoClasses}
        src={LogoOnlyLight}
        alt={strings}
      />
    </div>
  ) : null;
};

export default connect(mapStateToProps)(HoyluLogo);
