import React, { MouseEvent } from "react";

// TODO: remove below padding when we update dashboard to new design.
type ModalProps = React.PropsWithChildren<{
  handleOutsideClick?: (e: MouseEvent) => void;
  zIndex?: string;
  padding?: string; // this will allow to set padding to updated design
  allowContentToOverflow?: boolean; // true: allow content to overflow and also prevent showing scrollbars (e.g: when content has a dropdown)
  showOverlay?: boolean;
}>;

export const Modal: React.FC<ModalProps> = ({
  handleOutsideClick,
  padding,
  children,
  allowContentToOverflow = false,
  zIndex = "z-50",
  showOverlay = true,
}) => {
  const modalPadding: string = padding ?? "p-6";
  const modalStyle: string =
    "bg-primary text-primary-text rounded-lg pointer-events-auto mx-auto max-w-90vw " +
    "max-h-90vh xxs:w-full sm:w-auto " +
    (allowContentToOverflow ? "" : "overflow-x-auto ") +
    `${modalPadding}`;

  return (
    <>
      <div
        onClick={handleOutsideClick}
        className={
          showOverlay
            ? `fixed top-0 left-0 ${zIndex} opacity-50 w-full h-full bg-gray-500`
            : `display-none`
        }
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
      />
      <div
        className={`pointer-events-none fixed top-0 left-0 ${zIndex} w-full h-screen flex justify-center items-center`}
      >
        <div className={modalStyle}>{children}</div>
      </div>
    </>
  );
};
