import React from 'react';
import { useDispatch } from 'react-redux';
import { DashboardOption } from "../../state/workspaces/DashboardOption";
import { activateDashboardOption } from "../../state/workspaces/workspaces.actions";
import { Localized } from "../../strings";

type HeaderButtonsProps = {
  showSearch: boolean;
  handleSearchClick: () => void;
};

export const HeaderButtons: React.FC<HeaderButtonsProps> = ({
  showSearch,
  handleSearchClick
}) => {
  const dispatch = useDispatch();

  const openStr = Localized.object("SHARED_WORKSPACE");
  const inviteStr = Localized.object("INVITE_USERS");

  const styles = "truncate btn rounded m-0 font-normal flex-col justify-center items-center text-theme-title bg-theme-header-button";
  const bgStyle = { backgroundColor: "var(--custom-workDay-color)" };

  const invite = () => dispatch(activateDashboardOption({
    optionType: DashboardOption.INVITE,
  }));

  return (
    <section
      className={"hidden sm:flex items-center gap-4"}
      tabIndex={0}
    >
      {showSearch && (
        <button
          data-test-id="Open shared workspace"
          title={openStr.OPEN_SHARED_WORKSPACE}
          className={styles}
          style={bgStyle}
          onClick={handleSearchClick}
        >
          {openStr.OPEN}
        </button>
      )}
      <button
        data-test-id="Invite users"
        title={inviteStr.INVITE_USER}
        className={styles}
        style={bgStyle}
        onClick={invite}
      >
        {inviteStr.INVITE}
      </button>
    </section>
  );
};

export default HeaderButtons;
