import { ShareType, ShareOptionValue } from "../ui/modals/sharing/ShareType";
import { WorkspacePermissionsAndUsers } from "../state/workspaces/types";
import { Localized } from "../strings";
import { jwtDecode } from "jwt-decode";
import { UserFullPayload } from "./credential.helpers";

export function updatePermissions(
  shareType: ShareOptionValue,
  permissions: WorkspacePermissionsAndUsers,
  update: (newValue: WorkspacePermissionsAndUsers) => void
) {
  let newPermissions: WorkspacePermissionsAndUsers = {
    administrators: permissions.administrators,
    readers: [],
    writers: [],
    unspecifiedUsers: [],
    globalAccess: permissions.globalAccess,
  };
  switch (shareType) {
    case ShareType.EDIT:
      newPermissions.readers = [];
      newPermissions.writers = [];
      break;
    case ShareType.READ:
      newPermissions.readers = [];
      newPermissions.writers = permissions.writers.concat(
        permissions.administrators
      );
      break;
    case ShareType.NO_ACCESS:
      newPermissions.readers = permissions.readers.concat(
        permissions.administrators
      );
      newPermissions.writers = permissions.writers.concat(
        permissions.administrators
      );
      break;
  }
  update(newPermissions);
  return newPermissions;
}

export function calculatePermissions(globalAccess: string) {
  let publicPermissionLevel: ShareOptionValue;
  if (globalAccess === "WRITE") {
    publicPermissionLevel = ShareType.EDIT;
  } else if (globalAccess === "READ") {
    publicPermissionLevel = ShareType.READ;
  } else {
    publicPermissionLevel = ShareType.NO_ACCESS;
  }
  return publicPermissionLevel;
}

export function sortUsers(permissionLevelSets: WorkspacePermissionsAndUsers) {
  return [
    ...permissionLevelSets.administrators.map((user) => ({
      email: user,
      permission: ShareType.ADMIN,
    })),
    ...permissionLevelSets.writers.map((user) => ({
      email: user,
      permission: ShareType.EDIT,
    })),
    ...permissionLevelSets.readers.map((user) => ({
      email: user,
      permission: ShareType.READ,
    })),
    ...permissionLevelSets.unspecifiedUsers.map((user) => ({
      email: user,
      permission: ShareType.NO_ACCESS,
    })),
  ].sort((userA, userB) => {
    if (userA.email < userB.email) {
      return -1;
    }
    if (userA.email > userB.email) {
      return 1;
    }
    return 0;
  });
}

export function formatWorkspaceId(workspaceId: string) {
  let formattedId: string = "";
  for (let i = 1; i <= workspaceId.length; i++) {
    formattedId += workspaceId[i - 1];
    if (i % 3 === 0 && i !== workspaceId.length) {
      formattedId += " ";
    }
  }
  return formattedId;
}

export const sanitizeUrl = (string: string): string => {
  const stringUrl = string.trim();
  try {
    let url = new URL(stringUrl);
    if (url.protocol === "http:" || url.protocol === "https:") {
      // When string is a valid url - remove www. prefix from hostname
      return url.hostname.replace("www.", "");
    }
  } catch (_) {
    // When string is not a valid url - remove www. prefix from string
    return string.replace("www.", "");
  }
  return string;
};

export const isOrganizationAdmin = (token: string) => {
  if (!token) return false;

  const userFullPayload = jwtDecode<UserFullPayload>(token);
  return !!userFullPayload.Permissions.Administer[0];
}

export function sendEmail(
  workspaceName: string,
  shareLink: string,
  recipients?: string[]
) {
  workspaceName = sanitizeUrl(workspaceName);
  const strings = Localized.object("COPY_EMAIL_LINK");
  let mailTo = "";
  if (recipients) {
    recipients.forEach((recipient, index) => {
      mailTo += recipient;
      if (recipients.length - 1 !== index) {
        mailTo += ";";
      }
    });
  }
  const emailLink =
    `mailto:${mailTo}?subject=${
      strings.WORKSPACE_SHARED_WITH_YOU
    } ${encodeURIComponent(workspaceName)}&` +
    `body=${strings.WORKSPACE_SHARED_WITH_YOU}%0D${encodeURIComponent(
      workspaceName
    )}%0D${shareLink}%0D%0D${strings.LEARN_MORE}`;
  window.open(emailLink, "_blank");
}

//TODO: Remove
/** @deprecated */
export function addNewUsers(
  value: ShareOptionValue,
  editedUsers: string[],
  permissions: WorkspacePermissionsAndUsers
): WorkspacePermissionsAndUsers {
  // only filter if adding new users - no filter when updating an existing user
  editedUsers = editedUsers.filter((user) => {
    return (
      !permissions.administrators.includes(user) &&
      !permissions.writers.includes(user) &&
      !permissions.readers.includes(user) &&
      !permissions.unspecifiedUsers.includes(user)
    );
  });
  return updateUserPermissions(value, editedUsers, permissions);
}

//TODO: Remove
/** @deprecated */
export function updateUserPermissions(
  value: ShareOptionValue,
  editedUsers: string[],
  permissions: WorkspacePermissionsAndUsers
): WorkspacePermissionsAndUsers {
  let newPerms: WorkspacePermissionsAndUsers = {
    administrators: [],
    writers: [],
    readers: [],
    unspecifiedUsers: [],
    globalAccess: permissions.globalAccess,
  };

  switch (value) {
    case ShareType.ADMIN:
      newPerms.administrators = permissions.administrators.concat(editedUsers);
      newPerms.writers = permissions.writers.concat(editedUsers);
      newPerms.readers = permissions.readers.concat(editedUsers);
      newPerms.unspecifiedUsers = permissions.unspecifiedUsers.filter(
        (user) => !editedUsers.includes(user)
      );
      break;
    case ShareType.EDIT:
      newPerms.administrators = permissions.administrators.filter(
        (user) => !editedUsers.includes(user)
      );
      newPerms.writers = permissions.writers.concat(editedUsers);
      newPerms.readers = permissions.readers.concat(editedUsers);
      newPerms.unspecifiedUsers = permissions.unspecifiedUsers.filter(
        (user) => !editedUsers.includes(user)
      );
      break;
    case ShareType.READ:
      newPerms.administrators = permissions.administrators.filter(
        (user) => !editedUsers.includes(user)
      );
      newPerms.writers = permissions.writers.filter(
        (user) => !editedUsers.includes(user)
      );
      newPerms.readers = permissions.readers.concat(editedUsers);
      newPerms.unspecifiedUsers = permissions.unspecifiedUsers.filter(
        (user) => !editedUsers.includes(user)
      );
      break;
    case ShareType.NO_ACCESS:
      newPerms.administrators = permissions.administrators.filter(
        (user) => !editedUsers.includes(user)
      );
      newPerms.writers = permissions.writers.filter(
        (user) => !editedUsers.includes(user)
      );
      newPerms.readers = permissions.readers.filter(
        (user) => !editedUsers.includes(user)
      );
      newPerms.unspecifiedUsers =
        permissions.unspecifiedUsers.concat(editedUsers);
      break;
    case ShareType.REMOVE:
      newPerms.administrators = permissions.administrators.filter(
        (user) => !editedUsers.includes(user)
      );
      newPerms.writers = permissions.writers.filter(
        (user) => !editedUsers.includes(user)
      );
      newPerms.readers = permissions.readers.filter(
        (user) => !editedUsers.includes(user)
      );
      newPerms.unspecifiedUsers = permissions.unspecifiedUsers.filter(
        (user) => !editedUsers.includes(user)
      );
      break;
  }

  return newPerms;
}
