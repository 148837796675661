import React, { useState, FC, SetStateAction, Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { login, registerEmail } from '../../state/user/user.actions';
import { CenteredContentWithLogo } from '../layouts/CenteredContentWithLogo';
import {Checkbox} from '../Checkbox';
import { Localized } from '../../strings';

const ArrowRight: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    viewBox="0 0 14 12"
    className="inline stroke-current"
  >
    <path
      fill="none"
      d="M303,21 L308.66,26.836592 M303,32.836593 L308.66,27 M296,26.92 L308.99,26.92"
      transform="translate(-296 -21)"
    />
  </svg>
);

const termsOfUseUrl = 'https://www.hoylu.com/terms-of-use/'; // TODO: Do we have a spot where we store external links?

type RegisterFunction = (email: string) => void;

type LoginPromptProps = {
  login: () => void;
  register: RegisterFunction;
  waitingToEditId: string | null;
};
export const LoginPromptContent: React.FunctionComponent<LoginPromptProps> = ({
  login,
  register,
  waitingToEditId
}) => {
  const strings = Localized.object("LOGIN_PROMPT");
  const [enterGuest, setEnterGuest] = useState(false);

  return (
    <CenteredContentWithLogo>
      <p className="py-5 h-sm:py-10 text-center text-3xl font-body text-primary-text">
        {strings.WELCOME_TO_HOYLU}
      </p>
      {!enterGuest && (
        <React.Fragment>
          <div className="flex justify-center items-center w-full py-2">
            <button
              onClick={login}
              type="submit"
              className="object-fill w-full flex-col items-center bg-primary hover:text-accent-hover text-gray-400 rounded-full shadow-2xl py-4 px-6"
              title={strings.LOGIN}
            >
              <p className="text-center text-base font-body">{strings.LOGIN}</p>
            </button>
          </div>
          <p className="text-center text-sm font-body text-primary-text">{strings.OR}</p>
          <div className="flex justify-center items-center w-full py-2">
            <button
              onClick={() => setEnterGuest(true)}
              type="submit"
              className="object-fill w-full flex-col items-center bg-primary hover:text-accent-hover text-gray-400 rounded-full shadow-2xl py-4 px-6"
              title={waitingToEditId ? strings.ENTER_AS_GUEST : strings.SIGN_UP}
            >
              <p className="text-center text-base font-body">
                {waitingToEditId ? strings.ENTER_AS_GUEST : strings.SIGN_UP}
              </p>
            </button>
          </div>
        </React.Fragment>
      )}
      {enterGuest && <RegisterEmailContent register={register} setEnterGuest={setEnterGuest} />}
    </CenteredContentWithLogo>
  );
};

type RegisterEmailProps = {
  register: RegisterFunction;
  setEnterGuest: Dispatch<SetStateAction<boolean>>;
};

const RegisterEmailContent: React.FunctionComponent<RegisterEmailProps> = ({
  register,
  setEnterGuest
}) => {
  const strings = Localized.object("LOGIN_PROMPT");
  const [hasAgreed, setHasAgreed] = useState(false);
  const [email, setEmail] = useState('');
  return (
    <div>
      <div className="flex justify-between my-2 bg-primary rounded-full shadow-2xl py-4 px-4 text-base">
        <input
          type="email"
          className="bg-transparent text-accent"
          placeholder={strings.ENTER_EMAIL}
          onChange={e =>
            setEmail(e.target.checkValidity() ? e.target.value : '')
          }
        />
        <button
          type="submit"
          className={
            (hasAgreed && email ? 'text-accent' : 'text-light-primary')
          }
          disabled={!(hasAgreed && email)}
          onClick={_ => register(email)}
        >
          <p title={strings.JOIN}>
            {strings.JOIN} <ArrowRight />
          </p>
        </button>
      </div>
      <div className="flex justify-center py-4">
        <div className='flex items-center font-body text-sm text-primary-text mx-auto'>
          <Checkbox
            checked={hasAgreed}
            styles="align-middle"
            onChange={() => setHasAgreed(!hasAgreed)}
          />
          <label htmlFor="agree" className="px-1">
          {strings.AGREE} {' '}
            <button
              onClick={() => window.open(termsOfUseUrl, '_blank')}
              className="text-accent cursor-pointer"
              title={strings.TERMS}
            >
              {strings.TERMS}
            </button>
          </label>
        </div>
      </div>
      <div className="flex justify-center pt-5">
        <button
          onClick={() => setEnterGuest(false)}
          className="font-body text-sm text-accent cursor-pointer"
          title={strings.BACK_TO_LOGIN}
        >
          {strings.BACK_TO_LOGIN}
        </button>
      </div>
    </div>
  );
};

const LoginPrompt: React.FunctionComponent<PromptProps> = ({
  login,
  registerEmail,
  flags,
  waitingToEditID
}) => {
  return <LoginPromptContent login={login} register={registerEmail} waitingToEditId={waitingToEditID} />;
};

const mapStateToProps = (state: RootState) => ({
  flags: state.context.config.featureFlags,
  waitingToEditID: state.context.workspaces.waitingToEditID,
});

const mapDispatchToProps = {
  login: login.request,
  registerEmail: registerEmail.request
};

type PromptProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LoginPrompt);
