export type ShareOptionValue = {
  title: string;
  color?: string;
  border?: string;
  value: number;
};

export type User = {
  email: string,
  permission: ShareOptionValue
};

export const ShareType = {
  ADMIN: {
    title: "SHARE_TYPE.ADMIN",
    color: 'bg-sky-blue',
    value: 3

  },
  EDIT: {
    title: "SHARE_TYPE.EDIT",
    color: 'bg-light-green',
    value: 2

  },
  READ: {
    title: "SHARE_TYPE.READ",
    color: 'bg-yellow-400',
    border: 'border-yellow-400',
    value: 1

  },
  NO_ACCESS: {
    title: "SHARE_TYPE.NO_ACCESS",
    color: 'bg-red-600',
    border: 'border-red-600',
    value: 0

  },
  REMOVE: {
    title: "SHARE_TYPE.REMOVE",
    value: -1

  }
};

export enum InfoSection {
  WORKSPACE = 'workspace',
  TEMPLATE = 'template'
}

