import React from "react";

type SelectFileProps = {
  onSubmit: (file: File) => void;
  strings: any;
};

//based on packages/web-suite/src/ui/insert.modals/insert.into.workspace.tsx
const openFile = (callback: (file: File) => void) => {
  // https://stackoverflow.com/questions/16215771/how-open-select-file-dialog-via-js
  // iOS specific issues documented here:  https://stackoverflow.com/questions/50751136/change-event-and-files-property-not-updated-by-particular-files-selected-with-ph
  let input: HTMLInputElement = document.createElement("input");
  input.setAttribute("display", "none");
  document.body.appendChild(input); // iOS limitation. input element need to be in DOM otherwise "change" event is not fired.
  input.type = "file";
  input.accept = ["application/zip"].join(", ");
  const changeListener = () => {
    // need to send event before closePrompt, because element won't exist after closePrompt (if sending to react)
    if (input.files && input.files.length === 1) {
      callback(input.files[0]);
    }
    input.removeEventListener("change", changeListener);
    document.body.removeChild(input);
  };
  input.addEventListener("change", changeListener, false);
  setTimeout(() => input.click(), 100); // setTimeout is a workaround for iOS. Otherwise 'change' is not always triggered.
};

export const SelectFile: React.FC<SelectFileProps> = ({
  onSubmit,
  strings,
}) => {
  return (
    <>
      <button onClick={() => openFile(onSubmit)} className="btn btn-primary">
        {strings.BROWSE}
      </button>
    </>
  );
};
