import React from 'react';
import { connect } from 'react-redux';
import { populateInitialWorkspaces } from '../state/workspaces/workspaces.actions';
import { Localized } from '../strings';
import { defaultDashboardPageSize } from '../utils/defaultDashboardPageSize';

const mapDispatchToProps = {
  populateWorkspacesAction: populateInitialWorkspaces.request
};

type ErrorFetchingWorkspaceProps = typeof mapDispatchToProps;

export const ErrorFetchingWorkspace: React.FC<ErrorFetchingWorkspaceProps> = ({
  populateWorkspacesAction
}) => {
  const strings = Localized.object("ERROR_FETCHING_WORKSPACE");
  return (
    <div className="text-theme-title flex flex-col items-center max-w-sm m-auto mt-8">
      <h1 className="text-2xl">{strings.ERROR_FETCHING_WORKSPACES}</h1>
      <button
        className="btn btn-primary"
        onClick={() => populateWorkspacesAction({limit: defaultDashboardPageSize})}
      >
        {strings.TRY_AGAIN}
      </button>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ErrorFetchingWorkspace);
