import {createReducer, isActionOf} from "typesafe-actions";
import * as actions from "./user.actions";
import { AjaxError } from "rxjs/ajax";

import { HoyluToken, LicenseInfo} from "./user.actions";
import { decodeToken } from "../../utils/credential.helpers";
import { resetState } from "../root.action";
import { retryableFailures } from "../workspaces/retryableFailures";
import { checkUrlPathForWorkspaceId } from "../../utils/url.helpers";
import { emailToHSL } from "@hoylu/client-common";

export const userMarbleDefaultColor = "var(--global--light-primary--Color)";

export interface OrgPolicies {
  workspacesPrivateByDefault?: boolean;
  allOrgAdminsHaveAdminAccess?: boolean;
}

export interface UserState {
  token: HoyluToken;
  isLoggedIn: boolean | "UNKNOWN";
  requiresPassword: boolean;
  orgBannerUrl: string;
  orgBannerLink?: string;
  profile: User;
  skipEmailValidation: boolean;
  isProfileOpen: boolean;
  userMarbleColor: string;
  licenseInfo?: LicenseInfo;
  isReloggedUser?: boolean;
  policies?: OrgPolicies;
}

export interface User {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  isEmailValidated?: boolean;
  createdTime?: number;
  isPartialAccount: boolean;
  licenseValid: boolean;
  orgId: string;
  permissions: {
    read: string[];
    write: string[];
    shareExternal: string[];
    administer: string[];
  };
}

const defaultProfile = {
  userId: "",
  firstName: "",
  lastName: "",
  email: "",
  isPartialAccount: false,
  licenseValid: false,
  orgId: "",
  permissions: { read: [], write: [], shareExternal: [], administer: [] },
  isEmailValidated: false,
};

export const defaultState = (): UserState => ({
  token: "",
  isLoggedIn: "UNKNOWN",
  requiresPassword: false,
  orgBannerUrl: "",
  orgBannerLink: undefined,
  profile: defaultProfile,
  isProfileOpen: false,
  isReloggedUser: false,
  skipEmailValidation: false,
  userMarbleColor: userMarbleDefaultColor
});

export default createReducer<UserState>(defaultState())
  .handleAction(resetState, () => defaultState())
  .handleAction(actions.toggleUserDetails, (state) => ({
    ...state,
    isProfileOpen: !state.isProfileOpen,
  }))
  .handleAction(
    [actions.getToken.request, actions.registerEmail.request],
    (state) => ({
      ...state,
      token: "",
    })
  )
  .handleAction(
    [
      actions.login.success,
      actions.getToken.success,
      actions.registerEmail.success,
    ],
    (state, action) => {
      let profile: User = defaultProfile;
      if (action.payload.token) {
        profile = decodeToken(action.payload.token) as User;
      }
      //Force reload to avoid stuck in dashboard after re-log from workspace
      if (isActionOf(actions.login.success, action) && checkUrlPathForWorkspaceId(window.location.pathname)){
        window.location.reload()
      }
      return {
        ...state,
        token: action.payload.token,
        isLoggedIn: !!action.payload.token,
        requiresPassword: false,
        orgBannerUrl: "",
        orgBannerLink: undefined,
        profile,
        isReloggedUser: state.profile.userId === profile.userId,
      };
    }
  )
  .handleAction(actions.getOrgPolicies.success, (state, action) => ({
    ...state,
    requiresPassword: action.payload.policies.includes(
      "documents_require_password"
    ),
    policies: {
      workspacesPrivateByDefault: action.payload.policies.includes(
        "workspaces_private_by_default"
      ),
      allOrgAdminsHaveAdminAccess: action.payload.policies.includes(
        "all_org_admins_get_admin_access"
      ),
    },
  }))
  .handleAction(
    [
      actions.login.failure,
      actions.logout.success,
      actions.logout.failure,
      actions.registerEmail.failure,
    ],
    () => ({
      ...defaultState(),
      isLoggedIn: false,
    })
  )
  .handleAction(actions.getToken.failure, (state, action) => {
    // do not clear login if another request is in progress
    if (action.payload.message === "request in progress") {
      return { ...state };
    }
    return {
      ...defaultState(),
      profile: {
        ...defaultState().profile,
        // we keep userId to check if after relogging we still have the same user and load him workspace from which he was kicked out
        userId: state.profile.userId,
      },
      isLoggedIn: false,
    };
  })
  .handleAction(actions.getOrgBanner.success, (state, action) => ({
    ...state,
    orgBannerUrl: action.payload.bannerBlobUrl,
  }))
  .handleAction(actions.getOrgBannerConfig.success, (state, action) => ({
    ...state,
    orgBannerLink: action.payload.bannerLink,
  }))
  .handleAction(actions.sendValidationEmail.success, () => ({
    ...defaultState(),
    isLoggedIn: false,
  }))
  .handleAction(actions.getLicenseInfo.success, (state, action) => ({
    ...state,
    licenseInfo: action.payload,
  }))
  .handleAction(retryableFailures, (state, action) => ({
    ...state,
    // don't clear token on errors that are not 401
    token:
      typeof action.payload.error !== "string" &&
      (action.payload.error as AjaxError).status === 401
        ? ""
        : state.token,
  }))
  .handleAction(actions.getUserProfile.success, (state, action) => {
    if (state.profile.isEmailValidated === action.payload.emailValidated)
      return state;
    return {
      ...state,
      userMarbleColor: emailToHSL(action.payload.email),
      profile: {
        ...state.profile,
        isEmailValidated: action.payload.emailValidated,
        createdTime: action.payload.creationTime
      },
    };
  })
  .handleAction(actions.skipValidationEmail.success, (state) => ({
    ...state,
    skipEmailValidation: true,
  }));
