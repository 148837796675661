import React from 'react';
import { Localized } from '../../../strings';

//TODO: consider adding our fonts instead of putting svgs here

export function shareIcon (){
  return (
    <div
      title="Share"
      className="hidden sm:block pr-1"
    >
      <svg className="text-primary-text fill-current" xmlns="http://www.w3.org/2000/svg" width={"24"} height={"24"} viewBox="0 0 32 32">
        <path d="M25.344 11.68h-4.096c-1.952 0-3.584 1.28-4.128 3.072h-2.304c-0.544-1.76-2.176-3.072-4.128-3.072h-4.096c-2.368 0-4.288 1.952-4.288 4.32s1.92 4.32 4.288 4.32h4.096c1.952 0 3.584-1.28 4.128-3.072h2.304c0.544 1.76 2.176 3.072 4.128 3.072h4.096c2.368 0 4.288-1.952 4.288-4.32 0.032-2.368-1.888-4.32-4.288-4.32zM10.72 17.664h-4.096c-0.992 0-1.76-0.672-1.76-1.664 0-0.96 0.8-1.664 1.76-1.664h4.096c0.448 0 0.864 0.16 1.184 0.416h-1.184c-0.608 0-1.12 0.48-1.12 1.088v0.32c0 0.608 0.512 1.088 1.12 1.088h1.184c-0.32 0.256-0.704 0.416-1.184 0.416zM25.344 17.664h-4.096c-0.448 0-0.864-0.16-1.184-0.416h1.184c0.608 0 1.12-0.48 1.12-1.088v-0.32c0-0.608-0.512-1.088-1.12-1.088h-1.184c0.32-0.256 0.736-0.416 1.184-0.416h4.096c0.992 0 1.76 0.672 1.76 1.664 0.032 0.96-0.768 1.664-1.76 1.664z"></path>
      </svg>
    </div>
  );
}

export function closeIcon(){
  return(
    <svg
      className={"fill-current"}
      version="1.1" xmlns="http://www.w3.org/2000/svg"
      viewBox="-4 -4 40 40"
    >
      <path d="M27.2 24.48v2.72h-2.72l-8.48-8.48-8.48 8.48h-2.72v-2.72l8.48-8.48-8.48-8.48v-2.72h2.72l8.48 8.48 8.48-8.48h2.72v2.72l-8.48 8.48z"></path>
    </svg>
  );
}

export function usersIcon() {
  return (
    <svg className={`fill-current`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>{Localized.string("SHARING_ICONS.USERS")}</title>
      <path d="M11.712 17.152c0.608 0 1.184-0.16 1.728-0.448-0.32-0.992-0.512-2.048-0.512-3.168 0-2.528 0.928-4.768 2.336-6.272-0.832-1.408-2.112-2.304-3.552-2.304-2.528 0-4.544 2.72-4.544 6.112 0 3.36 2.048 6.080 4.544 6.080z"></path>
      <path d="M15.648 20.128c-0.576-0.512-1.056-1.12-1.472-1.824-0.8-0.096-1.6-0.192-2.464-0.192-5.44 0-9.824 2.368-9.824 5.248 0 0 2.912 0 6.912 0 1.472-1.28 3.904-2.688 6.848-3.232z"></path>
      <path d="M24.352 13.536c0 3.676-2.235 6.656-4.992 6.656s-4.992-2.98-4.992-6.656c0-3.676 2.235-6.656 4.992-6.656s4.992 2.98 4.992 6.656z"></path>
      <path d="M19.36 27.040c-5.952 0-10.752 0-10.752 0 0-3.168 4.8-5.76 10.752-5.76s10.752 2.592 10.752 5.76c0 0-4.8 0-10.752 0z"></path>
    </svg>

  );
}

export function permissionsIcon (){
  return (
    <div
      title={Localized.string("SHARING_ICONS.PERMISSIONS")}
      className="pr-1"
    >
      <svg className="text-primary-text fill-current" version="1.1" xmlns="http://www.w3.org/2000/svg" width={"24"} height={"24"} viewBox="0 0 32 32">
        <path d="M26.912 22.56l-9.888-9.888c0.384-1.888-0.096-4.064-1.632-5.6-2.368-2.368-6.176-2.336-8.544 0s-2.336 6.176 0 8.512c1.536 1.536 3.744 1.984 5.632 1.6l1.376 1.376 3.104-0.288-0.224 2.656 2.336-0.128-0.192 2.176 1.504 0.032-0.192 1.44 2.272 0.064-0.352 2.336 4.576 0.032 0.224-4.32zM8.192 11.552c-0.864-0.864-0.864-2.24 0-3.104s2.24-0.864 3.104 0c0.864 0.864 0.864 2.24 0 3.104s-2.24 0.864-3.104 0z"></path>
      </svg>
    </div>
  );
}

export function unlockedIcon(){
  return (
    <svg className={`fill-current`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>{Localized.string("SHARING_ICONS.UNLOCKED")}</title>
      <path d="M24.16 5.536c0.512 0.224 0.992 0.544 1.376 0.928s0.704 0.832 0.928 1.376c0.224 0.512 0.352 1.088 0.352 1.664v4.32h-2.88v-4.288c0-0.192-0.032-0.384-0.128-0.544-0.064-0.192-0.192-0.32-0.32-0.448s-0.288-0.256-0.448-0.32c-0.192-0.064-0.352-0.128-0.544-0.128h-2.88c-0.192 0-0.384 0.032-0.544 0.128-0.192 0.064-0.32 0.192-0.448 0.32s-0.256 0.288-0.32 0.448c-0.064 0.192-0.128 0.352-0.128 0.544v4.32h0.352c0.16 0 0.288 0.032 0.416 0.096s0.256 0.128 0.352 0.224c0.096 0.096 0.16 0.224 0.224 0.352s0.096 0.256 0.096 0.416v10.816c0 0.16-0.032 0.288-0.096 0.416s-0.128 0.256-0.224 0.352c-0.096 0.096-0.224 0.16-0.352 0.224s-0.256 0.096-0.416 0.096h-12.256c-0.16 0-0.288-0.032-0.416-0.096s-0.256-0.128-0.352-0.224c-0.096-0.096-0.16-0.224-0.224-0.352s-0.096-0.256-0.096-0.416v-10.848c0-0.16 0.032-0.288 0.096-0.416s0.128-0.256 0.224-0.352c0.096-0.096 0.224-0.16 0.352-0.224s0.256-0.096 0.416-0.096h8.992v-4.288c0-0.608 0.128-1.152 0.352-1.664s0.544-0.992 0.928-1.376c0.384-0.384 0.832-0.704 1.376-0.928 0.512-0.224 1.088-0.352 1.664-0.352h2.88c0.608 0 1.152 0.096 1.696 0.32z"></path>
    </svg>
    
  );
}

export function lockedIcon(){
  return (
    <svg className={`fill-current`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>{Localized.string("SHARING_ICONS.LOCKED")}</title>
      <path d="M22.112 13.856c0.16 0 0.288 0.032 0.416 0.096s0.256 0.128 0.352 0.224c0.096 0.096 0.16 0.224 0.224 0.352s0.096 0.256 0.096 0.416v10.816c0 0.16-0.032 0.288-0.096 0.416s-0.128 0.256-0.224 0.352c-0.096 0.096-0.224 0.16-0.352 0.224s-0.256 0.096-0.416 0.096h-12.224c-0.16 0-0.288-0.032-0.416-0.096s-0.256-0.128-0.352-0.224c-0.096-0.096-0.16-0.224-0.224-0.352s-0.096-0.256-0.096-0.416v-10.848c0-0.16 0.032-0.288 0.096-0.416s0.128-0.256 0.224-0.352c0.096-0.096 0.224-0.16 0.352-0.224s0.256-0.096 0.416-0.096h0.352v-4.288c0-0.608 0.128-1.152 0.352-1.664s0.544-0.992 0.928-1.376c0.384-0.384 0.832-0.704 1.376-0.928 0.512-0.224 1.088-0.352 1.664-0.352h2.88c0.608 0 1.152 0.128 1.664 0.352s0.992 0.544 1.376 0.928c0.384 0.384 0.704 0.832 0.928 1.376 0.224 0.512 0.352 1.088 0.352 1.664v4.32h0.352zM13.12 9.536v4.32h5.76v-4.32c0-0.192-0.032-0.384-0.128-0.544-0.064-0.192-0.192-0.32-0.32-0.448s-0.288-0.256-0.448-0.32c-0.192-0.064-0.352-0.128-0.544-0.128h-2.88c-0.192 0-0.384 0.032-0.544 0.128-0.192 0.064-0.32 0.192-0.448 0.32s-0.256 0.288-0.32 0.448c-0.096 0.16-0.128 0.32-0.128 0.544z"></path>
    </svg>
  );
}

export function arrowUp() {
  return (
    <svg className={"fill-current"} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>{Localized.string("SHARING_ICONS.COLLAPSE")}</title>
      <path d="M23.968 22.4c-0.32 0.32-0.832 0.32-1.152 0l-6.816-6.816-6.816 6.816c-0.32 0.32-0.832 0.32-1.152 0l-2.144-2.112c-0.32-0.32-0.32-0.864 0-1.184l9.536-9.504c0.32-0.32 0.832-0.32 1.152 0l9.536 9.536c0.32 0.32 0.32 0.864 0 1.184l-2.144 2.080z"></path>
    </svg>
  );
}

export function arrowDown() {
  return (
    <svg className={"fill-current"} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>{Localized.string("SHARING_ICONS.EXPAND")}</title>
      <path d="M8.032 9.6c0.32-0.32 0.832-0.32 1.152 0l6.816 6.816 6.816-6.816c0.32-0.32 0.832-0.32 1.152 0l2.144 2.112c0.32 0.32 0.32 0.864 0 1.184l-9.536 9.504c-0.32 0.32-0.832 0.32-1.152 0l-9.536-9.504c-0.32-0.32-0.32-0.864 0-1.184l2.144-2.112z"></path>
    </svg>
  );
}

export function email() {
  return (
    <svg className={"fill-current"} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>{Localized.string("COPY_EMAIL_LINK.EMAIL")}</title>
      <path d="M4.16 7.040v0.512l11.904 6.080 11.776-5.984v-0.608z"></path>
      <path d="M4.16 10.72v14.24h23.68v-14.176l-11.776 5.984z"></path>
    </svg>
  );
}

export function copy() {
  return (
    <svg className={"fill-current"} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>{Localized.string("COPY_EMAIL_LINK.COPY_LINK")}</title>
      <path d="M11.84 8.32h4.48v-0.352l-4.48-4.128z"></path>
      <path d="M21.12 17.92v-5.76h-5.44v16h11.2v-10.24z"></path>
      <path d="M22.4 16.64h4.48v-0.352l-4.48-4.128z"></path>
      <path d="M10.56 9.6v-5.76h-5.44v16h9.28v-8.96h1.92v-1.28z"></path>
    </svg>
  );
}

export function checkMark() {

  return (
    <svg className={"fill-current"} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>{Localized.string("COPY_EMAIL_LINK.SMALL_COPIED_LINK")}</title>
      <path d="M14.432 27.616c-0.096 0-0.192-0.032-0.288-0.096l-11.072-7.648c-0.224-0.16-0.288-0.48-0.128-0.704l1.728-2.464c0.096-0.128 0.256-0.224 0.416-0.224 0.096 0 0.192 0.032 0.288 0.096l8.192 5.664 12.192-17.664c0.096-0.128 0.256-0.224 0.416-0.224 0.096 0 0.192 0.032 0.288 0.096l2.496 1.696c0.224 0.16 0.288 0.48 0.128 0.704l-14.24 20.576c-0.096 0.128-0.224 0.192-0.416 0.192z"></path>
    </svg>
  );
}