import {createReducer} from "typesafe-actions";
import type {ConfigResponse} from "./config.actions";
import * as actions from "./config.actions";
import {HostClientType} from "@microsoft/teams-js";
import {resetState} from "../root.action";
import {TeamsFrameContexts} from "../../utils/detectTeams";
import {defaultLaunchDarklyFeatureFlag} from "@hoylu/client-common";

export type FeatureFlags = {
  isAnonymous: boolean;
  enableBetaAccess: boolean;
  enableSupplyChain: boolean;
  enableWhatsNew: boolean;
  enableOrgBanner: boolean;
  redirectPullPlanningV3ToLegacy: boolean;
  enableUpgradeWithinApp: boolean;
  validateEmailNotification: boolean;
  highlightEnterpriseFeatures: boolean;
  enableHubspot: boolean;
  enablePendo: boolean;
  enablePendoPII: boolean;
  projectLicensing: boolean;
  showWorkspaceWeight: boolean;
  duplicateApiSupportsPassword: boolean;
  workspaceTemplates: boolean;
  showLegacyModules: boolean;
  showTemplateBasedModules: boolean;
  enableWorkspaceExport: boolean;
  enableWorkspaceInfo: boolean;
  enableDocumentIdNewV3Api: boolean;
  showTemplateCreationUI: boolean;
  enableFavouritesCategory: boolean;
  enableFavoritesWorkspaceSection: boolean;
  enableInAppProjectCreation: boolean;
  showCreditLimitUi: boolean;
  orgTemplates: boolean;
  showNotificationsUI: boolean;
};

export interface WebexEmbeddedAppState {
  isInSelectorMode: boolean;
  isShared: boolean;
}

export interface ConfigState extends ConfigResponse {
  isDev: boolean;
  isLoaded: boolean;
  isBeta: boolean;
  workspaceIdeasId?: string;
  featureFlags: FeatureFlags;
  teamsClient: HostClientType | null;
  teamsFrame: TeamsFrameContexts | null;
  webexMode: WebexEmbeddedAppState | null;
  newVersion: boolean;
}

export const defaultConfigState = (): ConfigState => ({
  isDev: false,
  isLoaded: false,
  isBeta: false,
  teamsClient: null,
  teamsFrame: null,
  webexMode: null,
  featureFlags: {
    isAnonymous: true,
    enableBetaAccess: !!defaultLaunchDarklyFeatureFlag.get("allow-beta-access"),
    enableSupplyChain: !!defaultLaunchDarklyFeatureFlag.get("supply-chain-module"),
    enableWhatsNew: !!defaultLaunchDarklyFeatureFlag.get("whats-new"),
    enableOrgBanner: !!defaultLaunchDarklyFeatureFlag.get("organization-banner"),
    redirectPullPlanningV3ToLegacy: !!defaultLaunchDarklyFeatureFlag.get("ppv3-in-legacy"),
    enableUpgradeWithinApp: !!defaultLaunchDarklyFeatureFlag.get("upgrade-from-within-app"),
    validateEmailNotification: !!defaultLaunchDarklyFeatureFlag.get("validate-email-notification"),
    highlightEnterpriseFeatures: !!defaultLaunchDarklyFeatureFlag.get("highlight-enterpise-features"),
    enableHubspot: !!defaultLaunchDarklyFeatureFlag.get("enable-hubspot"),
    enablePendo: !!defaultLaunchDarklyFeatureFlag.get("enable-pendo"),
    enablePendoPII: !!defaultLaunchDarklyFeatureFlag.get("enable-pendo-pii"),
    projectLicensing: !!defaultLaunchDarklyFeatureFlag.get("project-licensing"),
    showWorkspaceWeight: !!defaultLaunchDarklyFeatureFlag.get("show-workspace-size"),
    duplicateApiSupportsPassword: !!defaultLaunchDarklyFeatureFlag.get("duplicate-api-supports-password"),
    workspaceTemplates: !!defaultLaunchDarklyFeatureFlag.get("workspace-templates"),
    showLegacyModules: !!defaultLaunchDarklyFeatureFlag.get("show-legacy-modules"),
    showTemplateBasedModules: !!defaultLaunchDarklyFeatureFlag.get("show-template-based-modules"),
    enableWorkspaceExport: !!defaultLaunchDarklyFeatureFlag.get("Workspace-Export"),
    enableWorkspaceInfo: !!defaultLaunchDarklyFeatureFlag.get("workspace-info-section"),
    enableDocumentIdNewV3Api: !!defaultLaunchDarklyFeatureFlag.get("use-document-id-new-v-3-api"),
    showTemplateCreationUI: !!defaultLaunchDarklyFeatureFlag.get("show-template-creation-ui"),
    enableFavouritesCategory: !!defaultLaunchDarklyFeatureFlag.get("enable-favourites-category"),
    enableFavoritesWorkspaceSection: !!defaultLaunchDarklyFeatureFlag.get("enable-favorites-ws-section"),
    enableInAppProjectCreation: !!defaultLaunchDarklyFeatureFlag.get("enable-in-app-project-creation"),
    showCreditLimitUi: !!defaultLaunchDarklyFeatureFlag.get("show-credit-limit-ui"),
    orgTemplates: !!defaultLaunchDarklyFeatureFlag.get("org-templates"),
    showNotificationsUI: !!defaultLaunchDarklyFeatureFlag.get("notifications"),
  },
  serviceConfig: {
    login: "",
    auth: "",
    documentImages: "",
    documentMetadata: "",
    metrics: undefined,
    hoylu_app: "",
    hoylu_appbeta: "",
    licensing: "",
    management_portal: "",
    transfer: "",
    notification: ""
  },
  newVersion: false,
});

export default createReducer<ConfigState>(defaultConfigState())
  .handleAction(resetState, () => defaultConfigState())
  .handleAction(actions.updateConfig, (state, action) => ({
    ...state,
    ...action.payload,
  }))
  .handleAction(actions.updateFlags, (state, action) => ({
    ...state,
    featureFlags: action.payload,
  }))
  .handleAction(actions.getConfig.success, (state, action) => ({
    ...state,
    isLoaded: true,
    ...action.payload,
  }))
  .handleAction(actions.teamsClient, (state, action) => ({
    ...state,
    teamsClient: action.payload,
  }))
  .handleAction(actions.teamsFrame, (state, action) => ({
    ...state,
    teamsFrame: action.payload,
  }))
  .handleAction(actions.webexDetected, (state, action) => ({
    ...state,
    webexMode: action.payload,
  }))
  .handleAction(actions.setWebexShareState, (state, action) => ({
    ...state,
    webexMode: {
      ...state.webexMode!,
      isShared: action.payload,
    },
  }))
  .handleAction(actions.updateVersion, (state, action) => ({
    ...state,
    newVersion: action.payload,
  }));
