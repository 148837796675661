import React from "react";

export type StandaloneIconProps = {
  icon: JSX.Element;
  isSelected?: boolean;
  title?: string;
  onClick?: () => void;
};

/**
 * StandaloneIcon should be use in places where it can be used as standalone component.
 * It has own styles like corner radius, colours and hover styles.
 * */
export const StandaloneIcon: React.FC<StandaloneIconProps> = ({
  icon,
  isSelected,
  title,
  onClick,
}) => {
  // we need title in div, alas in many cases the prop title is undefined,
  // but passed icons also have title so we extract value of <title> element from svg
  if (!title && Array.isArray(icon.props.children)) {
    const titleNode = icon.props.children.find(
      (child: JSX.Element) => child.type === "title"
    );

    // svg element <title> has as its child a simple text
    title = titleNode.props?.children;
  }

  const sharedStyle: string =
    "text-indicator rounded cursor-pointer p-1 w-8 h-8";
  const defaultStyle: string = `${sharedStyle} hover:text-accent hover:bg-opacity-15`;
  const selectedStyle: string = `${sharedStyle} bg-opacity-15`;
  const iconStyle = !isSelected ? defaultStyle : selectedStyle;

  return (
    <div
      className={`${iconStyle}`}
      title={title}
      onClick={(e) => {
        if (onClick) {
          onClick();
          e.stopPropagation();
        }
      }}
    >
      {icon}
    </div>
  );
};
