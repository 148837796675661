import { log } from "@hoylu/client-common";
import { RootState } from "typesafe-actions";

let lastUseV3Value: boolean | undefined = undefined;
/**
 * Helper to determine if we should use the v3 document id api.
 * Once used it will not change anymore because switching between v1 and v3 after the first call may cause unexpected results.
 */
export const useV3 = (state: RootState) => {
  const flagState = !!state.context.config?.featureFlags?.enableDocumentIdNewV3Api;
  if (lastUseV3Value === undefined) {
    lastUseV3Value = flagState;
  }
  if (lastUseV3Value !== flagState) {
    log.info("Prevented too late documentid version switch", {
      inUseVersion: lastUseV3Value,
      requestedVersion: flagState,
    });
  }
  return lastUseV3Value;
};
/** Only for tests: Reset useV3 */

export const resetUseV3ForTests = () => {
  lastUseV3Value = undefined;
};
