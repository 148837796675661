import { user } from "../../state/user/user.selector";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "./Modal";
import React, { useCallback } from "react";
import { CloseIcon } from "./modalElements/CloseIcon";
import { Localized } from "../../strings";
import {
  activateDashboardOption,
  cancelDashboardOption,
} from "../../state/workspaces/workspaces.actions";
import { Button } from "./modalElements/Button";
import { DashboardOption } from "../../state/workspaces/DashboardOption";
import { callWithLoginConfirm } from "../../state/user/user.actions";
import { managementPortal } from "../../state/config/config.selector";

export const ManageAccount = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(user);
  const managementPortalUrl = useSelector(managementPortal);

  const strings = Localized.object("MANAGE_ACCOUNT");
  const close = useCallback(
    () => dispatch(cancelDashboardOption()),
    [dispatch]
  );
  return (
    <Modal handleOutsideClick={close} padding={"p-3"}>
      <div className="w-full inline-flex justify-end items-center pb-3">
        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-row">
            <div className="text-indicator font-bold text-base ">
              {strings.TITLE}
            </div>
          </div>
        </div>
        {<CloseIcon onClose={close} />}
      </div>
      <div className="p-1 pb-4 flex flex-col w-full justify-between gap-4">
        <div className="gap-4 flex flex-row w-full justify-between items-center">
          <span>{strings.EMAIL}</span>
          <span>{currentUser.profile.email}</span>
        </div>
        <div className="gap-4 flex flex-row w-full justify-between items-center">
          <span>{strings.CREATED}</span>
          {currentUser.profile.createdTime && (
            <span>
              {new Date(currentUser.profile.createdTime).toLocaleDateString()}
            </span>
          )}
        </div>
      </div>
      <div className={"flex justify-between items-center"}>
        <Button
          label={strings.DELETE}
          onButtonClick={() =>
            dispatch(
              callWithLoginConfirm({
                accept: activateDashboardOption({
                  optionType: DashboardOption.CONFIRM_ACCOUNT_DELETE,
                }),
                reject: cancelDashboardOption(),
              })
            )
          }
          additionalStyling={"btn-secondary"}
        >
          {strings.DELETE + "..."}
        </Button>
        <Button
          label={strings.MANAGE}
          onButtonClick={() =>
            window.open(`${managementPortalUrl}/u`, "_blank")
          }
          additionalStyling={"btn-primary"}
        >
          {strings.MANAGE}
        </Button>
      </div>
    </Modal>
  );
};
