import React, { useMemo } from "react";
import UserProfileWidget from "../UserProfileWidget";
import HoyluLogo from "../HoyluLogo";
import HeaderButtons from "./HeaderButtons";
import ProjectNavigationDropdown, {
  ProjectSelectionHandler,
} from "./ProjectNavigationDropdown";
import { FilterByWorkspaceTag } from "../FilterByWorkspaceTag";
import { FilterByWorkspaceName } from "../FilterByWorkspaceName";
import { WorkspaceLabels } from "../../state/workspaces/types";
import { FlatRippleSpinner } from "../RippleSpinner";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { AppMode } from "../../state/mode/mode.reducer";
import { activateDashboardOption } from "../../state/workspaces/workspaces.actions";
import { DashboardOption } from "../../state/workspaces/DashboardOption";
import {
  getAllProjects,
  getSelectedProjectColor,
} from "../../state/workspaces/projects.selector";
import { getAllLabels } from "../../state/workspaces/workspaces.selector";
import { hexToRgba } from "../../utils/hexToRgba";
import { enableInAppProjectCreation, showNotificationsUI } from "../../state/config/config.selector";
import { ProjectStatuses } from "../../state/workspaces/workspaces.reducer";
import { ProjectNavigationInformation } from "./ProjectNavigationInformation";
import NotificationMenu from "./NotificationMenu";

const mapStateToProps = (state: RootState) => ({
  allLabels: getAllLabels(state),
  allProjects: getAllProjects(state),
  isLoggedIn: state.context.user.isLoggedIn === true,
  isLoading:
    state.mode === AppMode.LOADING_DASHBOARD ||
    state.mode === AppMode.LOADING_WORKSPACE ||
    state.context.workspaces.isLoadingSelectedProjectWorkspaces,
  projectThemeColor: getSelectedProjectColor(state),
  selectedProject: state.context.workspaces.selectedProject,
  searchTerm: state.context.workspaces.searchTerm,
  showProjects: state.context.config.featureFlags.projectLicensing,
  allowCreatingProject: enableInAppProjectCreation(state),
  showSearch: ![
    AppMode.NOT_VERIFIED_POLICY_RESTRICTED,
    AppMode.BETA_NOT_ALLOWED,
  ].includes(state.mode),
  showNotificationsUI: showNotificationsUI(state)
});

const mapDispatchToProps = {
  handleSearchClick: () =>
    activateDashboardOption({
      optionType: DashboardOption.OPEN,
    })
};

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: {
    handleProjectSelected: ProjectSelectionHandler;
    selectedLabels: WorkspaceLabels;
    handleLabelsChange: (selected: WorkspaceLabels) => void;
  }
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

type HeaderProps = ReturnType<typeof mergeProps>;

export const Header: React.FC<HeaderProps> = ({
  isLoggedIn,
  showSearch,
  handleSearchClick,
  allProjects,
  selectedProject,
  handleProjectSelected,
  showProjects,
  selectedLabels,
  allLabels,
  handleLabelsChange,
  searchTerm,
  isLoading,
  projectThemeColor,
  allowCreatingProject,
  showNotificationsUI
}) => {
  const backgroundColor = useMemo(
    () =>
      projectThemeColor
        ? hexToRgba(projectThemeColor)
        : "var(--gradient-from-color-theme)",
    [projectThemeColor]
  );

  /* Inactive projects last */
  const sortedProjects = useMemo(
    () =>
      allProjects?.sort((a, b) => {
        const statusA = a.status === ProjectStatuses.Inactive ? 1 : 0;
        const statusB = b.status === ProjectStatuses.Inactive ? 1 : 0;

        return statusA - statusB;
      }),
    [allProjects]
  );

  return (
    <div className={"w-full z-40 top-0 shadow-lg relative lg:fixed left-0"}>
      <div className={"h-8 w-full"} style={{ backgroundColor }} />
      <div className={"w-full bg-theme-header lg:px-8 xxs:px-2"}>
        <div className="font-body text-base flex items-center justify-center py-2">
          <div className="flex flex-1 items-center sm:gap-4 md:gap-8">
            <HoyluLogo />
            {isLoggedIn && (
              <HeaderButtons
                showSearch={showSearch}
                handleSearchClick={handleSearchClick}
              />
            )}
          </div>
          <div className="flex mx-auto my-0 items-center flex-col xxs:px-0 xs:px-1 lg:px-6">
            <ProjectNavigationDropdown
              allProjects={sortedProjects ?? []}
              selectedProject={selectedProject}
              handleProjectSelected={handleProjectSelected}
              showProjects={showProjects}
              allowCreatingProject={allowCreatingProject}
            />
            <ProjectNavigationInformation
              handleProjectSelected={handleProjectSelected}
            />
          </div>
          <div className={`flex flex-1 items-center justify-end w-auto gap-4`}>
            <FilterByWorkspaceName searchTerm={searchTerm} />
            <FilterByWorkspaceTag
              selectedLabels={selectedLabels}
              allLabels={allLabels}
              handleLabelsChange={handleLabelsChange}
            />
            {showNotificationsUI && <NotificationMenu />}
            <div className="flex">{isLoggedIn && <UserProfileWidget />}</div>
          </div>
        </div>
        {isLoading && <FlatRippleSpinner />}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Header);
