import { StandaloneIcon } from "./StandaloneIcon";
import React, { useState } from "react";
import {checkMark, copy} from "../../../../assets/images/icons/sharingIcons";

export type CopyIconProps = {
  workspaceLink: string,
};

export const CopyIcon: React.FC<CopyIconProps> = ({
  workspaceLink
}) => {
  const [copyClicked, setCopyClicked] = useState(false);

  const copyLink = (): void => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(workspaceLink);
    } else {
      //For electron app
      if(window.getSelection){
        let span = document.createElement("span");
        span.innerHTML = workspaceLink;
        span.setAttribute("display", "none");
        document.body.appendChild(span);
        let selection = window.getSelection();
        selection!.removeAllRanges();
        let range = document.createRange();
        range.selectNodeContents(span);
        selection!.addRange(range);
        document.execCommand('copy');
        document.body.removeChild(span);
      }
    }
  };
  const flashCheckMarkBriefly = (): void => {
    setCopyClicked(true);
    setTimeout(() => {
      setCopyClicked(false);
    }, 1000);
  }
  const copyLinkAndFlashTheMark = (): void => {
    copyLink();
    flashCheckMarkBriefly();
  }

  return (
    <div className={"mr-1"}>
      {copyClicked ? <StandaloneIcon icon={checkMark()} /> : <StandaloneIcon icon={copy()} onClick={copyLinkAndFlashTheMark}/>}
    </div>
  );
}