import React from "react";
import { closeIcon } from '../../../assets/images/icons/sharingIcons';
import { Localized } from "../../../strings";

type CloseButtonProps = {
  closeModal: () => void,
  style?: string
}

export const CloseButton: React.FC<CloseButtonProps> = ({
  closeModal,
  style = ""
}) => {
  const strings = Localized.object("CLOSE_BUTTON");
  return (
    <button className={"close-button " + style} title={strings.CLOSE} onClick={closeModal}>{closeIcon()}</button>
  );
}