import React, { useMemo } from "react";
import { FrameContexts } from "@microsoft/teams-js";
import WorkspacePreviewLabels from "./WorkspacePreviewLabels";
import { WorkspaceCardProps } from "./WorkspaceCard";

const LiveWorkspacePreview: React.FC<WorkspaceCardProps> = ({
  workspaceDetails,
  showEditLabelPrompt,
  canEditLabels,
  showProjectOptions,
  project,
  config,
  onProjectLabelClick
}) => {
  const labelsAndPotentialAssignProject =
    project && showProjectOptions
      ? workspaceDetails.labels?.concat([project])
      : workspaceDetails.labels;
  const allowFullScreen = useMemo(() => workspaceDetails.isFullscreen && config.teamsFrame != FrameContexts.sidePanel, [workspaceDetails.isFullscreen, config.teamsFrame]);
  return (
    <>
      <iframe
        name={"iframes"}
        id={workspaceDetails.workspaceId}
        title={workspaceDetails.workspaceId}
        className={`${
          allowFullScreen
            ? "fixed z-30"
            : "absolute pointer-events-none"
        } h-full w-full top-0 left-0 bg-primary`}
        src={workspaceDetails.workspaceUrl}
      />
      {!allowFullScreen && (
        <WorkspacePreviewLabels
          onProjectLabelClick={onProjectLabelClick}
          canEditLabels={!!canEditLabels}
          showEditLabelPrompt={showEditLabelPrompt}
          labels={labelsAndPotentialAssignProject}
          workspaceDetails={workspaceDetails}
        />
      )}
    </>
  );
};

export default LiveWorkspacePreview;