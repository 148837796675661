import { ajax } from "rxjs/ajax";
import { Observable, of, from } from "rxjs";
import { mergeMap, concatMap, switchMap, toArray, map } from "rxjs/operators";
import {
  MyWorkspacePermissions,
  UserWorkspaceTonnageResponse,
  WorkspaceDetails,
  WorkspacePermissionsAndUsers,
  WorkspaceRoles,
} from "../../../state/workspaces/types";
import {
  MyWorkspacesV3Response,
  UpsertWorkspaceUsersPermissions,
  assertMyWorkspaceV3Response,
  metadataV3toWorkspaceDetailsArray,
  stripInvalidWorkspaces,
  MyWorkspaceMetadataV3,
  CreateWorkspaceRequestMetadata,
  DuplicateWorkspaceRequestMetadata,
} from "./workspaces.v3.types";
import { PopulateWorkspacesResult } from "../../../state/workspaces/workspaces.actions";
import { patchJSON, postJSON, putJSON } from "../../dependencies/ajaxRequests";
import { metadataV3toWorkspaceDetails } from "./to.workspace.details";

const EmptyWorkspaceDetailsArray: WorkspaceDetails[] = [];

/** DASHBOARD ENDPOINTS **/
function getMyWorkspacesPaged(
  origin: string,
  token: string,
  userId: string,
  limit: number,
  offset?: number | string /* can remove string type once v1 is removed */,
  includeHidden?: boolean
): Observable<PopulateWorkspacesResult> {
  let url = `${origin}/api/v3/dashboard/${userId}?limit=${limit}`;
  if (offset) {
    url += `&offset=${offset}`;
  }

  if (includeHidden) {
    url += `&includeHidden=${includeHidden}`;
  }

  return ajax
    .getJSON<MyWorkspacesV3Response>(url, {
      Authorization: `Bearer ${token}`,
    })
    .pipe(
      mergeMap((response) => {
        assertMyWorkspaceV3Response(response);
        const { workspaces, offset } = stripInvalidWorkspaces(response);

        if (!workspaces || workspaces.length === 0) {
          return of({
            details: EmptyWorkspaceDetailsArray,
            cursorOrOffset: null,
          });
        } else {
          return of({
            details: metadataV3toWorkspaceDetailsArray(workspaces),
            cursorOrOffset: offset,
          });
        }
      })
    );
}

function getMyHiddenWorkspacesPaged(
  origin: string,
  token: string,
  userId: string,
  limit: number,
  offset?: number | string /* can remove string type once v1 is removed */
): Observable<PopulateWorkspacesResult> {
  let url = `${origin}/api/v3/dashboard/${userId}/hidden?limit=${limit}`;
  if (offset) {
    url += `&offset=${offset}`;
  }
  return ajax
    .getJSON<MyWorkspacesV3Response>(url, {
      Authorization: `Bearer ${token}`,
    })
    .pipe(
      mergeMap((response) => {
        assertMyWorkspaceV3Response(response);
        const { workspaces, offset } = stripInvalidWorkspaces(response);
        const activeWorkspaces = workspaces?.filter(
          (ws) => ws.workspaceType !== "DELETED"
        );

        if (!workspaces || !activeWorkspaces || activeWorkspaces.length === 0) {
          return of({
            details: EmptyWorkspaceDetailsArray,
            cursorOrOffset: null,
          });
        } else {
          return of({
            details: metadataV3toWorkspaceDetailsArray(activeWorkspaces),
            cursorOrOffset: offset,
          });
        }
      })
    );
}

/** Remove a workspace from the users dashboard permanently (forget)**/
function removeFromMyWorkspaces(
  origin: string,
  token: string,
  userId: string,
  workspaceId: string
) {
  const url = `${origin}/api/v3/dashboard/${userId}/${workspaceId}`;
  return ajax.delete(url, { Authorization: `Bearer ${token}` });
}

function setWorkspaceVisibility(
  origin: string,
  token: string,
  userId: string,
  workspaceId: string,
  isHidden: boolean
) {
  const url = `${origin}/api/v3/dashboard/${userId}/${workspaceId}/visibility?isHidden=${isHidden}`;
  return ajax.patch(url, undefined, { Authorization: `Bearer ${token}` });
}

/** Projects **/
function getProjectWorkspaces(
  origin: string,
  token: string,
  projectId: string
): Observable<MyWorkspaceMetadataV3[]> {
  let url = `${origin}/api/v3/projects/${projectId}/workspaces`;

  return ajax.getJSON<MyWorkspaceMetadataV3[]>(url, {
    Authorization: `Bearer ${token}`,
  });
}

/** WORKSPACE ENDPOINTS **/
/**
 * Gets the raw workspace metdata in the v3 document metadata format.
 * Note: This does not prepare the workspace for opening, since no password is/can be provided here.
 */
function getRawWorkspaceMetadata(
  origin: string,
  token: string,
  workspaceId: string
): Observable<MyWorkspaceMetadataV3> {
  const url = `${origin}/api/v3/workspace/${workspaceId}`;
  return ajax.getJSON<MyWorkspaceMetadataV3>(url, {
    Authorization: `Bearer ${token}`,
  });
}

function createWorkspace(
  origin: string,
  token: string,
  workspaceDetails: CreateWorkspaceRequestMetadata
): Observable<MyWorkspaceMetadataV3> {
  const url = `${origin}/api/v3/workspace`;

  return postJSON<CreateWorkspaceRequestMetadata, MyWorkspaceMetadataV3>(
    url,
    workspaceDetails,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

function duplicateWorkspace(
  origin: string,
  token: string,
  sourceWorkspaceId: string,
  sourcePassword: string | undefined,
  body: DuplicateWorkspaceRequestMetadata
): Observable<MyWorkspaceMetadataV3> {
  const url = `${origin}/api/v3/workspace/${sourceWorkspaceId}/duplicate`;

  return postJSON<DuplicateWorkspaceRequestMetadata, MyWorkspaceMetadataV3>(
    url,
    body,
    {
      Authorization: `Bearer ${token}`,
      ...(sourcePassword && {
        "X-Hoylu-Workspace-Password": sourcePassword,
      }),
    }
  );
}

function upsertWorkspaceUserPermissionsOfSingleUser(
  origin: string,
  token: string,
  workspaceId: string,
  user: { userId?: string; userEmail?: string }, // either userId or userEmail must be provided
  permission: UpsertWorkspaceUsersPermissions
) {
  let url = `${origin}/api/v3/workspace/${workspaceId}/roles?role=${permission}`;
  if (user.userId) {
    url += `&userId=${user.userId}`;
  } else if (user.userEmail) {
    url += `&userEmail=${user.userEmail}`;
  } else {
    throw new Error("Either userId or userEmail must be provided");
  }
  return patchJSON(url, undefined, { Authorization: `Bearer ${token}` }, true);
}

function upsertWorkspaceUserPermissions(
  origin: string,
  token: string,
  workspaceId: string,
  userEmails: string[],
  permission: UpsertWorkspaceUsersPermissions
): Observable<WorkspaceDetails> {
  // Note: If we would have a bulk endpoint for this we could simplify this a lot (replace concatMap, toArray, switchMap with a single request)
  return from(userEmails).pipe(
    // concatMap to make sure requests are executed in order
    concatMap((userEmail) =>
      upsertWorkspaceUserPermissionsOfSingleUser(
        origin,
        token,
        workspaceId,
        { userEmail },
        permission
      )
    ),
    // collect all responses into an array (and most important a single observable)
    toArray(),
    switchMap(() => {
      // TODO: This could be made more efficient if the PUT request returned the updated workspace permissions
      return getRawWorkspaceMetadata(origin, token, workspaceId);
    }),
    mergeMap((metadata) =>
      getWorkspaceRoles(origin, token, metadata.workspaceId).pipe(
        map((roles) =>
          metadataV3toWorkspaceDetails(metadata, roles)
        )
      )
    )
  );
}

function removeUsersFromWorkspacePermissions(
  origin: string,
  token: string,
  workspaceId: string,
  userEmails: string[]
): Observable<WorkspaceDetails> {
  // use forkJoin to make sure all requests are awaited and merged into a single observable
  // Note: If we would have a bulk endpoint for this we could simplify this a lot (replace concatMap, toArray, switchMap with a single request)
  return from(userEmails).pipe(
    concatMap((userEmail) =>
      removeUserFromWorkspacePermissionsOfSingleUser(
        origin,
        token,
        workspaceId,
        { userEmail }
      )
    ),
    toArray(),
    switchMap((_) => {
      // TODO: This could be made more efficient if the PUT request returned the updated workspace permissions
      return getRawWorkspaceMetadata(origin, token, workspaceId);
    }),
    mergeMap((metadata) =>
      getWorkspaceRoles(origin, token, metadata.workspaceId).pipe(
        map((roles) =>
          metadataV3toWorkspaceDetails(metadata, roles)
        )
      )
    )
  );
}

/** Remove workspace permanently **/
function removeUserFromWorkspacePermissionsOfSingleUser(
  origin: string,
  token: string,
  workspaceId: string,
  user: { userId?: string; userEmail?: string } // either userId or userEmail must be provided
) {
  let url = `${origin}/api/v3/workspace/${workspaceId}/roles?`;
  if (user.userId) {
    url += `userId=${user.userId}`;
  } else if (user.userEmail) {
    url += `userEmail=${user.userEmail}`;
  } else {
    throw new Error("Either userId or userEmail must be provided");
  }
  return ajax.delete(url, { Authorization: `Bearer ${token}` });
}

// TODO: Not in use yet
function getUserWorkspacePermissions(
  origin: string,
  token: string,
  workspaceId: string
): Observable<MyWorkspacePermissions> {
  const url = `${origin}/api/v3/workspace/${workspaceId}/users/my/permissions`;

  return ajax.getJSON<MyWorkspacePermissions>(url, {
    Authorization: `Bearer ${token}`,
  });
}

function getWorkspaceRoles(
  origin: string,
  token: string,
  workspaceId: string
): Observable<WorkspacePermissionsAndUsers> {
  const url = `${origin}/api/v3/workspace/${workspaceId}/roles`;

  return ajax
    .getJSON<WorkspaceRoles>(url, {
      Authorization: `Bearer ${token}`,
    })
    .pipe(
      map(
        (r): WorkspacePermissionsAndUsers => ({
          administrators: r.administrators.map((u) => u.email),
          writers: r.writers.map((u) => u.email),
          readers: r.readers.map((u) => u.email),
          unspecifiedUsers: r.unspecifiedUsers.map((u) => u.email),
          globalAccess: r.globalAccess,
        })
      )
    );
}

/** TONNAGE ENDPOINTS **/
function getMyWorkspacesTonnage(
  origin: string,
  token: string
): Observable<UserWorkspaceTonnageResponse> {
  const url = `${origin}/api/v3/tonnage/users/my`;

  return ajax.getJSON<UserWorkspaceTonnageResponse>(url, {
    Authorization: `Bearer ${token}`,
  });
}

function getUserWorkspacesTonnageById(
  origin: string,
  token: string,
  userId: string
): Observable<UserWorkspaceTonnageResponse> {
  const url = `${origin}/api/v3/tonnage/users/${userId}`;

  return ajax.getJSON<UserWorkspaceTonnageResponse>(url, {
    Authorization: `Bearer ${token}`,
  });
}

function recalculateUserTonnage(
  origin: string,
  token: string,
  userId: string,
  force?: boolean
): Observable<UserWorkspaceTonnageResponse> {
  let url = `${origin}/api/v3/tonnage/users/${userId}/recalculate`;

  if (force) {
    url += `?force=${force}`;
  }

  return putJSON<undefined, UserWorkspaceTonnageResponse>(
    url,
    undefined,
    { Authorization: `Bearer ${token}` },
    true
  );
}

export const documentIdv3Requests = {
  getMyWorkspacesPaged,
  getMyHiddenWorkspacesPaged,
  removeFromMyWorkspaces,
  setWorkspaceVisibility,
  getProjectWorkspaces,
  getRawWorkspaceMetadata,
  createWorkspace,
  duplicateWorkspace,
  upsertWorkspaceUserPermissions,
  removeUsersFromWorkspacePermissions,
  getUserWorkspacePermissions,
  getWorkspaceRoles,
  getMyWorkspacesTonnage,
  getUserWorkspacesTonnageById,
  recalculateUserTonnage,
};

// DocumentId service v3 is still missing the following endpoint:
// - delete workspace
// - set workspace password
// - remove workspace password
// - set workspace name
// - update workspace labels (v2)
// - change workspace global access permission (v1)
