import React from "react";

export const HorizontalDivider = () => {
  return (
    <div
      className={"my-half"}
      style={{
        width: "100%",
        height: "1px",
        backgroundColor: "var(--theme-user-profile-dropdown-item-text)",
        opacity: 0.2,
      }}
    />
  );
};
