import React, { Dispatch, RefObject, SetStateAction, useMemo } from "react";
import { hiconToHoyluUiIconsCentered } from "../../../utils/icon.map";
import { FrameContexts } from "@microsoft/teams-js";
import { Project, WorkspaceDetails } from "../../../state/workspaces/types";
import { Localized } from "../../../strings";
import { ConfigState } from "../../../state/config/config.reducer";
import { ProjectStatuses } from "../../../state/workspaces/workspaces.reducer";
import { hexToRgba } from "../../../utils/hexToRgba";
import {DateTime} from "luxon"

type UpdatedWorkspaceNameParams = {
  workspaceId: string;
  name: string;
}

type WorkspaceCardThumbnailProps = {
  config: ConfigState;
  isEditingWorkspaceName: boolean;
  isLimited: boolean;
  isOptionsMenuOpen: boolean;
  openMoreOptions: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  outsideElementRef: RefObject<HTMLDivElement>;
  project: Project | undefined;
  showWorkspaceModifiedNotification: boolean;
  toggleEditingName: Dispatch<SetStateAction<boolean>>;
  updateWorkspaceName: ({ workspaceId, name } : UpdatedWorkspaceNameParams) => void;
  workspaceDetails: WorkspaceDetails;
}

const WorkspaceCardThumbnail = ({
  config,
  isEditingWorkspaceName,
  isLimited,
  isOptionsMenuOpen,
  openMoreOptions,
  outsideElementRef,
  project,
  showWorkspaceModifiedNotification,
  toggleEditingName,
  updateWorkspaceName,
  workspaceDetails
}: WorkspaceCardThumbnailProps) => {
  const strings = Localized.object("WORKSPACE_CARD");
  const isTemplate = !!workspaceDetails.templateId;
  const isWorkspaceHidden = workspaceDetails.isHidden;
  const workspaceName = workspaceDetails.workspaceName || strings.UNTITLED;

  const editName = (value: string) => {
    if (value !== workspaceDetails.workspaceName) {
      updateWorkspaceName({
        workspaceId: workspaceDetails.workspaceId,
        name: value,
      });
    }
  };

  let lastAccessed = workspaceDetails.lastAccess
    ? DateTime.fromJSDate(workspaceDetails.lastAccess).toLocaleString(
        DateTime.DATE_MED
      )
    : strings.NOT_YET;

  // WorkspaceCard title and footer styles
  const [displayedWorkspaceName, workspaceThumbnailFooterBg, workspaceSubtitle, color] = useMemo(
    () => {
      // ws under credit limit
      let name = workspaceName;
      let backGround = "bg-theme-thumbnails-footer";
      let subtitle = `${strings.ACCESSED} ${lastAccessed}`;
      let color = 'unset';

      if (project?.status === ProjectStatuses.Inactive) {  // rules only for ws assigned to the inactive project
        name = `${Localized.string("FILTER_BY_NAME.INACTIVE")}: ${workspaceName}`;
        backGround = "bg-dark-blue-gray bg-opacity-75";
      } else if (isLimited) {   // rules only for ws (exclude project ws) when tonnage is exceeded
        name = `${strings.LIMITED_TITLE.toUpperCase()}: ${workspaceName}`;
        backGround = "bg-light-red";
      } else if (isWorkspaceHidden) {
        subtitle = strings.HIDDEN_WORKSPACE;
        color = hexToRgba("#17171D", 0.5);
      }

      return [name, backGround, subtitle, color];
    },
    [project?.status, workspaceDetails.workspaceName, workspaceDetails.myPermissions?.canGrow, isWorkspaceHidden]
  );

  return (
    <div
      style={{ color }}
      className={`flex justify-between pl-3 pr-2 ${
        isTemplate
          ? "bg-theme-templates-footer text-primary-text"
          : workspaceThumbnailFooterBg
      }`}
    >
      <div className="flex items-center leading-tight py-2 w-4/5">
        <div className="flex flex-col w-full">
          <div className="flex items-center">
            {isEditingWorkspaceName ? (
              <textarea
                className={`textarea ${
                  isTemplate
                    ? "bg-theme-templates-footer text-primary-text"
                    : "bg-theme-thumbnails-footer text-theme-primary-text"
                } border-b-2 border-accent text-base h-6 overflow-hidden w-full mr-4 resize-none outline-none whitespace-no-wrap`}
                autoFocus
                defaultValue={workspaceDetails.workspaceName}
                // putting cursor to the end of input box
                onFocus={(e) => {
                  let temp = e.target.value;
                  e.target.value = "";
                  e.target.value = temp;
                }}
                onBlur={(e) => {
                  editName(e.currentTarget.value);
                  toggleEditingName(false);
                }}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    editName(e.currentTarget.value);
                    toggleEditingName(false);
                  }
                }}
              ></textarea>
            ) : (
              <>
                {isWorkspaceHidden && (
                  <div
                    style={{ color }}
                    className={"hoylu-ui-icons-indicator-hide text-2xl pr-2"}
                  />
                )}
                {project && (
                  <div
                    style={{ color: project.color }}
                    className={`${hiconToHoyluUiIconsCentered(
                      project.icon
                    )} text-2xl pr-2`}
                  />
                )}
                <h1 className="py-1 whitespace-no-wrap truncate xxs:text-xs xs:text-sm sm:text-base">
                  {displayedWorkspaceName}
                </h1>
              </>
            )}
          </div>
          <div className="hidden lg:contents text-xs ppb-2">
            {isTemplate ? (
              <span>{strings.TEMPLATE}</span>
            ) : (
              <div className="flex">
                <p className="w-fit" title={workspaceSubtitle}>
                  {workspaceSubtitle}
                </p>
                {showWorkspaceModifiedNotification && (
                  <div className="bg-attention rounded-full w-2 h-2 ml-half" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex py-2 items-end">
        <div ref={outsideElementRef}>
          <button
            className="flex flex-row-reverse items-center p-2 text-accent"
            aria-label={workspaceDetails.workspaceName}
            title={strings.MORE_OPTIONS}
            onClick={openMoreOptions}
            data-test-id="workspace-options"
            disabled={config.teamsFrame === FrameContexts.sidePanel}
          >
            <svg
              fill={isOptionsMenuOpen ? "#AECBFA" : "#716C83"}
              width="18px"
              height="6px"
              viewBox="0 0 22 6"
            >
              <path
                d="M5.24,4.248 C5.24,4.952 4.664,5.496 3.992,5.496 L1.464,5.496 C0.76,5.496 0.216,4.92 0.216,4.248 L0.216,1.72 C0.216,1.016 0.792,0.472 1.464,0.472 L3.96,0.472 C4.664,0.472 5.208,1.048 5.208,1.72 L5.208,4.248 L5.24,4.248 Z"
                id="Path"
              ></path>
              <path
                d="M13.528,4.248 C13.528,4.952 12.952,5.496 12.28,5.496 L9.752,5.496 C9.048,5.496 8.504,4.92 8.504,4.248 L8.504,1.72 C8.504,1.016 9.08,0.472 9.752,0.472 L12.28,0.472 C12.984,0.472 13.528,1.048 13.528,1.72 L13.528,4.248 Z"
                id="Path"
              ></path>
              <path
                d="M21.816,4.248 C21.816,4.952 21.24,5.496 20.568,5.496 L18.04,5.496 C17.336,5.496 16.792,4.92 16.792,4.248 L16.792,1.72 C16.792,1.016 17.368,0.472 18.04,0.472 L20.568,0.472 C21.272,0.472 21.816,1.048 21.816,1.72 L21.816,4.248 Z"
                id="Path"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default WorkspaceCardThumbnail;