import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  activateDashboardOption,
  createWorkspaceFromTemplateId,
} from "../state/workspaces/workspaces.actions";
import { RootState } from "typesafe-actions";
import { geSingleRowUseCaseTileCount } from "./layouts/UseCaseLayout";
import { moduleDefaultImage } from "../utils/defaultImages";
import { UseCaseCard } from "./cards/UseCaseCard";
import { DashboardOption } from "../state/workspaces/DashboardOption";
import { Localized } from "../strings";
import { fetchTemplates } from "../state/templates/templates.actions";
import { templatesSelectors} from "../state/templates";
import { defaultModule } from "../utils/defaultModule";
import { QUICK_ACCESS_CATEGORY_ID } from "../services/templates/types";
import { TemplateMetadata } from "../state/templates/types";
import { Button } from "@hoylu/client-common";
import SliderLayout from "./layouts/SliderLayout";

export const getScreenWidth = () => {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );
};

export const mapName = (templateName: string) => {
  const strings = Localized.object("CREATE_WORKSPACES_SECTION").MODULES;

  switch (templateName) {
    case defaultModule:
      return strings.BLANK_WORKSPACE;
    case "workspace-ideas":
      return strings.WORKSPACE_IDEAS;
    case "pullplanningv4":
      return strings.PULL_PLANNING;
    case "agile-planning":
      return strings.AGILE_PLANNING;
    case "simple-drawing":
      return strings.SIMPLE_DRAWING;
    case "value-proposition":
      return strings.VALUE_PROPOSITION_CANVAS;
    case "supply-chain":
      return strings.SUPPLY_CHAIN;
    case "pi-planning":
      return strings.PI_PLANNING;
    case "kanban":
      return strings.KANBAN;
    case "weekly-planner":
      return strings.WEEKLY_PLANNER;
    case "retrospective":
      return strings.RETROSPECTIVE;
    case "business-model":
      return strings.BUSINESS_MODEL_CANVAS;
    case "swot-analysis":
      return strings.SWOT_ANALYSIS;
    default:
      return templateName
  }
}

const CreateWorkspaceSection: React.FC<CreateWorkspaceSectionProps> = ({
  createFromTemplate,
  createUseCaseWorkspace,
  showWorkspaceTemplates,
  openAssetPanel,
  userName
}) => {
  const blankWorkspaceTemplate = { templateId: '', workspaceId: '', name: defaultModule };
  const reservedUseCaseCards = useMemo(
    () => (showWorkspaceTemplates ? 1 : 0),
    [showWorkspaceTemplates]
  );
  const quickAccessTemplates = useSelector(templatesSelectors.quickAccessTemplates);
  const allDashboardTemplates: TemplateMetadata[] = [
    blankWorkspaceTemplate,
    ...quickAccessTemplates
  ];

  const stringsNewSection = Localized.object("SECTIONS");
  const strings = Localized.object("CREATE_WORKSPACES_SECTION");
  const dispatch = useDispatch();
  const [expand, toggle] = useState(false);
  const [numberOfTilesShouldBeDisplayed, setNumberOfTiles] = useState(
    Math.max(
      geSingleRowUseCaseTileCount(getScreenWidth()) - reservedUseCaseCards,
      0
    )
  );

  const updateUseCaseTileCount = () => {
    return setNumberOfTiles(
      expand || getScreenWidth() < 1024
        ? allDashboardTemplates.length
        : Math.max(
            geSingleRowUseCaseTileCount(getScreenWidth()) -
              reservedUseCaseCards,
            0
          )
    );
  };

  const seeMoreBtnStyles = {
    fontWeight: "700",
    backgroundColor: "transparent",
    color: "var(--global--accent-deep--Color)",
    width: "fit-content",
    padding: 0,
    margin: 0
  }

  useEffect(() => {
    dispatch(fetchTemplates.request({ categoryId: QUICK_ACCESS_CATEGORY_ID }));
  }, [dispatch]);

  useEffect(updateUseCaseTileCount, [expand, showWorkspaceTemplates, quickAccessTemplates]);
  window.addEventListener("resize", updateUseCaseTileCount);

  return (
    <>
      <div className={"flex justify-between items-center w-full"}>
        <div className={"flex flex-col justify-start px-5 w-fit"}>
          <span className={"text-theme-title font-bold mb-1 whitespace-no-wrap"}>{Localized.string(
            "SECTIONS.START_FROM_TEMPLATE.TITLE",
            userName
          )}</span>
          <Button
            additionalStyles={seeMoreBtnStyles}
            onClick={openAssetPanel}
          >
            {stringsNewSection.START_FROM_TEMPLATE.BUTTON}
            {" "}&gt;
          </Button>
        </div>
        <SliderLayout>
          {allDashboardTemplates
            .map((t, i) => (
              <UseCaseCard
                key={`${t.name}-${i}`}
                imageURL={moduleDefaultImage(t.name)}
                useCaseName={mapName(t.name)}
                tooltip={strings.CREATE + " "}
                onClick={() => t.templateId && t.name != defaultModule
                  ? createFromTemplate({ templateId: t.templateId })
                  : createUseCaseWorkspace(defaultModule)
                }
                workspaceId={t.workspaceId}
                enabled={true}
                isDefaultModule={t.name === defaultModule}
              />
            ))}
        </SliderLayout>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  showWorkspaceTemplates: state.context.config.featureFlags.workspaceTemplates,
  userName: state.context.user.profile.firstName
});

const mapDispatchToProps = {
  createFromTemplate: createWorkspaceFromTemplateId.request,
  createUseCaseWorkspace: (useCaseName: string) =>
    activateDashboardOption({
      optionType: DashboardOption.CREATE,
      useCaseName: useCaseName,
  }),
  openAssetPanel: () => activateDashboardOption({
    optionType: DashboardOption.ASSET_PANEL
  })
};

type CreateWorkspaceSectionProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWorkspaceSection);
