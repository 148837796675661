import React, { useRef, useState } from "react";
import { Localized } from "../../../strings";
import {Button} from "../modalElements/Button";
import {sendEmail} from "../../../utils/sharing.helpers";

type CopyEmailLinkProps = {
  workspaceName?: string,
  shareLink: string
}

export const CopyEmailLink: React.FC<CopyEmailLinkProps> = ({ workspaceName, shareLink}) => {
  const strings = Localized.object("COPY_EMAIL_LINK");
  const [copyClicked, setCopyClicked] = useState(false);
  const shareLinkRef = useRef<HTMLSpanElement>(null);

  const copyLink = (): void => {
    setCopyClicked(true);
    if (navigator.clipboard) {
      navigator.clipboard.writeText(shareLink);
    } else {
      //For electron app
      if(shareLinkRef && shareLinkRef.current && window.getSelection){
        let selection = window.getSelection();
        selection!.removeAllRanges();
        let range = document.createRange();
        range.selectNodeContents(shareLinkRef.current);
        selection!.addRange(range);
        document.execCommand('copy');
      }
    }
  };

  const isSmall = window.innerWidth < 760;

  let copyText = isSmall ?
    (copyClicked ? strings.SMALL_COPIED_LINK : strings.SMALL_COPY_LINK) : 
    (copyClicked ? strings.COPIED_LINK : strings.COPY_LINK);
  let emailText = isSmall ? strings.SMALL_EMAIL : strings.EMAIL;

  return (
    <div className="flex justify-between items-center">
      {shareLink &&
        <div className={'truncate appearance-none focus:shadow-outline w-80'}>
          <span className={"text-sm"} ref={shareLinkRef}>{shareLink.replace('https://', '')}</span>
        </div>
      }
      <div className={`flex justify-end`}>
        <Button
          label={strings.COPY_WORKSPACE_LINK}
          onButtonClick={copyLink}
          additionalStyling={'btn-primary'}
        >
          {copyText}
        </Button>
        <Button
          label={strings.EMAIL_WORKSPACE_LINK}
          onButtonClick={() => sendEmail(workspaceName ?? "", shareLink)}
          additionalStyling={'btn-primary mr-0'}
        >
          {emailText}
        </Button>
      </div>
    </div>
  );
};