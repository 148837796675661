import React from 'react';
import {connect, useDispatch} from 'react-redux';
import { RootState } from 'typesafe-actions';
import { cancelDashboardOption } from '../../state/workspaces/workspaces.actions';
import { getSelectedWorkspaceDetails } from '../../state/workspaces/workspaces.selector';
import { Localized } from '../../strings';
import {Modal} from "./Modal";
import {CloseIcon} from "./modalElements/CloseIcon";
import {Button} from "@hoylu/client-common";
import * as workspaceActions from "../../state/workspaces/workspaces.actions";
import {DashboardOption} from "../../state/workspaces/DashboardOption";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state)
});

const mapDispatchToProps = {
  cancelDashboardOption,
};

type LinkedMasterDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const LinkedMasterDialog: React.FC<LinkedMasterDialogProps> = ({
  cancelDashboardOption,
  selectedWorkspaceDetails
}) => {
  const dispatch = useDispatch();
  const strings = Localized.object("LINKED_MASTER_DIALOG");

  const openChangeMasterDialog = () =>
    dispatch(workspaceActions.activateDashboardOption({
      optionType: DashboardOption.CHANGE_MASTER,
      workspaceId: selectedWorkspaceDetails?.workspaceId,
    }))

  return (
    <Modal
      handleOutsideClick={cancelDashboardOption}
      padding={"p-3"}
      allowContentToOverflow={true}
    >
      <div className="w-100 h-auto" data-test-id="linked-master-ws-dialog">
        <div className="w-full flex justify-between items-center font-lato mb-4">
          <div className="text-indicator font-bold text-base ">{strings.TITLE}</div>
          <CloseIcon onClose={cancelDashboardOption}/>
        </div>
        <span className={"text-white font-lato font-bold text-sm mb-2"}>{strings.SUBTITLE}</span>
        <div className={"w-full flex flex-col gap-3 p-2 text-sm"}>
          <div className={"w-full flex justify-between align-middle"}>
            <span>{strings.NAME_FIELD}</span>
            <span>Workspace Name</span>
          </div>
          <div className={"w-full flex justify-between align-middle"}>
            <span>{strings.ID_FIELD}</span>
            <span>Workspace ID</span>
          </div>
        </div>
        <div className={"flex mt-6 justify-end"}>
          <Button
            onClick={cancelDashboardOption}
            type="secondary"
            dataTestId={strings.CANCEL}
          >
            {strings.CANCEL}
          </Button>
          <Button
            type={"primary"}
            onClick={openChangeMasterDialog}
            dataTestId={"change-linked-master"}
          >
            {strings.CHANGE_MASTER}
          </Button>
          <Button
            type={"primary"}
            onClick={() => {}}
            dataTestId={"edit-content"}
          >
            {strings.EDIT_CONTENT}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkedMasterDialog);
