import React, {useState} from "react";
import { Localized } from "../../../strings";

type ConfigurationProps = {
    updateConfiguration: (configuration: KanbanConfiguration) => any
}

type KanbanConfiguration = {
    columns: number
}

export const KanbanConfiguration: React.FC<ConfigurationProps> = ( { updateConfiguration } ) => {
  const strings = Localized.object("KANBAN_CONFIGURATION");
  const minColumns = 3;
  const maxColumns = 6;

  let [configuration, setConfiguration] = useState<KanbanConfiguration>({columns: 3});
  updateConfiguration(configuration);

  const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfiguration({...configuration, [e.target.id]: parseInt(e.target.value)});
    updateConfiguration(configuration);
  };

  return(
    <div className="mt-4 ml-1">
      <label htmlFor="columns" className="pl-1">
        {strings.SET_COLUMNS}
      </label>
      <input
        type="number"
        id="columns"
        className="align-text-top input input-container w-2/12 float-right"
        onChange={e => handleUpdate(e)}
        value={configuration.columns}
        min={minColumns}
        max={maxColumns}
      />
    </div>
  );
};
