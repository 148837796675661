import React from 'react';
import {connect, useDispatch} from 'react-redux';
import { RootState } from 'typesafe-actions';
import {cancelDashboardOption, updateWorkspaceDetails} from '../../state/workspaces/workspaces.actions';
import { getSelectedWorkspaceDetails } from '../../state/workspaces/workspaces.selector';
import { Localized } from '../../strings';
import {Modal} from "./Modal";
import {CloseIcon} from "./modalElements/CloseIcon";
import {Button} from "@hoylu/client-common";
import {deleteTemplate} from "../../state/templates/templates.actions";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state)
});

const mapDispatchToProps = {
  cancelDashboardOption,
};

type RemoveTemplateDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const DeleteTemplateDialog: React.FC<RemoveTemplateDialogProps> = ({
  cancelDashboardOption,
  selectedWorkspaceDetails
}) => {
  const dispatch = useDispatch();
  const strings = Localized.object("DELETE_TEMPLATE_DIALOG");

  const removeTemplate = () => {
    if (!selectedWorkspaceDetails?.templateId) {
      return;
    }

    dispatch(deleteTemplate.request(selectedWorkspaceDetails.templateId));
    dispatch(
      updateWorkspaceDetails.success({
        ...selectedWorkspaceDetails,
        templateId: undefined,
      })
    );
    cancelDashboardOption();
  }

  return (
    <Modal
      handleOutsideClick={cancelDashboardOption}
      padding={"p-3"}
      allowContentToOverflow={true}
    >
      <div className="w-120 h-auto" data-test-id="linked-master-ws-dialog">
        <div className="w-full flex justify-between items-center font-lato mb-3">
          <div className="text-indicator font-bold text-base ">{strings.TITLE}</div>
          <CloseIcon onClose={cancelDashboardOption}/>
        </div>
        <div className={"flex w-full font-lato flex text-sm p-2"}>
          <span>{strings.SUBTITLE}</span>
        </div>
        <div className={"flex mt-4 justify-end"}>
          <Button
            onClick={cancelDashboardOption}
            type="secondary"
            dataTestId={strings.CANCEL}
          >
            {strings.CANCEL}
          </Button>
          <Button
            type={"primary"}
            onClick={removeTemplate}
            dataTestId={"delete-template"}
          >
            {strings.CONTINUE}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteTemplateDialog);
