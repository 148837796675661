import React, { useContext } from "react";
import { emailToHSL, Icon, Marble } from "@hoylu/client-common";
import { Localized } from "../strings";
import { ThemeContext } from "./ThemeContext";
import { THEME_LIGHT } from "../utils/color.scheme";
import { useSelector } from "react-redux";
import { getSelectedProject } from "../state/workspaces/projects.selector";

export type ProjectSectionProps = {
  onEdit: () => void;
  collaborators: string[];
  canEdit: boolean;
};

const ProjectSection: React.FC<ProjectSectionProps> = ({
  onEdit,
  collaborators,
  canEdit,
}) => {
  const strings = Localized.object("SECTIONS.PROJECT");
  const themeContext = useContext(ThemeContext);
  const selectedProject = useSelector(getSelectedProject);

  const copyLink = () => {
    const currentOrigin = new URL(window.location.href).origin;
    const projectId = selectedProject?.id;

    if (projectId && currentOrigin) {
      const deepLink = `${origin}/?project=${projectId}`;
      navigator.clipboard.writeText(deepLink);
    }
  };

  const generateMarble = (collaboratorEmail: string) => {
    const initial = collaboratorEmail[0];
    const projectMarbleStyles = {
      width: "3rem",
      minWidth: "3rem",
      height: "3rem",
      fontSize: "var(--global--size-h1)",
      backgroundColor: emailToHSL(collaboratorEmail),
      boxShadow:
        "0 10px 5px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    };

    return (
      <Marble
        key={`marble-${collaboratorEmail}`}
        title={collaboratorEmail}
        style={projectMarbleStyles}
      >
        {initial}
      </Marble>
    );
  };

  const editIconStyle = {
    color: "var(--theme-title)",
    fontSize: "var(--global--size-x-large)",
  };

  const collaboratorsScrollbarClass =
    themeContext.theme === THEME_LIGHT
      ? "theme-light-collaborators-scrollbar"
      : "theme-dark-collaborators-scrollbar";

  return (
    <div
      className={
        "bg-theme-section-create-workspace flex justify-center items-center w-full px-5 lg:pl-28 lg:pr-28 mt-6"
      }
    >
      <div
        className={`flex px-1 pb-3 overflow-x-auto ${collaboratorsScrollbarClass}`}
        tabIndex={0}
        aria-label={strings.COLLABORATORS}
      >
        {collaborators.map(generateMarble)}
      </div>
      <div title={strings.COPY_LINK} tabIndex={0}>
        <Icon
          icon={"hoylu-ui-icons-link"}
          style={editIconStyle}
          hoverStyle={editIconStyle}
          onClick={copyLink}
        />
      </div>
      {canEdit && (
        <div title={strings.PROJECT_SETTINGS} tabIndex={0}>
          <Icon
            icon={"hoylu-ui-icons-setting"}
            style={editIconStyle}
            hoverStyle={editIconStyle}
            onClick={onEdit}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectSection;
