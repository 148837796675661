import React, { FormEvent, useState } from "react";
import { Label, WorkspaceLabels } from "../../state/workspaces/types";
import { Localized } from "../../strings";
import { TileTag } from "./editLabel/TileTag";
import PlusIcon from "./../../assets/images/icons/plus.svg";
import RemoveIcon from "./../../assets/images/icons/close.svg";
import { Checkbox, Divider } from "@hoylu/client-common";
import { loadHiddenWorkspaces, toggleHiddenWorkspaces } from "../../state/workspaces/workspaces.actions";
import { maximumDashboardPageSize } from "../../utils/defaultDashboardPageSize";
import { useDispatch, useSelector } from "react-redux";
import { isHiddenSectionVisible } from "../../state/workspaces/workspaces.selector";
import { enableDocumentIdNewV3Api } from "../../state/config/config.selector";

export type EditLabelContentProps = {
  allLabels: WorkspaceLabels;
  selected: WorkspaceLabels | undefined;
  updateSelected: (selected: WorkspaceLabels) => void;
  canAddNewLabels?: boolean;
  subscribeLabelsChanges?: boolean;
  enableHiddenWorkspaceSearch?: boolean;
};

type Tag = {
  checked: boolean;
  name: string;
};

export const EditLabelContent: React.FC<EditLabelContentProps> = ({
  allLabels,
  selected,
  updateSelected,
  canAddNewLabels = true,
  subscribeLabelsChanges = false,
  enableHiddenWorkspaceSearch = false,
}) => {
  const dispatch = useDispatch();
  const strings = Localized.object("EDIT_LABEL_DIALOG");
  const currentWorkspaceLabels =
    selected?.map((label: Label) => ({
      checked: true,
      name: label.name,
    })) || [];
  const isChecked = useSelector(isHiddenSectionVisible);
  const isV3ApiEnabled = useSelector(enableDocumentIdNewV3Api);
  const [labelField, setLabelField] = useState("");
  const [tags, setTags] = useState(
    currentWorkspaceLabels
      .concat(
        allLabels.map((label: Label) => ({
          checked: false,
          name: label.name,
        })) || []
      )
      .filter(
        (tag: Tag, index, self) =>
          self.map((val: Tag) => val.name).indexOf(tag.name) === index
      )
  );
  if (subscribeLabelsChanges) {
    // todo Workaround?
    const selectedLabelsOneDimension = currentWorkspaceLabels.map(
      (label: Label) => label.name
    );
    const updatedTags = tags.map((label: Label) => ({
      name: label.name,
      checked: selectedLabelsOneDimension.includes(label.name),
    }));
    if (JSON.stringify(updatedTags) !== JSON.stringify(tags)) {
      setTags(updatedTags);
    }
  }

  const searchTagIndex = (str: string) => {
    return tags.findIndex(
      (tag: Tag) =>
        tag.name.trim().toLocaleLowerCase() === str.trim().toLocaleLowerCase()
    );
  };

  const searchTag = (str: string) => {
    return tags.find(
      (tag: Tag) =>
        tag.name.trim().toLocaleLowerCase() === str.trim().toLocaleLowerCase()
    );
  };

  const filterTags = (str: string) => {
    return tags.filter(
      (tag: Tag) =>
        tag.name
          .trim()
          .toLocaleLowerCase()
          .indexOf(str.trim().toLocaleLowerCase()) !== -1
    );
  };

  const requestUpdateLabels = (tags: Tag[]) => {
    updateSelected(
      tags
        .filter((tag: Tag) => tag.checked)
        .map((tag: Tag) => ({ name: tag.name }))
    );
  };

  const submitAction = (e: FormEvent) => {
    e.preventDefault();
    const value = labelField.trim();
    if (value === "") return;
    const existTagIndex = searchTagIndex(value);
    let newTags = [...tags];
    if (existTagIndex !== -1) {
      newTags[existTagIndex] = {
        ...newTags[existTagIndex],
        checked: !newTags[existTagIndex].checked,
      };
    } else if (canAddNewLabels) {
      newTags = tags.concat([{ checked: true, name: labelField }]);
    }
    setTags(newTags);
    requestUpdateLabels(newTags);
    setLabelField("");
  };

  const onToggleTag = (text: string, checked: boolean) => {
    const newTags = tags.map((tag: Tag) => ({
      name: tag.name,
      checked: tag.name === text ? checked : tag.checked,
    }));
    setTags(newTags);
    requestUpdateLabels(newTags);
  };

  const showHiddenWorkspaces = () => {
    /*
    If API version 3 is enabled, we have to fetch hidden workspaces with a dedicated endpoint.
    API version 1 is returning hidden workspaces together with all user's workspaces during populateAll action.
    */
    if (isV3ApiEnabled && !isChecked) {
      dispatch(loadHiddenWorkspaces.request({ limit: maximumDashboardPageSize}));
    }
    dispatch(toggleHiddenWorkspaces());
  }

  const foundTag = searchTag(labelField);
  const filteredTags = filterTags(labelField);
  const hasCheckedTag = foundTag && foundTag.checked;

  return (
    <form autoComplete="off" onSubmit={submitAction}>
      <div
        className="flex items-center justify-between bg-dark-selected rounded mb-4"
        data-test-id="workspace-labels-editor"
      >
        <input
          onKeyDown={(e) => {
            if (e.key === "Escape") setLabelField("");
          }}
          className="input text-base text-indicator placeholder-indicator h-8 rounded"
          placeholder={strings.ENTER_LABEL}
          type="text"
          autoFocus
          name="label"
          onChange={(e) => setLabelField(e.target.value)}
          value={labelField}
        />
        <button className="mr-2" type="submit">
          {labelField.trim() !== "" && (foundTag || canAddNewLabels) && (
            <img
              className="w-4"
              alt={hasCheckedTag ? strings.REMOVE : strings.ADD}
              src={hasCheckedTag ? RemoveIcon : PlusIcon}
            />
          )}
        </button>
      </div>
      <div className="flex content-start flex-wrap max-h-60vh overflow-y-auto overscroll-none w-full mb-5">
        {filteredTags.map((tag: Tag) => (
          <TileTag
            key={tag.name}
            text={tag.name}
            checked={tag.checked}
            onToggle={onToggleTag}
          />
        ))}
      </div>
      {enableHiddenWorkspaceSearch && (
        <>
          <Divider horizontal={true} width={"80%"} />
          <div className="flex items-center mt-2" onClick={showHiddenWorkspaces}>
            {/*TODO: Fix Checkbox usage (connected label already exists in cc checkbox) */}
            <Checkbox
              checked={isChecked}
              style={{ display: "flex" }} // Overwriting vertical-align: middle from cc .customCheckbox
            />
            <label className="ml-3 text-primary-text" htmlFor="checkbox">
              {Localized.string("FILTER_BY_WORKSPACE_LABEL.SHOW_HIDDEN_WORKSPACES")}
            </label>
          </div>
        </>
      )}
    </form>
  );
};
