import React from 'react';
import leftPathIcon from '../../assets/images/left-path.svg';
import rightPathIcon from "../../assets/images/right-path.svg";
import darkLogoOnly from "../../assets/images/logos/logo-only-theme-dark.svg";
import { Localized } from "../../strings";

export const CenteredContentWithLogo: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const strings = Localized.object("CENTERED_CONTENT_WITH_LOGO");
  return (
    <div>
      <img
        className="hidden lg:block absolute pointer-events-none"
        src={leftPathIcon}
        alt={strings.LEFT_PATH_ICON}
      />
      <div className="h-screen bg-dark-primary flex items-center justify-center">
        <div className="block z-10">
          <img
            className="w-16 h-sm:w-24 m-auto"
            src={darkLogoOnly}
            alt={strings.HOYLU_LOGO}
          />
          {children}
        </div>
        <img
          className="hidden lg:block absolute right-0 w-1/3 pr-6 pointer-events-none"
          src={rightPathIcon}
          alt={strings.RIGHT_PATH_ICON}
        />
      </div>
    </div>
  );
};
