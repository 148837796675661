import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import {
  cancelDashboardOption,
  populateAllWorkspaces,
  setSearchTerm,
} from "../../state/workspaces/workspaces.actions";
import { Localized } from "../../strings";
import { Modal } from "./Modal";
import { CloseIcon } from "./modalElements/CloseIcon";
import SearchIconGray from "../../assets/images/icons/search-icon.svg";
import { Button } from "@hoylu/client-common";

const mapStateToProps = (state: RootState) => ({
  hasPopulatedAllWorkspaces: state.context.workspaces.hasPopulatedAllWorkspaces,
  isLoadingAll: state.context.workspaces.isLoadingAll,
  searchTerm: state.context.workspaces.searchTerm
});

const mapDispatchToProps = {
  cancelDashboardOption,
  setSearchingPhrase: (value: string) => setSearchTerm(value)
};

type SearchWorkspaceDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const SearchWorkspaceDialog: React.FC<SearchWorkspaceDialogProps> = ({
  cancelDashboardOption,
  hasPopulatedAllWorkspaces,
  isLoadingAll,
  setSearchingPhrase,
  searchTerm
}) => {
  const dispatch = useDispatch()
  const strings = Localized.object("SEARCH_WORKSPACE_DIALOG");

  const [term, setTerm] = useState(searchTerm);

  const handleSearch = () => {
    setSearchingPhrase(term);
    cancelDashboardOption();
  }

  return (
    <Modal padding="p-3" handleOutsideClick={cancelDashboardOption}>
      <div className="flex flex-col font-lato xxs:w-full sm:w-68 sm:max-w-68">
        <div className="flex pb-3">
          <h1 className="text-base font-bold leading-8 truncate text-indicator">
            {strings.TITLE}
          </h1>
          <CloseIcon onClose={cancelDashboardOption} />
        </div>
        <div
          className="font-lato items-center flex text-base relative bg-primary rounded sm:ml-0 w-full"
          onMouseOver={() => {
            if (!hasPopulatedAllWorkspaces) {
              dispatch(populateAllWorkspaces.request());
            }
          }}
        >
          <img
            className="absolute left-3 w-4 text-primary-text"
            src={SearchIconGray}
            alt={strings.MAGNIFYING_GLASS}
          />
          <input
            data-test-id="search-workspace"
            className={`input text-base bg-dark-selected text-indicator placeholder-indicator flex pl-8 mr-0 rounded h-10 w-full`}
            type="text"
            value={term}
            autoFocus
            onChange={(e: any) => setTerm(e.target.value)}
            placeholder={isLoadingAll ? strings.LOADING_WORKSPACES : strings.SEARCH}
            disabled={isLoadingAll}
          />
        </div>
        <div className={"flex justify-end mt-3"}>
          <Button
            onClick={cancelDashboardOption}
            type="secondary"
            dataTestId={strings.CANCEL}
          >
            {strings.CANCEL}
          </Button>
          <Button
            type={"primary"}
            onClick={handleSearch}
            dataTestId={"set-searching-workspace-term"}
          >
            {strings.SEARCH}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchWorkspaceDialog);
