import React, { FormEvent, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import {
  documentMetadata,
  transferService,
} from "../../../state/config/config.selector";
import { authorizationHeader } from "../../../state/user/user.selector";
import { cancelDashboardOption } from "../../../state/workspaces/workspaces.actions";
import { getSelectedWorkspaceDetails } from "../../../state/workspaces/workspaces.selector";
import { Localized } from "../../../strings";
import { Modal } from "../Modal";

function browserSaveFile(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
}

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
  transferServiceUrl: transferService(state),
  documentMetadataUrl: documentMetadata(state),
  authHeader: authorizationHeader(state),
  fetchApi: window.fetch,
  saveFile: browserSaveFile,
});

const mapDispatchToProps = {
  cancelDashboardOption,
};

export type DownloadDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const DownloadDialog: React.FC<DownloadDialogProps> = ({
  cancelDashboardOption,
  selectedWorkspaceDetails,
  transferServiceUrl,
  documentMetadataUrl,
  authHeader,
  fetchApi,
  saveFile,
}) => {
  const strings = Localized.object("DOWNLOAD_DIALOG");

  const isPasswordRequired = selectedWorkspaceDetails?.hasPassword;

  const [disabled, setDisabled] = useState(isPasswordRequired);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [message, setMessage] = useState("");
  const abortControllerRef = useRef<AbortController | null>(null);
  const isActive = useRef(true);
  useEffect(() => {
    return () => {
      isActive.current = false;
      abortControllerRef.current?.abort();
    };
  }, []);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const encoded = Buffer.from(password, "binary").toString("base64");
    const url = `${documentMetadataUrl}/api/v1/${
      selectedWorkspaceDetails!.workspaceId
    }?schemaVersion=3`;
    const headers = {
      ...authHeader,
      "X-Hoylu-Document-Password": encoded,
    };
    try {
      const response = await fetchApi(url, {
        method: "GET",
        mode: "cors",
        headers,
      });
      if (response.ok) {
        setDisabled(false);
      } else {
        setIsPasswordInvalid(true);
        console.warn(response);
      }
    } catch (error) {
      setIsPasswordInvalid(true);
      console.warn(error);
    }
  };

  // future improvements
  // https://stackoverflow.com/a/72914817
  //
  // use https://developer.mozilla.org/en-US/docs/Web/API/Window/showSaveFilePicker
  // and https://developer.mozilla.org/en-US/docs/Web/API/FileSystemWritableFileStream
  // or
  // https://github.com/jimmywarting/native-file-system-adapter

  // possible workaround https://stackoverflow.com/a/72576242
  const download = async () => {
    abortControllerRef.current = new AbortController();
    setDisabled(true);
    setMessage(strings.DOWNLOADING);
    const url = `${transferServiceUrl}/Download/${
      selectedWorkspaceDetails!.workspaceId
    }`;
    try {
      const response = await fetchApi(url, {
        method: "GET",
        mode: "cors",
        headers: authHeader,
        signal: abortControllerRef.current.signal,
      });
      if (response.ok) {
        const filename =
          response.headers
            .get("content-disposition")
            ?.split(";")
            .find((n) => n.includes("filename="))
            ?.replace("filename=", "")
            .trim()
            .replace(/^"(.*)"$/, "$1") ||
          `${selectedWorkspaceDetails!.workspaceId}.zip`;
        const blob = await response.blob();
        saveFile(blob, filename);
        cancelDashboardOption();
      } else {
        if (isActive.current) {
          setMessage(strings.DOWNLOAD_FAILED);
          setDisabled(false);
        }
        abortControllerRef.current = null;
        console.warn(response);
      }
    } catch (error) {
      if (isActive.current) {
        setMessage(strings.DOWNLOAD_FAILED);
        setDisabled(false);
      }
      abortControllerRef.current = null;
      console.warn(error);
    }
  };

  const style = isPasswordInvalid ? " border-solid border border-red-600" : "";

  if (!selectedWorkspaceDetails) return null;
  return (
    <Modal padding="p-3" handleOutsideClick={cancelDashboardOption}>
      <div className="relative">
        <h1 className="text-2xl leading-none">{strings.DOWNLOAD}</h1>

        {isPasswordRequired && (
          <form className="mt-4 sm:w-80" role="form" onSubmit={onSubmit}>
            <div className="mt-2">
              <div className={"input-container" + style}>
                <label className="input-label" htmlFor="password">
                  {strings.PASSWORD}
                  <div className="flex justify-between">
                    <input
                      id={"password"}
                      className="input"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      autoFocus
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setIsPasswordInvalid(false);
                      }}
                    />
                    <div
                      className={`${
                        showPassword
                          ? "hoylu-ui-icons-indicator-hide"
                          : "hoylu-ui-icons-indicator-show"
                      } text-indicator text-2xl mr-2 -my-1`}
                      onClick={() => setShowPassword(!showPassword)}
                      title={strings.SHOW_PASSWORD}
                    />
                  </div>
                </label>
              </div>
            </div>
          </form>
        )}

        {message}
        <footer className="flex justify-end w-full xxs:text-sm xs:text-base">
          <button
            type="button"
            className="btn btn-negative"
            onClick={cancelDashboardOption}
            title={strings.CANCEL}
          >
            {strings.CANCEL}
          </button>
          <button
            onClick={download}
            className="btn btn-primary"
            disabled={disabled}
            title={strings.DOWNLOAD}
          >
            {strings.DOWNLOAD}
          </button>
        </footer>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDialog);
