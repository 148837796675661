import React from "react";
import { lockedIcon, unlockedIcon } from "../../../../assets/images/icons/sharingIcons";
import {PermissionIcon} from "./PermissionIcon";

export type LockIconProperties = {
  isAdmin: boolean;
  hasPassword: boolean;
  isActive: boolean;
  toggleActive: (isActive: boolean) => void;
}

export const LockIcon: React.FC<LockIconProperties> = ({
  isAdmin,
  hasPassword,
  isActive,
  toggleActive
}) => {

  const unlocked = unlockedIcon;
  const locked = lockedIcon;
  const currentIcon = hasPassword ? locked : unlocked;

  return (
    <div>
      {isAdmin && <PermissionIcon icon={currentIcon()} isActive={isActive} toggleActive={toggleActive}/>}
    </div>
  );
}