import React, { useEffect, useRef, useState} from 'react';

enum SlideDirection {
  LEFT = 'LEFT',
  RIGHT = "RIGHT"
}

interface SliderProps {
  width?: string;
  children: React.ReactNode;
}

const Slider: React.FC<SliderProps> = ({ width = "w-full", children }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollDistance = 300;

  const navigationStyles = "absolute bg-theme-section top-0 items-center flex p-2 h-full focus:outline-none focus:shadow-outline select-none";
  const arrowStyles = "text-theme-title text-4xl font-bold cursor-pointer";
  const arrowShadow = { boxShadow: "-1rem 0 3rem 5rem var(--theme-section)" };

  const [ showLeftArrow, setLeftArrowVisibility ]= useState(false);
  const [ showRightArrow, setRightArrowVisibility ]= useState(true);
  const [ showSlider, setSliderVisibility ]= useState(false);

  const handleScroll = () => {
    const container = scrollContainerRef.current;

    if (container) {
      setLeftArrowVisibility(container.scrollLeft !== 0)
      setRightArrowVisibility(container.scrollLeft + container.clientWidth < container.scrollWidth)
    }
  };


  useEffect(() => {
    if (!scrollContainerRef.current || !scrollContainerRef.current.parentElement?.clientWidth) return;

    setSliderVisibility(scrollContainerRef.current.scrollWidth > scrollContainerRef.current?.parentElement?.clientWidth);
  }, [scrollContainerRef.current?.scrollWidth])

  const scroll = (command: SlideDirection) => {
    const direction = command === SlideDirection.LEFT ? -scrollDistance : scrollDistance;

    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: direction, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
    return undefined;
  }, []);

  return (
    <div className={`flex relative items-center ${width} overflow-hidden mx-auto md:pr-10 md:pl-2 lg:px-16 `}>
      <div className={"flex px-5 items-center w-fit overflow-x-hidden"} ref={scrollContainerRef}>
        {React.Children.map(children, (child, index) => (
          <div key={index} className={"px-2 pb-4"}>
            {child}
          </div>
        ))}
      </div>
      {showLeftArrow && (
        <div
          onClick={() => scroll(SlideDirection.LEFT)}
          className={`${navigationStyles} left-0`}
        >
          <span className={arrowStyles}>&lt;</span>
        </div>
      )}
      {showRightArrow && showSlider && (
        <div
          onClick={() => scroll(SlideDirection.RIGHT)}
          className={`${navigationStyles} right-0`}
          style={arrowShadow}
        >
          <span className={arrowStyles}>&gt;</span>
        </div>
      )}
    </div>
  );
};

export default Slider;
