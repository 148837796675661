import React, { CSSProperties } from "react";
import { Modal } from "../Modal";
import { CloseButton } from "../modalElements/CloseButton";

export type ErrorDialogWithImageProps = {
  imageUrl: string;
  title: string;
  text?: string;
  buttonText?: string;
  showButton?: boolean;
  showExternalLinkSymbol?: boolean;
  onButtonClick?: () => any;
  onCancel?: () => any;
  buttonCustomStyles?: CSSProperties;
};

export const ErrorDialogWithImage: React.FC<ErrorDialogWithImageProps> = ({
  onCancel,
  onButtonClick,
  title,
  text,
  buttonText,
  imageUrl,
  showButton = true,
  showExternalLinkSymbol = false,
  buttonCustomStyles
}) => {
  const closeStyle: string = "text-accent pb-4 w-4";

  return (
    <Modal handleOutsideClick={onCancel}>
      <div className="max-w-md">
        <div className="close-container">
          <CloseButton
            closeModal={() => onCancel && onCancel()}
            style={closeStyle}
          />
        </div>
        <div className="xxs:px-8 xxs:pb-8 xxs:pt-4 lg:p-8 flex flex-col justify-center items-center">
          <img
            className="xxs:w-1/2 lg:w-2/3"
            src={imageUrl}
            alt="headerGraphic"
          ></img>
          <h1 className="xxs:text-base lg:text-2xl xxs:mt-4 lg:mt-8 text-center xxs:leadinig-4 lg:leading-7 lg:px-24 lg:-mx-3">
            {title}
          </h1>
          {text &&
            <p className="xxs:text-sm lg:text-base xxs:mt-4 lg:mt-8 text-center">
              {text}
            </p>
          }
          {buttonText && showButton && (
            <button
              className="btn btn-primary xxs:mt-4 lg:mt-8 xxs:text-sm xs:text-base xxs:mx-0 flex items-center"
              style={buttonCustomStyles}
              onClick={() => onButtonClick && onButtonClick()}
            >
              {buttonText}{" "}
              {showExternalLinkSymbol && (
                <span className="hoylu-ui-icons-link-from-task pl-1" />
              )}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
