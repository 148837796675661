import React, { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { Localized } from "../../strings";

const mapStateToProps = (state: RootState) => ({
  workspaceId: state.context.workspaces.waitingToEditID,
  workspaceName: state.context.workspaces.waitingToEditName,
  progress: state.context.workspaces.loadProgress,
  step: state.context.workspaces.loadState,
});

type LoadingWorkspaceDialogProps = ReturnType<typeof mapStateToProps>;

function formatWorkspaceId(id: string | null) {
  return id ? id.match(/.{1,3}/g)?.join("-") : null;
}

export const LoadingWorkspaceDialog: React.FC<LoadingWorkspaceDialogProps> = ({
  workspaceId,
  workspaceName,
  progress = 0,
  step,
}) => {
  if (workspaceId == null) {
    workspaceId = window.location.pathname.substring(1);
  }
  const strings = Localized.object("LOADING_WORKSPACE_DIALOG");
  const [delayLoadingPercent, setDelayLoadingPercent] = useState(false);
  const [showLoadingPercent, setShowLoadingPercent] = useState(false);
  const [loadStage, setLoadStage] = useState(strings.LOADED);
  useEffect(() => {
    const timer = setTimeout(() => setDelayLoadingPercent(true), 8000);
    //Users seems to give up on page after 10s according to 06.2021 research :)
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setShowLoadingPercent(
      delayLoadingPercent && (!!Math.floor(progress) || !!step)
    );
  }, [delayLoadingPercent, progress, step]);

  useEffect(() => {
    switch (step) {
      case 0:
        setLoadStage(strings.LOADED);
        break;
      case 1:
        setLoadStage(strings.PROCESS);
        break;
      case 2:
        setLoadStage(strings.RENDER);
        break;
      default:
        setLoadStage(strings.LOADED);
        break;
    }
  }, [step]);

  const title =
    '"' +
    (workspaceName !== null ? workspaceName : formatWorkspaceId(workspaceId)) +
    '"';
  return (
    <Modal>
      <div className="flex flex-col relative justify-center items-center">
        <h1 className="block text-2xl text-center whitespace-no-wrap truncate break-all w-full">
          {strings.LOADING_WORKSPACE}
          <span className="block text-center w-full truncate my-2">
            {title}
          </span>
        </h1>
        <div className="lds-roller pb-4">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {showLoadingPercent && (
          <>
            <div className="mt-2 text-accent">
              {strings.STEP} {(step || 0) + 1}/3
            </div>
            <div className="mt-2 text-accent">
              {Math.floor(progress)}% {loadStage}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps)(LoadingWorkspaceDialog);
