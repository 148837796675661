import Downshift, { ControllerStateAndHelpers } from "downshift";
import React, { useCallback, useState } from "react";
import { closeIcon as CloseIcon } from "../assets/images/icons/sharingIcons";
import { emailRegex } from "../utils/emailRegex";
import { debounce } from "ts-debounce";
import useAsyncEffect from "use-async-effect";

//based on https://codesandbox.io/s/github/kentcdodds/downshift-examples/tree/master/?module=/src/downshift/other-examples/gmail/index.js&moduleview=1&file=/src/downshift/other-examples/gmail/index.js

type RecipientProps = React.PropsWithChildren<{
  isValid?: boolean;
  onRemove: () => void;
}>;

const Recipient: React.FC<RecipientProps> = ({
  onRemove,
  children,
  isValid = true,
}) => {
  return (
    <div
      className={`rounded-md sm:rounded-lg h-6 px-2 cursor-default bg-dark-primary-selection text-white ${
        !isValid ? "border border-red-900" : ""
      }`}
    >
      {children}
      <button
        onClick={onRemove}
        type="button"
        className="ml-1 align-middle text-indicator w-4 hover:text-accent focus:outline-none"
      >
        <CloseIcon />
      </button>
    </div>
  );
};

type RecipientInputProps = {
  strings: any;
  onChange: (selection: Contact[]) => void;
  fetchContacts: IFetchContacts;
  selectedContacts: Contact[];
};

export class RecipientInput extends React.Component<
  RecipientInputProps,
  { selectedContacts: Contact[] }
> {
  input = React.createRef<HTMLInputElement>();

  handleChange = (
    selectedContact: Contact | null,
    downshift: ControllerStateAndHelpers<Contact>
  ) => {
    if (selectedContact) {
      downshift.reset();
      this.props.onChange([...this.props.selectedContacts, selectedContact]);
    }
  };

  removeContact(contact: Contact) {
    this.input.current?.focus();
    this.props.onChange(
      this.props.selectedContacts.filter((c) => c !== contact)
    );
  }

  handleInputKeyDown = ({
    event,
    isOpen,
    selectHighlightedItem,
    highlightedIndex,
    reset,
    inputValue,
  }: InputKeyDownProps) => {
    if (isOpen && ["Tab", ",", ";"].includes(event.key) && inputValue) {
      event.preventDefault();
      if (highlightedIndex != null) {
        selectHighlightedItem();
      } else {
        reset();
        this.props.onChange([
          ...this.props.selectedContacts,
          makeContact(inputValue),
        ]);
      }
    }
  };

  itemToString = (i: Contact | null) => {
    return i ? (i.name === i.email ? i.name : `${i.name} (${i.email})`) : "";
  };

  render() {
    const { selectedContacts } = this.props;
    return (
      <Downshift
        itemToString={this.itemToString}
        selectedItem={null}
        onChange={this.handleChange}
        defaultHighlightedIndex={0}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          selectHighlightedItem,
          setHighlightedIndex,
          reset,
          inputValue,
          clearItems,
          setItemCount,
        }) => (
          <div className="relative">
            <div className="mb-1 flex flex-wrap gap-1">
              {selectedContacts.map((c) => (
                <Recipient
                  key={c.id}
                  isValid={c.email.match(emailRegex) != null}
                  onRemove={() => this.removeContact(c)}
                >
                  {this.itemToString(c)}
                </Recipient>
              ))}
            </div>
            <input
              {...getInputProps({
                ref: this.input,
                onKeyDown: (event) =>
                  this.handleInputKeyDown({
                    event,
                    selectHighlightedItem,
                    highlightedIndex,
                    isOpen,
                    reset,
                    inputValue,
                  }),
                placeholder: this.props.strings.ENTER_USER,
                className:
                  "input bg-dark-selected rounded border-none p-2 w-full",
              })}
            />
            {isOpen && (
              <FetchContacts
                searchValue={inputValue}
                omitContacts={selectedContacts}
                onLoaded={({ contacts }) => {
                  clearItems();
                  if (contacts) {
                    if (
                      contacts.length === 0 &&
                      isNewEmail(inputValue, selectedContacts)
                    ) {
                      setItemCount(1);
                      setHighlightedIndex(0);
                      return;
                    }
                    setHighlightedIndex(contacts.length ? 0 : null!);
                    setItemCount(contacts.length);
                  }
                }}
                fetchContacts={this.props.fetchContacts}
              >
                {({ loading, contacts, error }: FetchContactsState) => (
                  <div
                    {...getMenuProps({
                      className:
                        "overflow-auto bg-dark-selected shadow-md w-full absolute mt-1 max-h-xs z-10",
                    })}
                  >
                    {loading ? (
                      <div className="p-2">{this.props.strings.LOADING}</div>
                    ) : error ? (
                      <div className="p-2">
                        {this.props.strings.FAILED_TO_LOAD_USERS}
                      </div>
                    ) : contacts.length ? (
                      <ContactList
                        highlightedIndex={highlightedIndex}
                        getItemProps={getItemProps}
                        contacts={contacts}
                      />
                    ) : (
                      <>
                        {isNewEmail(inputValue, selectedContacts) && (
                          <div
                            {...getItemProps({
                              item: makeContact(inputValue!),
                              index: 0,
                              className:
                                "cursor-pointer px-2 py-1 border-b border-opacity-15 bg-opacity-15",
                            })}
                          >
                            {`${this.props.strings.USE_THIS_ADDRESS}: ${inputValue}`}
                          </div>
                        )}
                        <div className="p-2">
                          {this.props.strings.NO_RESULTS_FOUND}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </FetchContacts>
            )}
          </div>
        )}
      </Downshift>
    );
  }
}

function isNewEmail(inputValue: string | null, selectedContacts: Contact[]) {
  return (
    inputValue?.match(emailRegex) &&
    !selectedContacts.some(
      (c) =>
        inputValue.localeCompare(c.email, undefined, {
          sensitivity: "base",
          usage: "search",
        }) == 0
    )
  );
}

type InputKeyDownProps = {
  event: React.KeyboardEvent<HTMLInputElement>;
} & Pick<
  ControllerStateAndHelpers<Contact>,
  | "selectHighlightedItem"
  | "highlightedIndex"
  | "isOpen"
  | "reset"
  | "inputValue"
>;

type ContactListProps = {
  highlightedIndex: number | null;
  contacts: Contact[];
} & Pick<ControllerStateAndHelpers<Contact>, "getItemProps">;

const ContactList: React.FC<ContactListProps> = ({
  highlightedIndex,
  getItemProps,
  contacts,
}) => {
  return (
    <ul>
      {contacts.map((item, index) => (
        <li
          key={item.id}
          {...getItemProps({
            item,
            index,
            className: `cursor-pointer px-2 py-1 border-b border-opacity-15 ${
              highlightedIndex === index ? "bg-opacity-15" : ""
            }`,
          })}
        >
          <div>{item.name}</div>
          {item.name === item.email || (
            <div className="text-sm ml-1">{item.email}</div>
          )}
        </li>
      ))}
    </ul>
  );
};

type FetchContactsProps = {
  searchValue: string | null;
  omitContacts: Contact[];
  onLoaded: (
    state: Partial<Pick<FetchContactsState, "contacts" | "error">>
  ) => void;
  limit?: number;
  fetchContacts: IFetchContacts;
  children?: (state: FetchContactsState) => React.ReactNode;
};
type FetchContactsState = { loading: boolean; error: any; contacts: Contact[] };

const LOADING: FetchContactsState = {
  loading: true,
  error: null,
  contacts: [],
};
const idle = (contacts: Contact[]): FetchContactsState => ({
  loading: false,
  error: null,
  contacts,
});
const fetchError = (error: any): FetchContactsState => ({
  loading: false,
  error,
  contacts: [],
});

const FetchContacts: React.FC<FetchContactsProps> = ({
  children,
  fetchContacts,
  searchValue,
  omitContacts,
  limit,
  onLoaded,
}) => {
  const [state, setState] = useState<FetchContactsState>(idle([]));
  const fetch = useCallback(debounce(fetchContacts, 300), [fetchContacts]);

  useAsyncEffect(
    async (isActive) => {
      setState(LOADING);
      try {
        const {
          response: { data: contacts },
        } = await fetch(searchValue?.toLowerCase() ?? "", {
          omitContacts,
          limit,
        });
        if (!isActive()) {
          return;
        }
        onLoaded({ contacts });
        setState(idle(contacts));
      } catch (error) {
        if (!isActive()) {
          return;
        }
        onLoaded({ error });
        setState(fetchError(error));
      }
    },
    [searchValue, omitContacts, limit, fetch]
  );

  return <>{children?.(state)}</>;
};

export type Contact = {
  name: string;
  email: string;
  id: string;
};

export const makeContact = (inputValue: string): Contact => ({
  id: inputValue.toLowerCase(),
  email: inputValue,
  name: inputValue,
});

interface IFetchContacts {
  (searchValue: string, props?: FetchProps): Promise<FetchResponse>;
}

type FetchResponse = {
  response: {
    data: Contact[];
    requestId?: number;
  };
};

export type FetchProps = {
  omitContacts?: Contact[];
  limit?: number;
  requestId?: number;
  allContacts?: Contact[];
};
