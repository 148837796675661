import React, { FormEvent } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { ConfirmationDialog } from './ConfirmationDialog';
import {
  cancelDashboardOption,
  deleteWorkspace,
} from '../../state/workspaces/workspaces.actions';
import { getSelectedWorkspaceDetails } from '../../state/workspaces/workspaces.selector';
import { deleteWithPassword } from '../../utils/deleteWithPassword';
import { Localized } from '../../strings';

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
});

const mapDispatchToProps = {
  cancelDashboardOption,
  deleteWorkspace: deleteWorkspace.request,
};

type DeleteWorkspaceDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const DeleteWorkspaceDialog: React.FC<DeleteWorkspaceDialogProps> = ({
  cancelDashboardOption,
  deleteWorkspace,
  selectedWorkspaceDetails,
}) => {
  const deleteString = Localized.object('MORE_OPTIONS.DELETE');
  const message = Localized.string(
    'DELETE_WORKSPACE_DRAWER.WARNING',
    selectedWorkspaceDetails!.workspaceName
  );
  const deleteTitle = Localized.string(
    'DELETE_WORKSPACE_DRAWER.DELETE',
    selectedWorkspaceDetails!.workspaceName
  );
  const confirmDelete = Localized.object(
    'DELETE_WORKSPACE_DRAWER.CONFIRM_DELETE'
  );

  return (
    <ConfirmationDialog
      icon={require('../../assets/images/icons/trash-icon.svg')}
      title={deleteTitle}
      message={message}
      buttonText={deleteString}
      submitAction={(e: FormEvent) =>
        deleteWithPassword(e, deleteWorkspace, selectedWorkspaceDetails)
      }
      cancelAction={cancelDashboardOption}
      passwordRequired={!!selectedWorkspaceDetails?.hasPassword}
      confirmWithCheckmark
      confirmDelete={confirmDelete}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteWorkspaceDialog);
