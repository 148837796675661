import React, { useEffect, useState } from "react";

type ProgressProps = {
  title: string;
  step: number | undefined;
  progress: number;
  strings: any;
};

export const Progress: React.FC<ProgressProps> = ({
  title,
  step,
  progress,
  strings,
}) => {
  const showLoadingPercent = !!Math.floor(progress) || !!step;
  const [loadStage, setLoadStage] = useState(strings.LOADED);

  useEffect(() => {
    switch (step) {
      case 0:
        setLoadStage(strings.LOADED);
        break;
      case 1:
        setLoadStage(strings.PROCESS);
        break;
      default:
        setLoadStage(strings.LOADED);
        break;
    }
  }, [step]);

  return (
    <>
      <h1 className="block text-2xl text-center whitespace-no-wrap truncate break-all w-full">
        <span className="block text-center w-full truncate my-2">{title}</span>
      </h1>
      <div className="lds-roller pb-4">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="mt-2 text-accent">
        {strings.STEP} {(step || 0) + 1}/2
      </div>
      {showLoadingPercent && (
        <div className="mt-2 text-accent">
          {Math.floor(progress)}% {loadStage}
        </div>
      )}
    </>
  );
};
