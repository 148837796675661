import React, { useRef, useState } from "react";

export const UseCaseLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [showScrollbar, setShowScrollbar] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleWheelScroll = (e: React.WheelEvent) => {
    const containerScrollPosition: number = containerRef.current
      ? containerRef.current.scrollLeft
      : 0;
    const customDeltaY = e.deltaY > 0 ? 200 : -200;
    // setTimeout is needed to make it work on Chrome
    setTimeout(() => {
      containerRef.current?.scrollTo({
        top: 0,
        left: containerScrollPosition + customDeltaY,
        behavior: "smooth",
      });
    });
  };

  const onHover = () => {
    if (!showScrollbar) {
      setShowScrollbar(true);
    }
  };

  const onMouseLeave = () => {
    setShowScrollbar(false);
  };

  return (
    <>
      <div
        className={`lg:hidden w-screen overflow-y-visible overflow-x-auto overscroll-none ${
          showScrollbar ? "" : "disable-scrollbar"
        }`}
        ref={containerRef}
        onMouseOver={onHover}
        onMouseLeave={onMouseLeave}
        onWheel={(e) => handleWheelScroll(e)}
      >
        <div className="flex flex-row px-4 h-full w-fit">
          {React.Children.map(
            children,
            (child) => child && <div className="mx-2 mt-2 w-32">{child}</div>
          )}
        </div>
      </div>
      <div className="hidden md:flex w-full px-24 pb-3 flex-wrap">
        {React.Children.map(
          children,
          (child) =>
            child && (
              <div className="mr-6 w-full sm:w-1 md:w-1/2 lg:w-1/3 xl:w-1/4 xxl:w-1/5 wall:w-1/6 lwall:w-1/8 xlwall:w-1/10">
                {child}
              </div>
            )
        )}
      </div>
    </>
  );
};

export const geSingleRowUseCaseTileCount = (width: number) => {
  // these should match what's in tailwind.config.js
  const sm = 640;
  const md = 768;
  const lg = 1024;
  const xl = 1280;
  const xxl = 1600;
  const wall = 1920;
  const lwall = 2600;
  const xlwall = 3600;

  if (width < sm) return 1;
  if (width < md) return 3;
  if (width < lg) return 4;
  if (width < xl) return 5;
  if (width < xxl) return 6;
  if (width < wall) return 7;
  if (width < lwall) return 8;
  if (width < xlwall) return 10;
  return 12;
};
