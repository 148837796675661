export const THEME_DARK = 'theme-dark';
export const THEME_LIGHT = 'theme-light';
export const DEFAULT_THEME = THEME_LIGHT;
export const AUTHORIZED_THEMES = [THEME_DARK, THEME_LIGHT];

export function getColorSchemeFromLocalStorage(): string | null {
  let colorTheme = window.localStorage.getItem("colorTheme");
  if (colorTheme && !AUTHORIZED_THEMES.includes(colorTheme)) {
    colorTheme = null;
  }
  return colorTheme;
}

export function getAppColorScheme(): string {
  return getColorSchemeFromLocalStorage() ?? DEFAULT_THEME;
}

export function isDarkTheme(theme: string | null): boolean {
  return theme === THEME_DARK;
}

export function setAppColorScheme(theme: string): void {
  window.localStorage.setItem("colorTheme", theme);
}

export function getCheckedTheme(checked: boolean): string {
  return checked ? THEME_DARK : THEME_LIGHT
}
