import React from "react";
import { Localized } from "../strings";
import { EditLabelContent } from "./modals/EditLabelContent";
import { WorkspaceLabels } from "../state/workspaces/types";
import useAutoVisibility from "./hooks/useAutoVisibility";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { activateDashboardOption, populateAllWorkspaces } from "../state/workspaces/workspaces.actions";
import { DashboardOption } from "../state/workspaces/DashboardOption";
import { Icon } from "@hoylu/client-common";
import { CloseIcon } from "./modals/modalElements/CloseIcon";
import { getSelectedProject } from "../state/workspaces/projects.selector";

export type FilterByWorkspaceTagProps = {
  allLabels: WorkspaceLabels;
  selectedLabels: WorkspaceLabels;
  handleLabelsChange: (selected: WorkspaceLabels) => void;
};

export const FilterByWorkspaceTag: React.FC<FilterByWorkspaceTagProps> = ({
  allLabels,
  handleLabelsChange,
  selectedLabels
}) => {
  const dispatch = useDispatch();
  const { ref, componentVisible, setComponentVisible } =
    useAutoVisibility(false);
  const strings = Localized.object("FILTER_BY_WORKSPACE_LABEL");
  const onUpdateSelected = (selected: WorkspaceLabels) => {
    handleLabelsChange(
      selected.filter((l) => allLabels.find((lb) => lb.name === l.name))
    );
  };

  const filterIconStyle = { color: "var(--theme-title)" };

  const hasPopulatedAllWorkspaces = useSelector((state: RootState) => state.context.workspaces.hasPopulatedAllWorkspaces)
  const isProjectView = useSelector(getSelectedProject)

  const openFilters = () => {
    if (hasPopulatedAllWorkspaces || componentVisible) {
      dispatch(activateDashboardOption({
        optionType: DashboardOption.FILTER,
      }));
      setComponentVisible(!componentVisible);
    }
  }
  const populateAllWs = () => {
    if (!hasPopulatedAllWorkspaces) {
      dispatch(populateAllWorkspaces.request());
    }
  }

  return (
    <div
      ref={ref}
      className="hidden xs:flex relative"
      aria-label={strings.FILTER_BY_LABELS}
      tabIndex={0}
    >
      <div onMouseOver={populateAllWs}>
        <Icon
          style={filterIconStyle}
          hoverStyle={filterIconStyle}
          icon={"hoylu-ui-icons-filter"}
          onClick={openFilters}
          testId={"filter-by-label"}
          title={strings.FILTER_BY_LABELS}
        />
      </div>
      {componentVisible && hasPopulatedAllWorkspaces && (
        <div className="absolute xxs:w-1/2 xs:w-100 mz-10 top-10 right-0 bg-dark-primary mt-3 p-3 rounded-lg">
          <div className="flex pb-4">
            <h1 className="text-base font-bold leading-8 truncate text-indicator">
              {strings.FILTER_BY_LABELS}
            </h1>
            <CloseIcon onClose={() => setComponentVisible(false)} />
          </div>
          <EditLabelContent
            allLabels={allLabels}
            selected={selectedLabels}
            updateSelected={onUpdateSelected}
            canAddNewLabels={false}
            subscribeLabelsChanges
            enableHiddenWorkspaceSearch={!isProjectView}
          />
        </div>
      )}
    </div>
  );
}