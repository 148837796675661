import { AssetCollection, AssetPanel } from "@hoylu/client-common";
import React, { useCallback, useEffect, useMemo } from "react";
import { Modal } from "./modals/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelDashboardOption,
  createWorkspaceFromTemplate,
} from "../state/workspaces/workspaces.actions";
import { templatesSelectors } from "../state/templates";
import {
  TemplateAsset,
  WorkspaceTemplateCard,
} from "./cards/WorkspaceTemplateCard";
import { Localized } from "../strings";
import {
  TemplateCategoryState,
  TemplateMetadata,
} from "../state/templates/types";
import { metadataV3toWorkspaceDetails } from "../services/workspaces/api/to.workspace.details";
import { config, documentMetadata } from "../state/config/config.selector";
import { user } from "../state/user/user.selector";
import { templateRequests } from "../services/templates/template.requests";
import { workspaceRequests } from "../services/workspaces/workspaces.requests";
import { decode } from "html-entities";
import { getWorkspaceThumbnailUrl } from "../state/workspaces/workspaces.selector";
import {
  ORGANIZATION_CATEGORY_ID,
  QUICK_ACCESS_CATEGORY_ID,
} from "../services/templates/types";
import { fetchTemplateCategories } from "../state/templates/templates.actions";

export type DashboardAssetPanelProps = {};

const FALLBACK_CATEGORY_ICON_MAP = new Map<string, string>([
  ["Strategy & Planning", "hoylu-ui-icons-distribute"],
  ["Brainstorming & Ideation", "hoylu-ui-icons-bulb"],
  ["Agile & Lean", "hoylu-ui-icons-agile-wheel"],
  ["Construction", "hoylu-ui-icons-construction"],
]);

export const DashboardAssetPanel = ({}: DashboardAssetPanelProps) => {
  const dispatch = useDispatch();
  const t = Localized.object("ASSET_PANEL");
  const documentMetadataOrigin = useSelector(documentMetadata);
  const currentUser = useSelector(user);
  const templateCategories = useSelector(templatesSelectors.templateCategories);
  const conf = useSelector(config);
  const getTemplateThumbnailUrl = (workspaceId: string) =>
    getWorkspaceThumbnailUrl(workspaceId, conf);

  const [templateCache, setTemplateCache] = React.useState<
    Map<string, TemplateAsset[]>
  >(new Map());

  useEffect(() => {
    dispatch(fetchTemplateCategories.request());
  }, [dispatch]);

  const getTemplates = useCallback(
    (categoryId: string) =>
      templateRequests.getTemplates(
        documentMetadataOrigin,
        currentUser.token,
        categoryId
      ),
    [documentMetadataOrigin, currentUser.token]
  );

  const getOrganizationTemplates = useCallback(
    () =>
      templateRequests.getOrganizationTemplates(
        documentMetadataOrigin,
        currentUser.token
      ),
    [documentMetadataOrigin, currentUser.token]
  );

  const getTemplateDetails = useCallback(
    (id: string) =>
      workspaceRequests.getTemplateDocumentMetadata(
        documentMetadataOrigin,
        currentUser.token,
        id
      ),
    [documentMetadataOrigin, currentUser.token]
  );

  const createFromTemplate = useCallback(
    async (workspaceId: string) => {
      const documentMetadata = await getTemplateDetails(
        workspaceId
      ).toPromise();
      const workspaceDetails = metadataV3toWorkspaceDetails(documentMetadata);
      dispatch(createWorkspaceFromTemplate(workspaceDetails));
    },
    [dispatch]
  );

  const closeAssetPanel = () => dispatch(cancelDashboardOption());

  const toWorkspaceAssets = useCallback(
    (templates: TemplateMetadata[]): TemplateAsset[] => {
      return templates.map((template) => ({
        assetId: template.workspaceId,
        templateName: decode(template.name), // TODO: is uri encoding really the best way to do this?
        templateDescription: decode(template.description),
        fetchThumbnail: () => {
          const url = getTemplateThumbnailUrl(template.workspaceId);

          return fetch(url + "?defyCache=" + Date.now(), {
            headers: { Authorization: `Bearer ${currentUser.token}` },
          });
        },
        onCreate: () => createFromTemplate(template.workspaceId),
      }));
    },
    [createFromTemplate]
  );

  const getAssetsForTemplateCategory = useCallback(
    async (category: TemplateCategoryState): Promise<TemplateAsset[]> => {
      // return cached values if they exist
      if (templateCache.has(category.categoryId))
        return templateCache.get(category.categoryId)!;

      // fetching templates from service here instead of getting it from state, to fit AssetPanels interface
      const response =
        category.categoryId === ORGANIZATION_CATEGORY_ID
          ? await getOrganizationTemplates().toPromise()
          : await getTemplates(category.categoryId).toPromise();

      const assets = toWorkspaceAssets(response.templates);
      // add to cache
      setTemplateCache((cache) => {
        cache.set(category.categoryId, assets);
        return cache;
      });
      return assets;
    },
    [templateCache, toWorkspaceAssets]
  );

  const workspaceTemplatesCollection: AssetCollection<
    TemplateAsset
  >[] = useMemo(() => {
    return templateCategories
      .filter((cat) => cat.categoryId !== QUICK_ACCESS_CATEGORY_ID)
      .map((cat) => ({
        key: cat.name,
        icon:
          cat.icon ??
          FALLBACK_CATEGORY_ICON_MAP.get(decode(cat.name)) ??
          "hoylu-ui-icons-palette-brush",
        name: decode(cat.name),
        AssetsRenderer: WorkspaceTemplateCard,
        getAssets: () => getAssetsForTemplateCategory(cat),
        filters: [],
      }));
  }, [Localized, toWorkspaceAssets, templateCategories]);

  return (
    <Modal handleOutsideClick={closeAssetPanel} padding={"p-0"}>
      {/*We need to enforce line-height: 1 for common components*/}
      <div className={"assetPanelFix"}>
        <AssetPanel
          assetCollection={workspaceTemplatesCollection}
          headerIcons={[
            {
              icon: "hoylu-ui-icons-incomplete",
              onClick: closeAssetPanel,
              title: Localized.string("CLOSE_BUTTON.CLOSE"),
            },
          ]}
          title={Localized.string("WORKSPACE_TEMPLATES.TEMPLATES")}
          locales={{
            USE_COMPACT_VIEW: t.USE_COMPACT_VIEW,
            USE_EXPANDED_VIEW: t.USE_EXPANDED_VIEW,
          }}
          isDashboardPanel={true}
        />
      </div>
    </Modal>
  );
};
