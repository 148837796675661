import { ajax } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import {
  ProjectUsersResponse,
  WorkspaceDetails,
  WorkspacePermissionsAndUsers,
} from "../../../state/workspaces/types";
import { PopulateWorkspacesResult } from "../../../state/workspaces/workspaces.actions";
import {
  CreateWorkspaceRequestParameters,
  DocumentMetadataV1Request,
  DocumentMetadataV1Response,
  LegacyMyWorkspacesV1Response,
  MyWorkspacesV1Response,
  metadataV1toWorkspaceDetailsArray,
} from "./workspaces.v1.types";
import {
  postJSON,
  putJSON,
  requestText,
} from "../../dependencies/ajaxRequests";
import { WorkspaceType } from "../../../state/workspaces/WorkspaceType";
import { metadataV3toWorkspaceDetails, toWorkspaceDetails } from "./to.workspace.details";
import { UpsertWorkspaceUsersPermissions } from "./workspaces.v3.types";
import { encode } from "../../../utils/credential.helpers";
import { documentIdv3Requests } from "./documentIdv3.requests";

const EmptyWorkspaceDetailsArray: WorkspaceDetails[] = [];

/**
 * Gets a page of workspaces for the given user.
 * @returns An array of workspaces (already converted to WorkspaceDetails) and a cursor for the next page.
 */
function getMyWorkspacesPaged(
  origin: string,
  token: string,
  userId: string,
  limit: number,
  cursor?: string | number /* number is here to match type cursorOrOffset */
): Observable<PopulateWorkspacesResult> {
  let url = `${origin}/api/v1/workspaces/${userId}?limit=${limit}`;
  if (cursor) {
    url += `&cursor=${cursor}`;
  }
  return ajax
    .getJSON<MyWorkspacesV1Response>(url, {
      Authorization: `Bearer ${token}`,
    })
    .pipe(
      mergeMap((response) => {
        const { workspaces, cursor } = response;
        if (!workspaces || workspaces.length === 0) {
          return of({
            details: EmptyWorkspaceDetailsArray,
            cursorOrOffset: null,
          });
        } else {
          return of({
            details: metadataV1toWorkspaceDetailsArray(workspaces),
            cursorOrOffset: cursor,
          });
        }
      })
    );
}

/**
 * Gets all workspaces for the given user (legacy endpoint, not paged).
 * @returns An array of workspaces (already converted to WorkspaceDetails) and a cursor for the next page (always null).
 */
function getAllMyWorkspaces(
  origin: string,
  token: string
): Observable<PopulateWorkspacesResult> {
  const url = `${origin}/api/v1/workspaces`;
  return ajax
    .getJSON<LegacyMyWorkspacesV1Response>(url, {
      Authorization: `Bearer ${token}`,
    })
    .pipe(
      mergeMap((response) => {
        return of({
          details: metadataV1toWorkspaceDetailsArray(response),
          cursorOrOffset: null,
        });
      })
    );
}
function upsertUserPermissions(
  permission: UpsertWorkspaceUsersPermissions,
  userEmails: string[],
  currentPermissions: WorkspacePermissionsAndUsers
): WorkspacePermissionsAndUsers {
  let newPerms: WorkspacePermissionsAndUsers = {
    administrators: [],
    writers: [],
    readers: [],
    unspecifiedUsers: [],
    globalAccess: currentPermissions.globalAccess,
  };

  const addTo = (permissionList: string[]): string[] => {
    return [...new Set(permissionList.concat(userEmails))]; // combine and remove duplicates
  };
  const removeFrom = (permissionList: string[]): string[] => {
    return permissionList.filter((user) => !userEmails.includes(user));
  };

  switch (permission) {
    case UpsertWorkspaceUsersPermissions.Administer:
      newPerms.administrators = addTo(currentPermissions.administrators);
      newPerms.writers = addTo(currentPermissions.writers);
      newPerms.readers = addTo(currentPermissions.readers);
      newPerms.unspecifiedUsers = removeFrom(
        currentPermissions.unspecifiedUsers
      );
      break;
    case UpsertWorkspaceUsersPermissions.Write:
      newPerms.administrators = removeFrom(currentPermissions.administrators);
      newPerms.writers = addTo(currentPermissions.writers);
      newPerms.readers = addTo(currentPermissions.readers);
      newPerms.unspecifiedUsers = removeFrom(
        currentPermissions.unspecifiedUsers
      );
      break;
    case UpsertWorkspaceUsersPermissions.Read:
      newPerms.administrators = removeFrom(currentPermissions.administrators);
      newPerms.writers = removeFrom(currentPermissions.writers);
      newPerms.readers = addTo(currentPermissions.readers);
      newPerms.unspecifiedUsers = removeFrom(
        currentPermissions.unspecifiedUsers
      );
      break;
    case UpsertWorkspaceUsersPermissions.None:
      newPerms.administrators = removeFrom(currentPermissions.administrators);
      newPerms.writers = removeFrom(currentPermissions.writers);
      newPerms.readers = removeFrom(currentPermissions.readers);
      newPerms.unspecifiedUsers = addTo(currentPermissions.unspecifiedUsers);
      break;
  }

  return newPerms;
}

function upsertWorkspaceUserPermissions(
  origin: string,
  token: string,
  workspaceId: string,
  userEmails: string[],
  permission: UpsertWorkspaceUsersPermissions
): Observable<WorkspaceDetails> {
  // Since v1 requires that we send an entire workspace metadata object, we need to get the current metadata first
  return documentIdv3Requests
    .getRawWorkspaceMetadata(origin, token, workspaceId)//no need to fetch permissions as this request is not used (useV3 flag)
    .pipe(
      mergeMap((metadata) => {
        // convert to WorkspaceDetails because its easier to work with
        const details = metadataV3toWorkspaceDetails(metadata);
        if (!details.roles)
          throw new Error(
            `Workspace permissions are missing from the fetched metadata of workspace ${workspaceId}`
          );

        // Add the user to the metadata
        const updatedMetadata: DocumentMetadataV1Request = {
          schemaVersion: details.schemaVersion,
          // only name and permissions are suppored to be updated for now
          documentType: details.workspaceType,
          pageSize: details.pageSize,
          documentName: details.workspaceName || "",
          module: details.module,
          permissions: upsertUserPermissions(
            permission,
            userEmails,
            details.roles
          ),
          // Remark: If document metadata change, new properties need to be added here too otherwise changing a workspace name will delete them
          //         Once my-workspace service is fixed, we could simplify this code again
        };

        // Send the updated metadata to the server
        return updateRawWorkspaceMetadata(
          origin,
          token,
          workspaceId,
          updatedMetadata
        );
      }),
      // convert the response back to WorkspaceDetails
      mergeMap((metadata) => of(toWorkspaceDetails(metadata)))
    );
}

function removeUsers(
  userEmails: string[],
  currentPermissions: WorkspacePermissionsAndUsers
): WorkspacePermissionsAndUsers {
  return {
    administrators: currentPermissions.administrators.filter(
      (user) => !userEmails.includes(user)
    ),
    writers: currentPermissions.writers.filter(
      (user) => !userEmails.includes(user)
    ),
    readers: currentPermissions.readers.filter(
      (user) => !userEmails.includes(user)
    ),
    unspecifiedUsers: currentPermissions.unspecifiedUsers.filter(
      (user) => !userEmails.includes(user)
    ),
    globalAccess: currentPermissions.globalAccess,
  };
}

function removeUsersFromWorkspacePermissions(
  origin: string,
  token: string,
  workspaceId: string,
  userEmails: string[]
): Observable<WorkspaceDetails> {
  // Since v1 requires that we send an entire workspace metadata object, we need to get the current metadata first
  return documentIdv3Requests
    .getRawWorkspaceMetadata(origin, token, workspaceId)//no need to fetch permissions as this request is not used (useV3 flag)
    .pipe(
      mergeMap((metadata) => {
        // convert to WorkspaceDetails because its easier to work with
        const details = metadataV3toWorkspaceDetails(metadata);
        if (!details.roles)
          throw new Error(
            `Workspace permissions are missing from the fetched metadata of workspace ${workspaceId}`
          );

        // Add the user to the metadata
        const updatedMetadata: DocumentMetadataV1Request = {
          schemaVersion: details.schemaVersion,
          // only name and permissions are suppored to be updated for now
          documentType: details.workspaceType,
          pageSize: details.pageSize,
          documentName: details.workspaceName || "",
          module: details.module,
          permissions: removeUsers(userEmails, details.roles),
          // Remark: If document metadata change, new properties need to be added here too otherwise changing a workspace name will delete them
          //         Once my-workspace service is fixed, we could simplify this code again
        };

        // Send the updated metadata to the server
        return updateRawWorkspaceMetadata(
          origin,
          token,
          workspaceId,
          updatedMetadata
        );
      }),
      // convert the response back to WorkspaceDetails
      mergeMap((metadata) => of(toWorkspaceDetails(metadata)))
    );
}

/**
 * Gets all project's collaborators
 * @returns an array of ProjectUsersResponse
 */
function getProjectUsers(
  origin: string,
  token: string,
  containerId?: string,
): Observable<ProjectUsersResponse[]> {
  const url = `${origin}/api/v1/projects/${containerId}/users`;

  return ajax
    .getJSON<ProjectUsersResponse[]>(url, {
      Authorization: `Bearer ${token}`,
    });
}

function updateWorkspaceName(
  origin: string,
  token: string,
  workspaceId: string,
  name: string
): Observable<WorkspaceDetails> {
  // Since v1 requires that we send an entire workspace metadata object, we need to get the current metadata first
  return documentIdv3Requests
    .getRawWorkspaceMetadata(origin, token, workspaceId)
    .pipe(
      mergeMap((metadata) => {
        if (metadata.workspaceType === "DELETED")
          throw new Error("Cannot update the name of a deleted workspace");

        const details = metadataV3toWorkspaceDetails(metadata);
        const updatedMetadata: DocumentMetadataV1Request = {
          schemaVersion: details.schemaVersion,
          documentType: details.workspaceType,
          pageSize: details.pageSize,
          documentName: name,
          module: details.module,
          // if we keep permissions undefined or null, they will not be changed
        };
        return updateRawWorkspaceMetadata(
          origin,
          token,
          workspaceId,
          updatedMetadata
        );
      }),
      // convert the response back to WorkspaceDetails
      mergeMap((metadata) => of(toWorkspaceDetails(metadata)))
    );
}

function updateRawWorkspaceMetadata(
  origin: string,
  token: string,
  workspaceId: string,
  metadata: DocumentMetadataV1Request
): Observable<DocumentMetadataV1Response> {
  const url = `${origin}/api/v1/${workspaceId}`;

  return putJSON<DocumentMetadataV1Request, DocumentMetadataV1Response>(
    url,
    metadata,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

/**
 * Creates a new workspace.
 * @returns The workspace details of the newly created workspace.
 */
function createWorkspace(
  origin: string,
  token: string,
  workspaceParameters: CreateWorkspaceRequestParameters
): Observable<WorkspaceDetails> {
  const url = `${origin}/api/v1/`;

  /**
   *  IMPORTANT: When changing the schema version here also update it to the same value in /io-document/src/constants.ts
   */
  const SCHEMA_VERSION = 3;

  const body: DocumentMetadataV1Request = {
    schemaVersion: SCHEMA_VERSION,
    documentType: WorkspaceType.HOYLU,
    ...workspaceParameters,
  };

  return postJSON<DocumentMetadataV1Request, DocumentMetadataV1Response>(
    url,
    body,
    {
      Authorization: `Bearer ${token}`,
    }
  ).pipe(
    mergeMap((response) =>
      of(toWorkspaceDetails(response, workspaceParameters.module.name))
    )
  );
}

/** Remove a workspace from the users dashboard permanently */
function removeFromMyWorkspaces(
  origin: string,
  token: string,
  workspaceId: string
) {
  const url = `${origin}/api/v1/workspaces/${workspaceId}`;

  return ajax.delete(url, {
    Authorization: `Bearer ${token}`,
  });
}

function deleteWorkspace(
  origin: string,
  token: string,
  workspaceId: string,
  password?: string
) {
  const url = `${origin}/api/v1/${workspaceId}`;

  return ajax.delete(url, {
    Authorization: `Bearer ${token}`,
    "X-Hoylu-Document-Password": encode(password),
  });
}

function setWorkspacePassword(
  origin: string,
  token: string,
  workspaceId: string,
  currentPasword: string | undefined,
  password: string
) {
  const url = `${origin}/api/v1/${workspaceId}/password`;

  return requestText(
    url,
    { newPassword: password },
    {
      Authorization: `Bearer ${token}`,
      "X-Hoylu-Document-Password": encode(currentPasword),
    }
  );
}

function removeWorkspacePassword(
  origin: string,
  token: string,
  workspaceId: string,
  currentPassword: string
) {
  const url = `${origin}/api/v1/${workspaceId}/password`;

  return ajax.delete(url, {
    Authorization: `Bearer ${token}`,
    "X-Hoylu-Document-Password": encode(currentPassword),
  });
}

export const documentIdv1Requests = {
  getMyWorkspacesPaged,
  getAllMyWorkspaces,
  createWorkspace,
  upsertWorkspaceUserPermissions,
  removeUsersFromWorkspacePermissions,
  removeFromMyWorkspaces,
  deleteWorkspace,
  setWorkspacePassword,
  removeWorkspacePassword,
  updateWorkspaceName,
  getProjectUsers
};
