import React, { useState } from "react";
import { SectionPosition, ShareSection } from "./ShareSection";
import { ShareSectionHeader } from "./ShareSectionHeader";
import { shareIcon } from "../../../assets/images/icons/sharingIcons";
import { Localized } from "../../../strings";
import { CopyEmailLink } from "./CopyEmailLink";
import { PermissionsBar } from "./PermissionsBar";
import { ShareOptionValue } from "./ShareType";
import { HeaderPermissions } from "./HeaderPermissions";
import { CopyIcon } from "./icons/CopyIcon";
import { EmailIcon } from "./icons/EmailIcon";

export type LinkSharingSectionProperties = {
  workspaceName: string;
  publicPermissionLevel: ShareOptionValue;
  shareLink: string;
  email: string;
  commitUpdate: (sharingOption: ShareOptionValue) => void;
  isAdmin: boolean;
  options: ShareOptionValue[];
  isUpdatingWorkspace: boolean;
  activeLinkSharing: boolean;
};

export const LinkSharingSection: React.FC<LinkSharingSectionProperties> = ({
  workspaceName,
  publicPermissionLevel,
  shareLink,
  email,
  commitUpdate,
  isAdmin,
  options,
  isUpdatingWorkspace,
  activeLinkSharing
}) => {
  const strings = Localized.object("SHARE_WORKSPACE_DIALOG");
  const [isSectionVisible, setIsSectionVisible] = useState(activeLinkSharing);

  const linkSharingIcons: JSX.Element[] = [
    <CopyIcon key={"link-sharing-copy"} workspaceLink={shareLink} />,
    <EmailIcon
      key={"link-sharing-email"}
      shareLink={shareLink}
      workspaceName={workspaceName}
    />,
  ];

  return (
    <ShareSection sectionPosition={SectionPosition.TOP} testId="share-link">
      <ShareSectionHeader
        sectionIcon={shareIcon}
        sectionTitle={strings.LINK_SHARING}
        onHeaderClick={() => setIsSectionVisible(!isSectionVisible)}
        isSectionVisible={isSectionVisible}
        icons={linkSharingIcons}
        headerContent={
          <HeaderPermissions
            text={strings.ANYONE_WITH_LINK}
            enabledOption={publicPermissionLevel}
          />
        }
      />
      {isSectionVisible && (
        <div className={"pl-9 pr-10 pt-1 pb-3"}>
          <CopyEmailLink workspaceName={workspaceName} shareLink={shareLink} />
          <div className={" pt-1"}>
            <PermissionsBar
              currentUser={email}
              text={strings.ANYONE_WITH_LINK + " "}
              commitUpdate={commitUpdate}
              isAdmin={isAdmin}
              enabledOption={publicPermissionLevel}
              options={options}
              isUpdating={isUpdatingWorkspace}
            />
          </div>
        </div>
      )}
    </ShareSection>
  );
};
