import React from "react";
import { closeIcon } from '../../../assets/images/icons/sharingIcons';
import { Localized } from "../../../strings";
import { StandaloneIcon } from "../sharing/icons/StandaloneIcon";


type CloseIconProps = {
  onClose: () => void,
  title?: string,
  style?: string
}

export const CloseIcon: React.FC<CloseIconProps> = ({
  onClose,
  title,
  style = ""
}) => {
  const strings = Localized.object("CLOSE_BUTTON");
  return (
    <div className={"close-button " + style} title={strings.CLOSE}>
      <StandaloneIcon icon={closeIcon()} title={title} onClick={onClose} />
    </div>
  );
}