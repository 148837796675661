import React, { useMemo } from 'react';
import { Label } from "../../../state/workspaces/types";

type WorkspaceLabelProps = {
  label: Label;
  onClick: (e: React.MouseEvent, isWarningLabel: boolean) => void;
  isLimited: boolean;
  isReadOnly: boolean;
}

const WorkspaceLabel = ({
  label,
  onClick,
  isLimited,
  isReadOnly
}: WorkspaceLabelProps): JSX.Element => {

  const [labelIcon, labelBg] = useMemo(() => {
    let icon = null;
    let bg = "bg-accent";

    if (isLimited) {
      icon = <div className="hoylu-ui-icons-warning" />;
      bg = "bg-limited";
    }

    if (isReadOnly) {
      icon = <div className="hoylu-ui-icons-edit-not-allowed" />;
      bg = "bg-yellow-400";
    }

    return [icon, bg];
  }, [isLimited, isReadOnly]);

  return (
    <div
      onClick={(e) => onClick(e, isLimited)}
      title={label.name}
      className={`${labelBg} flex border-primary text-primary overflow-hidden font-lato font-bold text-xxs ml-half px-1 py-half rounded-sm cursor-pointer`}
    >
      {labelIcon}
      <div className="overflow-hidden leading-3 whitespace-no-wrap">
        {label.name}
      </div>
    </div>
  );
}

export default WorkspaceLabel;