import React from "react";
import { StandaloneIcon } from "./StandaloneIcon";
import { email } from "../../../../assets/images/icons/sharingIcons";
import {sendEmail} from "../../../../utils/sharing.helpers";

export type EmailIconProps = {
  shareLink: string;
  workspaceName?: string;
}

export const EmailIcon: React.FC<EmailIconProps> = ({
  shareLink,
  workspaceName
}) => {
  return (
    <div className={"mr-1"}>
      <StandaloneIcon icon={email()} onClick={() => sendEmail(workspaceName ?? "", shareLink)}/>
    </div>
  );
}