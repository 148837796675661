import React, { useState } from "react";
import { useClickOutside } from "@hoylu/client-common";

const DROPDOWN_CLASSES =
  "flex font-lato relative bg-dark-selected rounded-lg xxs:w-1/3 xs:w-1/3 sm:w-40 text-indicator";

export type Option<T> = {
  label: string | JSX.Element;
  value: T;
};

export type DropdownProps<T> = {
  label: string | JSX.Element;
  options: Option<T>[];
  onChange: (value: T) => void;
  value: T;
  height?: string;
  dataTestId?: string;
  classes?: string;
  selectedLabelClasses?: string;
  optionsColorClasses?: string;
};

export type OptionEntryProps<T> = {
  onSelect: (value: T) => void;
} & Option<T>;

export const OptionEntry = <T,>({
  label,
  value,
  onSelect,
}: OptionEntryProps<T>) => {
  return (
    <div
      className={"cursor-pointer hover:bg-dark-selected p-1"}
      onClick={() => onSelect(value)}
    >
      {label}
    </div>
  );
};

export const Dropdown = <T,>({
  label,
  value,
  options,
  onChange,
  height = "h-10",
  dataTestId = "dropdown",
  classes = DROPDOWN_CLASSES,
  selectedLabelClasses,
  optionsColorClasses = "bg-dark-primary text-primary-text",
}: DropdownProps<T>) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useClickOutside(() => setOpen(false));
  const hasOptions = options.length > 0;

  return (
    <div ref={dropdownRef} className={classes}>
      <div
        className={`flex overflow-hidden ${height} w-full ${
          hasOptions ? "cursor-pointer" : ""
        }`}
        onClick={(e) => {
          setOpen((open) => !open);
          e.stopPropagation();
          e.preventDefault();
        }}
        data-test-id={dataTestId}
      >
        <div className={`flex flex-no-wrap overflow-hidden items-center w-full ${height} px-2 mb-1 text-sm`}>
          <div className={selectedLabelClasses}>
            <div className="truncate">
              {value ? options.find((o) => o.value === value)?.label : label}
            </div>
          </div>
        </div>
        {hasOptions && (
          <div className="flex flex-shrink-0 items-center px-2 text-xl">
            <div
              className={open ? "hoylu-ui-icons-triangle-up" : "hoylu-ui-icons-triangle-down"}
            />
          </div>
        )}
      </div>
      {open && hasOptions && (
        <div
          className={`absolute flex flex-col w-full z-10 top-8 right-0 mt-3 p-3 rounded-lg max-h-xs overflow-y-auto ${optionsColorClasses}`}
        >
          {options.map((o, idx) => (
            <OptionEntry
              label={o.label}
              value={o.value}
              key={idx}
              onSelect={(value) => {
                setOpen(false);
                onChange(value);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};
