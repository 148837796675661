import React, { useState } from "react";
import { Localized } from "../../../strings";

type PasswordInputProps = {
  password: string;
  passwordOnChange: (value: string) => void;
  placeholder: string;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  password,
  passwordOnChange,
  placeholder
}) => {
  const strings = Localized.object("PASSWORD_INPUT");
  const [isPasswordPlainText, toggleShowPassword] = useState(false);
  const getPasswordInputIcon = (): string => {
    return isPasswordPlainText
      ? require('../../../assets/images/icons/eyeclosed.svg')
      : require('../../../assets/images/icons/eyeopen.svg');
  };

  return (
    <div className="flex justify-end my-3/2 w-2/3 bg-dark-selected rounded text-indicator">
      <input
        className="input placeholder-indicator"
        type={isPasswordPlainText ? 'text' : 'password'}
        name="password"
        value={password}
        onChange={e => passwordOnChange(e.target.value)}
        placeholder={placeholder} />
      <img
        className="pl-2 pr-2 py-1"
        src={getPasswordInputIcon()}
        onClick={() => toggleShowPassword(!isPasswordPlainText)}
        alt={strings.TOGGLE_PASSWORD}
        title={strings.TOGGLE_PASSWORD_VISIBILITY} />
    </div>
  );
};