import React from "react";
import { RootState } from "typesafe-actions";
import { connect } from "react-redux";
import { AppMode } from "../../state/mode/mode.reducer";
import NewWorkspaceDialog from "./NewWorkspaceDialog";
import LoadingWorkspaceDialog from "./LoadingWorkspaceDialog";
import OpenSharedWorkspaceDialog from "./OpenSharedWorkspaceDialog";
import HideWorkspaceDialog from "./HideWorkspaceDialog";
import LeaveWorkspaceDialog from "./LeaveWorkspaceDialog";
import DeleteWorkspaceDialog from "./DeleteWorkspaceDialog";
import RemoveWorkspaceDialog from "./RemoveWorkspaceDialog";
import ShareWorkspaceDialog, {ShareWorkspaceDialogSections} from "./sharing/ShareWorkspaceDialog";
import WhatsNewDialog from "./WhatsNewDialog";
import { DashboardOption } from "../../state/workspaces/DashboardOption";
import FreeUpsellDialog from "./FreeUpsellDialog";
import CompleteAccount from "../prompts/CompleteAccount";
import DuplicateWorkspaceDialog from "./DuplicateWorkspaceDialog";
import EditLabelDialog from "./EditLabelDialog";
import ErrorDialogRouter from "./ErrorDialogRouter";
import AssignProjectDialog from "./AssignProjectDialog";
import { InviteUserDialog } from "./InviteUsersDialog";
import RemoveWorkspaceFromProjectDialog from "./RemoveWorkspaceFromProjectDialog";
import { DashboardAssetPanel } from "../DashboardAssetPanel";
import CreateWorkspaceFromTemplateDialog from "./CreateWorkspaceFromTemplateDialog";
import DownloadDialog from "./downloadDialog/DownloadDialog";
import UploadDialog from "./uploadDialog/UploadDialog";
import { ThumbnailEditorDialog } from "./ThumbnailEditorDialog";
import { ManageAccount } from "./ManageAccount";
import { ConfirmAccountDelete } from "./ConfirmAccountDelete";
import { RequestingSnapshotDialog } from "./RequestingSnapshotDialog";
import LinkedMasterDialog from "./LinkedMasterDialog";
import ChangeMasterDialog from "./ChangeMasterDialog";
import DeleteTemplateDialog from "./DeleteTemplateDialog";
import SearchWorkspaceDialog from "./SearchWorkspaceDialog";

const mapStateToProps = (state: RootState) => {
  let activeOption = state.context.workspaces.activeOption;
  // state.context.user.isLoggedIn can be either boolean or 'UNKNOWN' so can't cast it to just !!state.context.user.isLoggedIn
  let isLoggedIn =
    typeof state.context.user.isLoggedIn === "boolean" &&
    state.context.user.isLoggedIn;

  return {
    isPartialAccount: state.context.user.profile.isPartialAccount,
    isErrorState: state.mode === AppMode.ERROR,
    isLoading: isLoggedIn && state.mode === AppMode.LOADING_WORKSPACE && state.context.config.teamsFrame != "sidePanel",
    isRequestingSnapshot: activeOption === DashboardOption.REQUESTING_SNAPSHOT,
    isCreating: activeOption === DashboardOption.CREATE,
    isOpeningSharedWorkspace: activeOption === DashboardOption.OPEN,
    isHiding: activeOption === DashboardOption.HIDE,
    isLeaving: activeOption === DashboardOption.LEAVE,
    isDuplicating: activeOption === DashboardOption.DUPLICATE,
    isCreatingNewWorkspaceFromTemplate:
      activeOption === DashboardOption.CREATE_FROM_TEMPLATE,
    isEditingLabels: activeOption === DashboardOption.ADD_LABEL,
    isDeleting: activeOption === DashboardOption.DELETE,
    isWorkspaceInfoRemoveDialog: activeOption === DashboardOption.REMOVE,
    isRemovingFromProject: activeOption == DashboardOption.REMOVE_FROM_PROJECT,
    isSharing: activeOption === DashboardOption.SHARE,
    isAssigningProject: activeOption === DashboardOption.ASSIGN_PROJECT,
    isEditingPermissions: activeOption === DashboardOption.PERMISSIONS,
    isShowingInfo: [DashboardOption.INFO, DashboardOption.REMOVE].includes(activeOption as DashboardOption),
    isNewVersion: state.context.config.newVersion,
    showUpsell: activeOption === DashboardOption.UPSELL,
    isInviting: activeOption === DashboardOption.INVITE,
    whatsNewEnabled:
      state.context.config.featureFlags.enableWhatsNew &&
      isLoggedIn &&
      !state.context.workspaces.waitingToEditID &&
      !state.context.workspaces.isCreatingNewWorkspaceUrl &&
      !state.context.config.webexMode && // webex is clearing localStorage quite often - so whats new is showing multiple times for one user
      state.mode === AppMode.DASHBOARD,
    assetPanelActive: activeOption === DashboardOption.ASSET_PANEL,
    isDownloading: activeOption === DashboardOption.DOWNLOAD,
    isUploading: activeOption === DashboardOption.UPLOAD,
    isManagingAccount: activeOption === DashboardOption.MANAGE_ACCOUNT,
    isConfirmingAccountRemoval: activeOption === DashboardOption.CONFIRM_ACCOUNT_DELETE,
    isShowingLinkedMasterWorkspace: activeOption === DashboardOption.LINKED_MASTER,
    isChangingMasterWorkspace: activeOption === DashboardOption.CHANGE_MASTER,
    isEditingThumbnail: state.context.workspaces.isEditingThumbnail,
    isDeletingTemplate: activeOption === DashboardOption.DELETE_TEMPLATE,
    isSearchingWorkspace: activeOption === DashboardOption.SEARCH_WORKSPACE
  };
};

type ModalsProps = ReturnType<typeof mapStateToProps>;

export const Modals: React.FC<ModalsProps> = ({
  isPartialAccount,
  isCreating,
  isLoading,
  isErrorState,
  isOpeningSharedWorkspace,
  isHiding,
  isLeaving,
  isDeleting,
  isWorkspaceInfoRemoveDialog,
  isRemovingFromProject,
  isSharing,
  isAssigningProject,
  isNewVersion,
  showUpsell,
  whatsNewEnabled,
  isDuplicating,
  isCreatingNewWorkspaceFromTemplate,
  isEditingLabels,
  isInviting,
  isEditingPermissions,
  assetPanelActive,
  isDownloading,
  isUploading,
  isManagingAccount,
  isConfirmingAccountRemoval,
  isShowingInfo,
  isEditingThumbnail,
  isRequestingSnapshot,
  isShowingLinkedMasterWorkspace,
  isChangingMasterWorkspace,
  isDeletingTemplate,
  isSearchingWorkspace
}) => (
  <>
    {isPartialAccount && <CompleteAccount />}
    {isNewVersion && whatsNewEnabled && <WhatsNewDialog />}
    {isCreating && <NewWorkspaceDialog />}
    {isLoading && <LoadingWorkspaceDialog />}
    {isRequestingSnapshot && <RequestingSnapshotDialog />}
    {isOpeningSharedWorkspace && <OpenSharedWorkspaceDialog />}
    {isHiding && <HideWorkspaceDialog />}
    {isLeaving && <LeaveWorkspaceDialog />}
    {isDeleting && <DeleteWorkspaceDialog />}
    {isWorkspaceInfoRemoveDialog && <RemoveWorkspaceDialog />}
    {isRemovingFromProject && <RemoveWorkspaceFromProjectDialog />}
    {isSharing && <ShareWorkspaceDialog openSection={ShareWorkspaceDialogSections.SHARE}/>}
    {isEditingPermissions && <ShareWorkspaceDialog openSection={ShareWorkspaceDialogSections.PERMISSION} />}
    {isShowingInfo && <ShareWorkspaceDialog openSection={ShareWorkspaceDialogSections.INFO} />}
    {isEditingThumbnail && <ThumbnailEditorDialog/>}
    {isAssigningProject && <AssignProjectDialog />}
    {isDuplicating && <DuplicateWorkspaceDialog />}
    {isCreatingNewWorkspaceFromTemplate && (
      <CreateWorkspaceFromTemplateDialog />
    )}
    {isEditingLabels && <EditLabelDialog />}
    {isErrorState && <ErrorDialogRouter />}
    {showUpsell && <FreeUpsellDialog />}
    {isInviting && <InviteUserDialog />}
    {assetPanelActive && <DashboardAssetPanel />}
    {isDownloading && <DownloadDialog />}
    {isUploading && <UploadDialog />}
    {isManagingAccount && <ManageAccount />}
    {isConfirmingAccountRemoval && <ConfirmAccountDelete />}
    {isShowingLinkedMasterWorkspace && <LinkedMasterDialog />}
    {isChangingMasterWorkspace && <ChangeMasterDialog />}
    {isDeletingTemplate && <DeleteTemplateDialog />}
    {isSearchingWorkspace && <SearchWorkspaceDialog />}
  </>
);

export default connect(mapStateToProps)(Modals);
