import React from "react";
import {PermissionIcon} from "./PermissionIcon";
import {usersIcon} from "../../../../assets/images/icons/sharingIcons";

export type UsersIconProperties = {
  isActive: boolean;
  toggleActive: (isActive: boolean) => void;
}

export const UsersIcon: React.FC<UsersIconProperties> = ({
   isActive,
   toggleActive
 }) => {
  return (
      <PermissionIcon icon={usersIcon()} isActive={isActive} toggleActive={toggleActive}/>
  );
}