import React from "react";

type ErrorProps = { strings: any };

export const Error: React.FC<ErrorProps> = ({ strings }) => {
  return (
    <h2 className="block text-center whitespace-no-wrap truncate break-all w-full">
      {strings.ERROR}
    </h2>
  );
};
