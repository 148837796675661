import React from "react";
import { CloseIcon } from "../modalElements/CloseIcon";

type ShareHeaderProps = React.PropsWithChildren<{
  modalTitle: string;
  closeModal?: () => void;
}>;

export const ShareHeader: React.FC<ShareHeaderProps> = ({
  modalTitle,
  closeModal,
  children
}) => {
  return (
    <div className="w-full inline-flex justify-end items-center pb-3">
      <div className="flex flex-row w-full justify-between">
        <div className="flex flex-row">
          <div className="text-indicator font-bold text-base ">{modalTitle}</div>
        </div>
        {children}
      </div>
      {closeModal && <CloseIcon onClose={closeModal}/>}
    </div>
  );
};