import React, { useState } from 'react';
import { Localized } from '../../../strings';
import { PasswordInput } from "./PasswordInput";
import {Button} from "../modalElements/Button";

export const MODE = {
  DEFAULT: 'DEFAULT',
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  CHANGE: 'CHANGE'
};

export const WorkspacePassword: React.FC<WorkspacePasswordProps> = ({
  hasPassword,
  confirmPasswordAction,
  requiresPassword
}) => {
  const strings = Localized.object("WORKSPACE_PASSWORD");
  const [mode, setMode] = useState(MODE.DEFAULT);
  const [isAddingPassword, toggleAddPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, updateConfirmPassword] = useState('');

  function handleChooseMode (newMode: string) {
    switch (newMode) {
      case MODE.ADD:
        toggleAddPassword(true);
        setMode(MODE.ADD);
        break;
      case MODE.REMOVE:
        toggleAddPassword(true);
        setMode(MODE.REMOVE);
        break;
      case MODE.CHANGE:
        toggleAddPassword(true);
        setMode(MODE.CHANGE);
        break;
      default:
        setMode(MODE.DEFAULT);
    }
  }

  const passwordValidation = new RegExp('^([a-zA-Za-zA-Z@#$%^&-+=()\\d]).{7,32}');

  let allowAddPassword: boolean = hasPassword ?
    !requiresPassword || passwordValidation.test(newPassword) :
    passwordValidation.test(newPassword) && newPassword === confirmPassword;

  let allowChangePassword: boolean = (
    allowAddPassword &&
    newPassword.length !== 0 &&
    confirmPassword.length !== 0 &&
    newPassword === confirmPassword
  );

  const triggerPasswordAction = (mode: string): void => {
    confirmPasswordAction(newPassword, currentPassword, mode);
    wipeOutData();
  };

  const wipeOutData = (): void => {
    toggleAddPassword(false);
    setNewPassword('');
    setCurrentPassword('');
    updateConfirmPassword('');
  }

  const setHeaderText = (mode: string): string => {
    switch (mode) {
      case MODE.ADD:
        return strings.ADD_WORKSPACE_PASSWORD;
      case MODE.REMOVE:
        return strings.REMOVE_WORKSPACE_PASSWORD;
      case MODE.CHANGE:
        return strings.CHANGE_WORKSPACE_PASSWORD;
      default: return "";
    }
  };

  return (
    <div className={"sm::ml-8"}>
      <div className={(isAddingPassword ? "" : "hidden")}>
        <div className="mb-1 text-xs w-4/5">{setHeaderText(mode)}</div>
        {mode !== MODE.REMOVE && <div className="mb-3/2 text-xs">{strings.CHARACTERS}</div>}
        {mode === MODE.REMOVE &&
          <PasswordInput
            password={newPassword}
            passwordOnChange={setNewPassword}
            placeholder={strings.ENTER_CURRENT_PASSWORD}
          />}
        {mode === MODE.ADD  &&
          <React.Fragment>
            <PasswordInput
              password={newPassword }
              passwordOnChange={ setNewPassword }
              placeholder={strings.SET_NEW_PASSWORD}
            />
            <PasswordInput
              password={ confirmPassword }
              passwordOnChange={ updateConfirmPassword }
              placeholder={strings.CONFIRM_NEW_PASSWORD}
            />
          </React.Fragment>}
        {mode === MODE.CHANGE  &&
          <React.Fragment>
            <PasswordInput
              password={currentPassword}
              passwordOnChange={setCurrentPassword}
              placeholder={strings.ENTER_CURRENT_PASSWORD}
            />
            <PasswordInput
              password={ newPassword}
              passwordOnChange={ setNewPassword}
              placeholder={strings.SET_NEW_PASSWORD}
            />
            <PasswordInput
                password={ confirmPassword }
                passwordOnChange={ updateConfirmPassword }
                placeholder={strings.CONFIRM_NEW_PASSWORD}
            />
          </React.Fragment>}
        <div className="flex justify-end">
          <Button
            label={strings.CANCEL}
            onButtonClick={() => wipeOutData() }
            additionalStyling='btn-negative mt-3/2'
          >
            {strings.CANCEL}
          </Button>
          {(mode === MODE.ADD || mode === MODE.REMOVE) &&
            <Button
              label={hasPassword ? strings.REMOVE_PASSWORD : strings.ADD_PASSWORD}
              onButtonClick={() => triggerPasswordAction(mode)}
              additionalStyling='btn-primary'
              disabled={!allowAddPassword}
            >
              {hasPassword ? strings.REMOVE_PASSWORD : strings.ADD_PASSWORD}
            </Button>}
          {mode === MODE.CHANGE &&
            <Button
              label={strings.CHANGE_PASSWORD}
              onButtonClick={() => triggerPasswordAction(mode)}
              additionalStyling='btn-primary'
              disabled={!allowChangePassword}
            >
              {strings.CHANGE_PASSWORD}
            </Button>}
        </div>
      </div>
      <div className={"flex justify-end items-center pt-2 " + (!isAddingPassword ? "" : "hidden")}>
        <span className={"mr-auto"}>{hasPassword ? strings.WORKSPACE_PASSWORD_SET : strings.WORKSPACE_NO_PASSWORD}</span>
        {hasPassword &&
          <Button
            label={strings.CHANGE}
            onButtonClick={() => handleChooseMode(MODE.CHANGE)}
            additionalStyling='btn-primary my-0'
          >
            {strings.CHANGE}
          </Button>}
          
        <Button
          disabled={hasPassword && requiresPassword}
          label={hasPassword ? strings.REMOVE_PASSWORD : strings.ADD_PASSWORD}
          onButtonClick={() => hasPassword ? handleChooseMode(MODE.REMOVE) : handleChooseMode(MODE.ADD)}
          additionalStyling={'btn-primary my-0'}
        >
          {hasPassword ? strings.REMOVE : strings.ADD_PASSWORD}
        </Button>
      </div>
    </div>
  );
};

type WorkspacePasswordProps = {
  hasPassword: boolean;
  confirmPasswordAction: (password: string, currentPassword: string, mode: string) => void,
  requiresPassword: boolean
};