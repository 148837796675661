import React, { FC, Fragment, useRef, useEffect } from "react";
import { RootState } from "typesafe-actions";
import { connect } from "react-redux";
import { HoyluToken, userRegistrationComplete } from "../../state/user/user.actions";
import { getLogin } from "../../services/dependencies/loginDependencies";

const mapStateToProps = (state: RootState) => ({
  email: state.context.user.profile.email,
  config: state.context.config,
});

const mapDispatchToProps = {
  complete: userRegistrationComplete,
};

type CompleteAccountProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const CompleteAccount: FC<CompleteAccountProps> = ({
  email,
  config,
  complete,
}) => {
  const completeRegistrationElement = useRef<HTMLDivElement>(null);
  const showForm = () => {
    if (!completeRegistrationElement.current) return;
    if (!config.serviceConfig.login) return;
    if (completeRegistrationElement.current.lastElementChild) {
      completeRegistrationElement.current.removeChild(
        completeRegistrationElement.current.lastElementChild
      );
    }
    const Login = getLogin({
      loginHost: config.serviceConfig.login,
    });
    Login.showCompleteRegistrationForm(
      email,
      completeRegistrationElement.current,
      (token: HoyluToken) => {
        complete(token);        
      }
    );
  };
  useEffect(showForm, [config]);
  return (
    <Fragment>
      {/* overlay everything except header to prevent interaction, header is required to log out */}
      <div className="fixed top-0 left-0 z-20 opacity-50 w-full h-full bg-gray-500"></div>

      <div className="absolute top-0 left-0 z-20 p-0 md:pt-20 w-full h-screen flex justify-center items-center">
        <div
          className="bg-primary p-0 md:p-2 rounded-lg shadow-xl"
          ref={completeRegistrationElement}
        ></div>
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteAccount);
