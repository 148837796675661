import React, { useMemo } from "react";
import { Localized } from "../../strings";
import { Dropdown, Option } from "../Dropdown";
import { ProjectSelectorLabel } from "../modals/AssignProjectDialog";
import { Project, WorkspaceProjects } from "../../state/workspaces/types";

// Escape value for Dropdown options
export const EmptyProject = "EmptyProject";

export type ProjectSelectionHandler = (
  project: Project | null | typeof EmptyProject
) => void;

type ProjectNavigationDropdownProps = {
  allProjects: WorkspaceProjects;
  selectedProject: Project | null;
  handleProjectSelected: ProjectSelectionHandler;
  showProjects: boolean;
  allowCreatingProject: boolean;
};

export const ProjectNavigationDropdown: React.FC<ProjectNavigationDropdownProps> = ({
  allProjects,
  selectedProject,
  handleProjectSelected,
  showProjects,
  allowCreatingProject,
}) => {
  const strings = Localized.object("FILTER_BY_NAME");

  const recentWorkspaceLabel = ProjectSelectorLabel(
    "hoylu",
    strings.DASHBOARD,
    null,
    "var(--theme-notification-workspace-icon)"
  );

  const lastWorkspaceLabel = ProjectSelectorLabel(
    "plus",
    strings.NEW_PROJECT,
    null,
    "var(--global--accent--Color)"
  );

  let projectsDropdownOptions: Option<
    Project | typeof EmptyProject | null
  >[] = [
    { label: recentWorkspaceLabel, value: null },
    ...allProjects.map((p) => ({
      label: ProjectSelectorLabel(p.icon, p.name, p.status, p.color),
      value: p,
    })),
  ];

  if (allowCreatingProject) {
    projectsDropdownOptions.push({
      label: lastWorkspaceLabel,
      value: EmptyProject,
    });
  }

  const value = useMemo(
    () => allProjects.find(({ id }) => id === selectedProject?.id) ?? null,
    [allProjects, selectedProject]
  );

  if (!showProjects || !allProjects.length) {
    return null;
  }
  return (
    <div
      aria-label={strings.FILTER_BY_NAME}
      tabIndex={0}
      className="font-body py-1 xs:px-2 text-xl xxs:w-32 xs:w-48 sm:w-56 lg:w-68 justify-between text-theme-title"
    >
      <Dropdown
        options={allProjects.length > 0 ? projectsDropdownOptions : []}
        label={recentWorkspaceLabel}
        value={value}
        onChange={handleProjectSelected}
        dataTestId="workspace-project-selector-dropdown"
        height={"h-8"}
        classes="font-body border-b-2 border-accent text-base w-full justify-between text-theme-title rounded-b-none rounded-t bg-theme-header-button relative"
        selectedLabelClasses="xxs:text-lg xs:text-base"
        optionsColorClasses="bg-theme-project-dropdown text-primary-text"
      />
    </div>
  );
};

export default ProjectNavigationDropdown;
