import React from "react";
import { Modal } from "./Modal";
import { Localized } from "../../strings";


export const RequestingSnapshotDialog = () => {
  const strings = Localized.object("THUMBNAIL_EDITOR");

  return (
    <Modal>
      <div className="flex flex-col relative justify-center items-center">
        <h1 className="block text-2xl text-center whitespace-no-wrap truncate break-all w-full">
          {strings.GENERATE_THUMBNAIL}
        </h1>
        <div className="lds-roller pb-4">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
       </div>
    </Modal>
  );
};
