import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { getAllWorkspaces } from '../../state/workspaces/workspaces.selector';
import { defaultDashboardPageSize } from '../../utils/defaultDashboardPageSize';
import { loadMoreWorkspaces } from "../../state/workspaces/workspaces.actions";

export const moreWorkspacesToLoad = (
  hasPopulatedAllWorkspaces: boolean,
  cursorOrOffset: string | number
): boolean => {
  if (hasPopulatedAllWorkspaces || cursorOrOffset === "") {
    return false;
  }
  return true;
};

export const WorkspacesLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  let cardStyle =
    "mt-6 w-full px-2 xxs:w-1/2 sm:px-3 sm:mt-6 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 xxl:w-1/6 wall:w-1/7 lwall:w-1/8 xlwall:w-1/10";

  const hasPopulatedAllWorkspaces = useSelector(
    (state: RootState) => state.context.workspaces.hasPopulatedAllWorkspaces
  );
  const cursor = useSelector(
    (state: RootState) => state.context.workspaces.cursorOrOffset
  );
  const workspaces = useSelector((state: RootState) => getAllWorkspaces(state));
  const dispatch = useDispatch();

  return (
    <>
      <InfiniteScroll
        dataLength={workspaces.length}
        next={() =>
          dispatch(
            loadMoreWorkspaces.request({
              limit: defaultDashboardPageSize,
              cursorOrOffset: cursor,
            })
          )
        }
        hasMore={moreWorkspacesToLoad(hasPopulatedAllWorkspaces, cursor)}
        loader={""}
        className="w-full flex flex-wrap overflow-visible"
      >
        {React.Children.map(children, (child) => (
          <div
            style={
              React.isValidElement(child) && Number.isInteger(child.props.order)
                ? { order: child.props.order }
                : {}
            }
            className={
              `${
                //@ts-expect-error: https://github.com/microsoft/TypeScript/issues/52232
                React.isValidElement(child) && child.props.hide ? "hidden" : ""
              } ` + cardStyle
            }
          >
            {child}
          </div>
        ))}
      </InfiniteScroll>
    </>
  );
};
