import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from "react";
import {SectionPosition, ShareSection} from "./ShareSection";
import {ShareSectionHeader} from "./ShareSectionHeader";
import {Icon, TextInput, useValidatedState } from "@hoylu/client-common";
import SimpleWorkspaceCard from "../../cards/SimpleWorkspaceCard";
import {Button} from "../modalElements/Button";
import {useDispatch, useSelector} from "react-redux";
import * as workspaceActions from "../../../state/workspaces/workspaces.actions";
import {cancelDashboardOption} from "../../../state/workspaces/workspaces.actions";
import { getSelectedWorkspaceDetails } from "../../../state/workspaces/workspaces.selector";
import {Localized} from "../../../strings";
import {DashboardOption} from "../../../state/workspaces/DashboardOption";
import {RootState} from "typesafe-actions";
import {InfoSection} from "./ShareType";
import {editThumbnail} from "../../../state/workspaces/workspaces.actions";
import {getUserOrgId} from "../../../state/user/user.selector";

export type WorkspaceInfoSectionProps = {
  initiallyOpen: boolean;
  isOrgAdmin: boolean;
  handleChangeSectionType: Dispatch<SetStateAction<InfoSection>>;
}

export const WorkspaceInfoSection = ({
  initiallyOpen,
  isOrgAdmin,
  handleChangeSectionType
}: WorkspaceInfoSectionProps) => {
  const dispatch = useDispatch();
  const strings = Localized.object("SHARE_WORKSPACE_DIALOG");

  const isRequired = useCallback((value?: string): string|undefined => {
    if (value && value.length) return;
    return strings.MANDATORY_FIELD
  }, [strings.MANDATORY_FIELD])

  const isWithinCharLimit = useCallback((value?: string): string|undefined => {
    if (value && value.length < 200) return;
    return strings.CHARACTER_LIMIT;
  }, [strings.CHARACTER_LIMIT])

  const workspaceDetails = useSelector(getSelectedWorkspaceDetails);
  const userOrgId = useSelector(getUserOrgId);
  const isWorkspaceOwnedByOrg = useMemo(
    () => workspaceDetails?.orgId === userOrgId,
    [workspaceDetails, userOrgId]
  );
  const showTemplateCreationUIFlag = useSelector((state: RootState) => state.context.config.featureFlags.showTemplateCreationUI);
  const showOrgTemplateCreationUIFlag = useSelector((state: RootState) => state.context.config.featureFlags.orgTemplates);

  const showPublishAsTemplate = showTemplateCreationUIFlag || (isOrgAdmin && showOrgTemplateCreationUIFlag)

  const [isOpenSection, setIsOpenSection] = useState(initiallyOpen);
  const [workspaceName, setWorkspaceName, nameError] = useValidatedState<string | undefined>(workspaceDetails?.workspaceName,[isRequired, isWithinCharLimit])
  const canSave = useMemo(() => workspaceDetails?.workspaceName != workspaceName && !nameError, [nameError, workspaceName, workspaceDetails?.workspaceName])
  const isAdmin = !!workspaceDetails?.isAdmin;

  const saveName = useCallback(() => {
    if (workspaceDetails && workspaceName)
      dispatch(
        workspaceActions.updateWorkspaceName.request({
          workspaceId: workspaceDetails.workspaceId,
          name: workspaceName,
        })
      );
  }, [workspaceDetails?.workspaceId, workspaceName]);

  const showRemoveWorkspacePrompt = () =>
    dispatch(workspaceActions.activateDashboardOption({
      optionType: DashboardOption.REMOVE,
      workspaceId: workspaceDetails?.workspaceId,
    }))

  const toggleOnHeaderClick = () => {
    setIsOpenSection(prevState => !prevState)
  }

  const close = () => dispatch(cancelDashboardOption());

  return (
    <ShareSection
      sectionPosition={SectionPosition.TOP}
      testId="workspace-info"
    >
      <ShareSectionHeader
        sectionIcon={() => <span className={"hoylu-ui-icons-blank pr-1 text-2xl"}/>}
        sectionTitle={strings.WORKSPACE_INFO}
        isSectionVisible={isOpenSection}
        onHeaderClick={toggleOnHeaderClick}
        alwaysShowIcons={true}
      />
      {isOpenSection && !!workspaceDetails &&
          <div className={"pl-9 pr-10 pt-3/2 pb-3"}>
              <div className={"flex flex-column items-center justify-between gap-4"}>
                  <SimpleWorkspaceCard workspaceDetails={workspaceDetails}>
                    {workspaceDetails?.isAdmin ?
                      <div className={"flex flex-column justify-start items-center gap-1 w-full cursor-pointer"}
                           onClick={() => dispatch(editThumbnail({flag:true}))}>
                        <span className={"hoylu-ui-icons-edit"}/>
                        <span>{strings.CHANGE_THUMBNAIL}</span>
                      </div>
                      : <span className={"truncate"} title={workspaceDetails.workspaceName}>{workspaceDetails.workspaceName}</span>}
                  </SimpleWorkspaceCard>
                  <TextInput label={strings.WORKSPACE_TITLE}
                             value={workspaceName}
                             onChange={setWorkspaceName}
                             error={nameError}
                             style={{flex:1}}
                             disabled={!workspaceDetails.isAdmin}
                  />
              </div>
              <div className={"flex justify-between items-center"}>
                <div className={"flex items-center justify-start text-sm text-accent"}
                     style={{ width: "200px"}}
                     onClick={showRemoveWorkspacePrompt}
                >
                  <Icon icon="hoylu-ui-icons-incomplete"
                        style={{ fontSize: "var(--global--size-h1)", color: "#AECBFA", cursor: "pointer" }} />
                  <span className={"cursor-pointer"}>{strings.REMOVE_WORKSPACE + '...'}</span>
                </div>

                {isAdmin && (
                  <div>
                    <Button label={strings.CANCEL}
                            onButtonClick={close}
                            additionalStyling={"btn-secondary"}>
                      {strings.CANCEL}
                    </Button>
                    {showPublishAsTemplate && (
                      <Button label={!isWorkspaceOwnedByOrg ? strings.PUBLISH_AS_TEMPLATE_NOT_ALLOWED : strings.PUBLISH_AS_TEMPLATE}
                              onButtonClick={() => handleChangeSectionType(InfoSection.TEMPLATE)}
                              additionalStyling={"btn-primary"}
                              disabled={!isWorkspaceOwnedByOrg}>
                        {strings.PUBLISH_AS_TEMPLATE}
                      </Button>
                    )}
                    <Button label={"Save"}
                            onButtonClick={saveName}
                            additionalStyling={"btn-primary"}
                            disabled={!canSave}>
                      {strings.SAVE}
                    </Button>
                  </div>
                )}
              </div>
          </div>}
    </ShareSection>
  )
}