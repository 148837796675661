import React from "react";
import {StandaloneIcon} from "./StandaloneIcon";

export type PermissionIconProperties = {
  icon: JSX.Element
  isActive: boolean;
  toggleActive: (isActive: boolean) => void;
}

export const PermissionIcon: React.FC<PermissionIconProperties> = ({
  icon,
  isActive,
  toggleActive
}) => {
  const toggleActiveStyle = () => {
    toggleActive(!isActive);
  }

  return (
    <div className={"mr-1"} >
      <StandaloneIcon icon={icon} isSelected={isActive} onClick={toggleActiveStyle} />
    </div>
  );
}