import React, { PropsWithChildren, CSSProperties, useState, useMemo, useLayoutEffect } from "react";
import { hexToRgba } from "../utils/hexToRgba";
import { useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { isCreditLimitReached } from "../state/workspaces/workspaces.selector";
import { Icon } from "@hoylu/client-common";

export type CircularProgressBarProps = {
  percentage: number;
  themeColor?: string;
  radius?: number;    // Providing the radius, you can change the size of the progress bar
  warningHandler?: () => void   // Open UserProfileMenu while on UserProfileWidget
};

const warningColor = "var(--global--error--Color)"

const circleStyles: CSSProperties = {
  transition: "stroke-dashoffset 1s linear",
  stroke: hexToRgba("#000", 0.2), // Background color of the progress bar (remaining space)
  strokeWidth: "var(--global--size-h5)",
};

const progressBarColor:CSSProperties = {
  stroke: hexToRgba("#000", 0.4), // Progress value color (used space)
};

const childrenContainerStyles:CSSProperties = {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "100%",
};

const shadowStyles: CSSProperties = {
  borderRadius: "100%",
  boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.25)",
  color: hexToRgba("#000", 0.5),
};

const warningStyles:CSSProperties = {
  backgroundColor: warningColor,
  width: "2rem",
  height: "2rem",
  fontSize: "var(--global--size-h1)",
  fontWeight: "700",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const CircularProgressBar = ({
  percentage,
  themeColor = "#716c83",
  radius = 16,  // default value for the main place which is User Profile Widget
  warningHandler,
  children
}: PropsWithChildren<CircularProgressBarProps>) => {
  const [offset, setOffset] = useState<number>(0);
  const isWarningState = useSelector((state: RootState) => isCreditLimitReached(state));
  const dimensions = (radius * 2) + 8;  // Adding space to the container for progress bar thickness => "strokeWidth=0.5rem"
  const circumference = Math.PI * radius * 2;
  const circleCenterPoint = dimensions / 2;  // Center of the X and Y axis
  const geometricPrecision = "geometricPrecision";
  const warningIcon = useMemo(() => isWarningState
      ? <Icon
          icon={"hoylu-ui-icons-warning"}
          style={{ ...shadowStyles, ...warningStyles }}
          hoverStyle={{
            color: hexToRgba("#000", 0.5),
            backgroundColor: warningColor
          }}
          onClick={() => warningHandler?.()}
      />
      : null,
    [isWarningState]);

  // Animation
  useLayoutEffect(() => {
    let calculatedPercentage = percentage;

    if (percentage > 100) {
      calculatedPercentage = 100;
    }

    if (percentage < 0) {
      calculatedPercentage = 0;
    }

    setOffset(((100 - calculatedPercentage) / 100) * circumference);

  }, [percentage]);

  return (
    <div className={"relative block mx-2 rounded-full h-fit w-fit"}
         style={{ backgroundColor: isWarningState ? warningColor : themeColor }}
    >
      <svg width={dimensions} height={dimensions} shapeRendering={geometricPrecision}>
        <circle
          r={radius}
          cx={circleCenterPoint}
          cy={circleCenterPoint}
          shapeRendering={geometricPrecision}
          strokeDashoffset="0"
          fill={"transparent"}
          strokeDasharray={circumference}
          style={circleStyles}
        />
        <circle
          r={radius}
          cx={circleCenterPoint}
          cy={circleCenterPoint}
          shapeRendering={geometricPrecision}
          strokeDashoffset={"0"}
          fill={"transparent"}
          strokeDasharray={circumference}
          transform={`rotate(-90, ${circleCenterPoint}, ${circleCenterPoint})`} // To start from 12 o'clock position
          style={{
            ...circleStyles,
            ...progressBarColor,
            strokeDashoffset: offset,
          }}
        />
        {children}
      </svg>
      <div style={{ ...childrenContainerStyles, ...shadowStyles, backgroundColor: themeColor }}>
        {warningIcon ?? children}
      </div>
    </div>
  );
};