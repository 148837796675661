import React from 'react';
import { Button } from "@hoylu/client-common";
import { Localized } from "../../../strings";

export const CreditLimitSection = () => {
  const learnMore = Localized.string("USER_PROFILE_WIDGET.LEARN_MORE");
  const strings = Localized.object("CREDIT_LIMIT_WARNING_BANNER");

  return (
    <div
      className="flex items-center bg-attention justify-between mb-3 p-1 rounded-lg h-10"
      data-test-id="ws-info-credit-limit-banner">
      <div className="flex items-center gap-2">
        <div className="text-xl hoylu-ui-icons-warning" />
        <span className="text-base">{strings.TITLE}</span>
      </div>
      <div>
        <Button toolTip={learnMore}
                type="secondary"
                onClick={() => window.open("https://www.hoylu.com/plans", "_blank")}
                additionalStyles={{ color: "#fff" }}
                dataTestId="ws-info-plan-redirect-button"
          >
          {learnMore}
        </Button>
        <Button toolTip={strings.BUTTON}
                type="primary"
                onClick={() => window.open("https://manage.hoylu.com/u/billing", "_blank")}
                additionalStyles={{ marginRight: 0 }}
                dataTestId="ws-info-billing-redirect-button"
        >
          {`${strings.BUTTON}...`}
        </Button>
      </div>
    </div>
  )
}