import React, { CSSProperties } from "react";

type TagProps = {
  text: string;
  checked: boolean;
  onToggle: (text: string, checked: boolean) => void;
  color?: string;
  icon?: string;
};

export const TileTag: React.FC<TagProps> = ({
  text,
  onToggle,
  checked,
  color,
  icon,
}) => {
  let divIcon: JSX.Element = <></>;
  let style: CSSProperties = {};
  let additionalClasses = "";
  if (icon && color) {
    divIcon = (
      <div style={{ color: color }} className={`${icon} pl-1 text-xs`} />
    );
    style = { borderColor: color };
    additionalClasses = "border-2 border-solid items-center";
  }
  return (
    <div
      className={`rounded flex ${
        checked ? "bg-accent" : "bg-sticky-gray"
      } h-8 mr-1 mb-1 cursor-pointer p-3/2 truncate ${additionalClasses}`}
      style={style}
      onClick={() => onToggle(text, !checked)}
    >
      <input
        type="checkbox"
        checked={checked}
        className="mt-1 cursor-pointer self-center"
        readOnly
      />
      {divIcon}
      <div className="text-primary font-bold text-sm ml-1 truncate">{text}</div>
    </div>
  );
};
