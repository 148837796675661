import React, { useEffect, useState } from "react";
import { Localized } from "../../strings";
import { useSelector } from "react-redux";
import { user } from "../../state/user/user.selector";
import {
  getWorkspaceById,
  getWorkspaceThumbnailUrl,
  hasModifiedThumbnail
} from "../../state/workspaces/workspaces.selector";
import { config} from "../../state/config/config.selector";
import { RootState } from "typesafe-actions";

type UseCaseCardProps = {
  useCaseName: string;
  enabled: boolean;
  workspaceId?: string;
  onClick?: () => void;
  imageURL?: string;
  tooltip?: string;
  tooltipPostfix?: string;
  isDefaultModule?: boolean;
};

export const UseCaseCard: React.FC<UseCaseCardProps> = ({
  imageURL,
  useCaseName: moduleName,
  onClick,
  enabled,
  workspaceId,
  tooltip = "",
  tooltipPostfix = "",
  isDefaultModule= false
}) => {
  const strings = Localized.object("USE_CASE_CARD");
  const responsiveSizes = "sm:w-32 md:w-40 lg:w-48 lwall:w-56 xlwall:w-68";
  const style =
    `w-full h-full ${responsiveSizes} rounded-lg overflow-hidden shadow-lg bg-theme-thumbnails-footer cursor-pointer font-body 
    ${enabled ? "" : "opacity-25"}`;
  const additionalProps = isDefaultModule
    ? { 'tabIndex': 0, 'aria-label': Localized.string("CREATE_WORKSPACES_SECTION.MODULES.BLANK_WORKSPACE") }
    : {};

  const currentUser = useSelector(user);
  const conf = useSelector(config);
  const workspaceDetails = useSelector((state: RootState) => getWorkspaceById(state, workspaceId ?? ''));

  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>(undefined);
  const ignoreCache = useSelector((state: RootState) => hasModifiedThumbnail(state, workspaceId ?? ''));

  useEffect(() => {
    const fetchThumbnail = async () => {
      let url = getWorkspaceThumbnailUrl(workspaceId!, conf);
      if (ignoreCache) {
        url += "?defyCache=" + Date.now()
      }

      return fetch(url, { headers: {Authorization: `Bearer ${currentUser.token}`} })
        .then((response: Response) => {
          if (response.status === 200) {
            response.blob().then((blob: Blob) => {
              if (blob instanceof Blob) {
                setThumbnailUrl(URL.createObjectURL(blob));
              }
            });
          }
        })
        .catch(() => setThumbnailUrl(undefined));
    }

    if (workspaceDetails?.thumbnailUrl?.startsWith("blob:")) {
      setThumbnailUrl(workspaceDetails.thumbnailUrl)
    } else if (workspaceId) {
      fetchThumbnail()
    }},
    [workspaceDetails?.thumbnailUrl, workspaceId, ignoreCache]
  );

  return (
    <div
      onClick={onClick}
      title={tooltip + moduleName + tooltipPostfix}
      className={style}
      data-test-id={`create-${moduleName}`}
      {...additionalProps}
    >
      <div className="relative pb-9/16 overflow-hidden">
        {imageURL ? (
          <img
            className="absolute h-full w-full object-cover cursor-pointer"
            src={thumbnailUrl ?? imageURL}
            alt={strings.TILE_IMAGE}
          />
        ) : (
          <div
            className={
              "flex justify-center items-center absolute h-full w-full bg-notification"
            }
          >
            <div className={"text-xxl hoylu-ui-icons-plus"} />
          </div>
        )}
      </div>
      <header className="flex items-center justify-between leading-tight p-2">
        <h1 className="text-base truncate">{moduleName}</h1>
      </header>
    </div>
  );
};
