import React from 'react';
import {Localized} from "../../../strings";
import {ProjectStatuses} from "../../../state/workspaces/workspaces.reducer";
import {hiconToHoyluUiIconsCentered} from "../../../utils/icon.map";
import {Project} from "../../../state/workspaces/types";

const ProjectLabel = ({
  project,
  onClick,
}: {
  project: Project;
  onClick: (e: React.MouseEvent, project: Project) => void;
}): JSX.Element => {
  const isProjectInactive = project.status === ProjectStatuses.Inactive;
  const projectName = isProjectInactive ? `${Localized.string("FILTER_BY_NAME.INACTIVE")}: ${project.name}` : project.name;
  const tooltip = isProjectInactive ? Localized.string("WORKSPACE_CARD.INACTIVE_PROJECT_LABEL") : project.name;

  return (
    <div
      onClick={(e) => onClick(e, project)}
      title={tooltip}
      className={"bg-white flex flex-row items-center border border-solid text-primary overflow-hidden font-lato font-bold text-xxs ml-half pr-1 py-half rounded-sm cursor-pointer"}
      style={{ borderColor: `${project.color}` }}
    >
      <div
        style={{ color: `${project.color}` }}
        className={`${hiconToHoyluUiIconsCentered(
          project.icon
        )} pl-half pr-1 text-xs`}
      />
      <div className="overflow-hidden leading-3 whitespace-no-wrap">
        {projectName}
      </div>
    </div>
  );
};

export default ProjectLabel;