import {createAsyncAction, createAction, RootAction} from "typesafe-actions";

export type HoyluToken = string;

export interface LoginResponse {
  token: HoyluToken;
}

export interface UserInvitePayload {
  emails: string[];
  reason: string;
}

export const userIdUpdated = createAction("USER_ID_UPDATED")<string>(); // useful for telemetry to tie a userid to a session id

export const toggleUserDetails = createAction("TOGGLE_USER_DETAILS")(); // useful for telemetry to tie a userid to a session id

export const fetchWorkspaceToken = createAsyncAction(
  "FETCH_WORKSPACE_TOKEN_REQUEST",
  "FETCH_WORKSPACE_TOKEN_SUCCESS",
  "FETCH_WORKSPACE_TOKEN_FAILURE"
)<undefined, LoginResponse, Error>();

export const login = createAsyncAction(
  "LOGIN_REQUEST",
  "LOGIN_SUCCESS",
  "LOGIN_FAILURE"
)<undefined, LoginResponse, Error>();

export const logout = createAsyncAction(
  "LOGOUT_REQUEST",
  "LOGOUT_SUCCESS",
  "LOGOUT_FAILURE"
)<undefined, undefined, Error>();

export const getToken = createAsyncAction(
  "TOKEN_REQUEST",
  "TOKEN_SUCCESS",
  "TOKEN_FAILURE"
)<undefined, LoginResponse, Error>();

export const registerEmail = createAsyncAction(
  "REGISTER_EMAIL_REQUEST",
  "REGISTER_EMAIL_SUCCESS",
  "REGISTER_EMAIL_FAILURE"
)<string, LoginResponse, Error>();

export type PoliciesResponse = { policies: string[] };
export const getOrgPolicies = createAsyncAction(
  "GET_ORG_POLICIES_REQUEST",
  "GET_ORG_POLICIES_SUCCESS",
  "GET_ORG_POLICIES_FAILURE"
)<undefined, PoliciesResponse, Error>();

export type LastLoginInfo = {
  lastLoginTime: number;
  lastLoginVersion: string;
};

export type UserProfileResponse = {
  uuid: string;
  fName: string;
  lName: string;
  email: string;
  company: string;
  department: string;
  creationTime: number;
  lastLoginInfo: LastLoginInfo;
  emailValidated: boolean;
  gdprConsentTime: boolean;
};

export type UserInviteResponse = Record<string, string>;

export const getUserProfile = createAsyncAction(
  "GET_USER_PROFILE_REQUEST",
  "GET_USER_PROFILE_SUCCESS",
  "GET_USER_PROFILE_FAILURE"
)<undefined, UserProfileResponse, Error>();

export type OrgBannerResponse = { bannerBlobUrl: string };
export const getOrgBanner = createAsyncAction(
  "GET_ORG_BANNER_REQUEST",
  "GET_ORG_BANNER_SUCCESS",
  "GET_ORG_BANNER_FAILURE"
)<undefined, OrgBannerResponse, Error>();

export type OrgBannerConfigResponse = { bannerLink: string };
export const isOrgBannerConfigResponse = (
  o: any
): o is OrgBannerConfigResponse => "bannerLink" in o;
export const getOrgBannerConfig = createAsyncAction(
  "GET_ORG_BANNER_CONFIG_REQUEST",
  "GET_ORG_BANNER_CONFIG_SUCCESS",
  "GET_ORG_BANNER_CONFIG_FAILURE"
)<undefined, OrgBannerConfigResponse, Error>();

export const sendValidationEmail = createAsyncAction(
  "SEND_VALIDATION_REQUEST",
  "SEND_VALIDATION_SUCCESS",
  "SEND_VALIDATION_FAILURE"
)<undefined, string, Error>();

export const skipValidationEmail = createAsyncAction(
  "SKIP_VALIDATION_EMAIL_REQUEST",
  "SKIP_VALIDATION_EMAIL_SUCCESS",
  "SKIP_VALIDATION_EMAIL_FAILURE"
)<undefined, undefined, undefined>();

export enum LicenseType {
  Plus = "Plus",
  Enterprise = "Enterprise",
  Free = "Free"
}

export type LicenseInfo = {
  type: LicenseType;
  expiration: Date;
  allowedPremiumModules: string[];
  orgOwnerId: string | null;
  isExpired: boolean;
}

export type LoginRequiredAction = {
  accept: RootAction,
  reject: RootAction
}

export const getLicenseInfo = createAsyncAction(
  "GET_LICENSE_REQUEST",
  "GET_LICENSE_SUCCESS",
  "GET_LICENSE_FAILURE"
)<undefined, LicenseInfo, Error>();

export const userRegistrationComplete = createAction(
  "USER_REGISTRATION_COMPLETE"
)<HoyluToken>();

export const inviteUsers = createAsyncAction(
  "INVITE_USERS_REQUEST",
  "INVITE_USERS_SUCCESS",
  "INVITE_USERS_FAILURE"
)<UserInvitePayload, UserInviteResponse, Error>();

export const requestDelete = createAsyncAction("REQUEST_DELETE_REQUEST", "REQUEST_DELETE_SUCCESS", "REQUEST_DELETE_FAILURE")<undefined, undefined, Error>()

export const callWithLoginConfirm = createAction("CONFIRM_WITH_LOGIN")<LoginRequiredAction>();