import React from "react";

type MenuItemProps = {
  icon: string;
  label: string;
  onClick: () => void;
  title?: string;
}
const MenuItem = ({
  icon,
  label,
  onClick,
  title ,
}: MenuItemProps) => (
  <div title={title ?? label}>
    <button
      className="btn-option flex items-center w-full p-1 hover:bg-dark-selected text-theme-user-profile-dropdown-item-text"
      onClick={onClick}
      aria-label={title ?? label}
    >
      <div className={`w-4 text-white ${icon}`}/>
      <span className="pl-2 truncate">{label}</span>
    </button>
  </div>
);

export default MenuItem;