import {
  FetchOrganizationTemplatesResponse,
  FetchTemplateCategoriesResponse,
  FetchTemplatesResponse,
} from "./types";
import { ajax } from "rxjs/ajax";
import { Observable, forkJoin } from "rxjs";
import {
  PublishedTemplate,
  TemplateDetails,
  TemplateMetadata,
  UpdateTemplateDetails,
} from "../../state/templates/types";
import { patchJSON, postJSON, putJSON } from "../dependencies/ajaxRequests";

// TODO: This can be changed to v3 once the backend is ready, since there is no breaking change
const vLatest = "v1";

//TODO: Add proper handling of limit & cursor
const getTemplateCategories = (
  origin: string,
  token: string
): Observable<FetchTemplateCategoriesResponse> => {
  return ajax.getJSON<FetchTemplateCategoriesResponse>(
    `${origin}/api/${vLatest}/templates/categories?limit=30`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
};

//TODO: Add proper handling of limit & cursor
const getTemplates = (
  origin: string,
  token: string,
  categoryId: string
): Observable<FetchTemplatesResponse> => {
  return ajax.getJSON<FetchTemplatesResponse>(
    `${origin}/api/${vLatest}/templates/categories/${categoryId}?limit=30`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
};

const getOrganizationTemplates = (
  origin: string,
  token: string
): Observable<FetchOrganizationTemplatesResponse> => {
  return ajax.getJSON<FetchOrganizationTemplatesResponse>(
    `${origin}/api/${vLatest}/templates/organization`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
};

const getTemplateById = (
  origin: string,
  token: string,
  templateId: string
): Observable<TemplateDetails> => {
  return ajax.getJSON<TemplateDetails>(
    `${origin}/api/${vLatest}/templates/${templateId}`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
};

const deleteTemplate = (origin: string, token: string, templateId: string) => {
  return ajax.delete(`${origin}/api/${vLatest}/templates/${templateId}`, {
    Authorization: `Bearer ${token}`,
  });
};

const createTemplate = (
  origin: string,
  token: string,
  payload: TemplateMetadata
): Observable<TemplateMetadata> => {
  return postJSON(`${origin}/api/${vLatest}/templates`, payload, {
    Authorization: `Bearer ${token}`,
  });
};

const publishTemplate = (
  origin: string,
  token: string,
  categoryIds: string[],
  payload: PublishedTemplate
): Observable<PublishedTemplate[]> => {
  const requests = categoryIds.map((id) =>
    putJSON<PublishedTemplate, PublishedTemplate>(
      `${origin}/api/${vLatest}/templates/categories/${id}`,
      payload,
      {
        Authorization: `Bearer ${token}`,
      }
    )
  );

  return forkJoin(requests);
};

const removeTemplateFromCategories = (
  origin: string,
  token: string,
  categoryIds: string[],
  templateId: string,
): Observable<any[]> => {
  const requests = categoryIds.map((id) =>
    ajax.delete(
      `${origin}/api/${vLatest}/templates/categories/${id}/${templateId}`,
      {
        Authorization: `Bearer ${token}`,
      }
    )
  );

  return forkJoin(requests);
};

const updateTemplate = (
  origin: string,
  token: string,
  templateId: string,
  payload: UpdateTemplateDetails
): Observable<TemplateMetadata> => {
  return patchJSON(
    `${origin}/api/${vLatest}/templates/${templateId}`,
    payload,
    {
      Authorization: `Bearer ${token}`,
    }
  );
};

export const templateRequests = {
  createTemplate,
  deleteTemplate,
  getTemplateCategories,
  getTemplates,
  getOrganizationTemplates,
  getTemplateById,
  publishTemplate,
  removeTemplateFromCategories,
  updateTemplate,
};
