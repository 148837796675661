import React from "react";
import { Localized } from "../strings";

type CirclePlusProps = {
  onClick: () => void;
};

export const CirclePlus: React.FC<CirclePlusProps> = ({ onClick }) => {
  const strings = Localized.object("CREATE_WORKSPACES_SECTION");
  const tooltip = `${strings.CREATE} ${strings.FROM_TEMPLATE}`;

  return (
    <>
      <div
        className={"hidden md:flex justify-center items-center mt-8 py-6 w-full"}
        title={tooltip}
        tabIndex={0}
        aria-label={strings.CREATE_WORKSPACE}
      >
        <div
          className={
            "shadow-circle flex justify-center items-center md:w-16 md:h-16 lg:w-20 lg:h-20 bg-notification rounded-full"
          }
          onClick={onClick}
        >
          <div className={"md:text-5xl lg:text-6xl hoylu-ui-icons-plus cursor-pointer"} />
        </div>
      </div>
      <div className={"md:hidden fixed bottom-8 right-8 z-40"} title={tooltip}>
        <div
          className={
            "flex justify-center items-center w-20 h-20 bg-notification rounded-full"
          }
          onClick={onClick}
        >
          <div className={"text-6xl hoylu-ui-icons-plus cursor-pointer"} />
        </div>
      </div>
    </>
  );
}

export default CirclePlus;
