import { useDispatch, useSelector} from "react-redux";
import React, {ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Modal} from "./Modal";
import {CloseIcon} from "./modalElements/CloseIcon";
import {Localized} from "../../strings";
import {getSelectedWorkspaceDetails, uploadedSnapshot} from "../../state/workspaces/workspaces.selector";

import {
  cancelDashboardOption,
  editThumbnail,
  uploadThumbnail,
  workspaceSnapshot,
  removeThumbnail
} from "../../state/workspaces/workspaces.actions";
import SimpleWorkspaceCard from "../cards/SimpleWorkspaceCard";
import {Dropdown, Icon, Option} from "@hoylu/client-common";
import {Button} from "./modalElements/Button";
import {RootState} from "typesafe-actions";

enum ThumbnailType {
  EMOJI,
  TEMPLATE,
  CUSTOM,
  SNAPSHOT
}

type ThumbnailSelectorProps = { setBlob:  Dispatch<SetStateAction<Blob | null>> }
const CustomThumbnailSelector = ({ setBlob }:ThumbnailSelectorProps) => {

  const inputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFilename] = useState("Drag & Drop")
  const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const allowedFileSizeMB = 1; // in MB
  const strings = Localized.object("THUMBNAIL_EDITOR");

  const [err, setErr] = useState<string | null>(null);

  const checkAcceptedTypeError = (file?: File): string | null => {
    if (!file || acceptedImageTypes.includes(file.type)) return null
    return strings.ERR;
  }

  const checkSizeError = (file?: File): string | null => {
    if (!file || allowedFileSizeMB * 1024 * 1024 >= file.size) return null;
    return strings.ERR
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0];
    const err = checkAcceptedTypeError(image) ?? checkSizeError(image);
    if (image && !err) {
      setErr(null)
      setFilename(image.name);
      setBlob(image);
    } else {
      setErr(err)
      setBlob(null)
    }
  };

  const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const image = e.dataTransfer.files[0];
    const err = checkAcceptedTypeError(image) ?? checkSizeError(image);
    if (image && !err) {
      setErr(null)
      setFilename(image.name);
      setBlob(image);
    } else {
      setErr(err)
      setBlob(null)
    }
  };

  const allowDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (<>
    <div className={`border-dashed border-2 ${err ? "border-attention" : "border-indicator" } flex flex-col justify-center items-center h-full overflow-hidden`}
         onDragOver={e => allowDrop(e)}
         onDrop={e => onFileDrop(e)}>
      <input
        ref={inputRef}
        className={"hidden"}
        type="file"
        onChange={onFileChange}
        accept={acceptedImageTypes.join(", ")}
        data-test-id="hidden-input"
      />
      {err && <span className={"text-attention text-xs p-3 overflow-hidden text-ellipsis"} title={err}>{err}</span>}
      {!err && <span className={"text-indicator overflow-hidden text-ellipsis"} title={fileName}>{fileName}</span>}
      <Button additionalStyling={"btn-primary flex-shrink-0"} label={strings.BROWSE} onButtonClick={() => inputRef.current?.click()}>Browse</Button>
    </div>

  </>)
}

const SnapshotSelector = () => {
  const dispatch = useDispatch();
  const workspaceDetails = useSelector(getSelectedWorkspaceDetails);
  const strings = Localized.object("THUMBNAIL_EDITOR");

  if (!workspaceDetails) return null;
  return (
    <>
      <Icon icon={"hoylu-ui-icons-camera"}/>
      <Button additionalStyling={"btn-primary"} label={strings.TAKE_SNAPSHOT} onButtonClick={() => dispatch(workspaceSnapshot.request({
        workspaceId: workspaceDetails.workspaceId
      }))}>{strings.TAKE_SNAPSHOT}</Button>
    </>)
}
export const ThumbnailEditorDialog = () => {
  const dispatch = useDispatch();
  const workspaceDetails = useSelector(getSelectedWorkspaceDetails);
  const close = useCallback( (closeAll: boolean = false) => dispatch(closeAll ? cancelDashboardOption(): editThumbnail({flag: false})),[])
  const snapshot = useSelector((state:RootState) => uploadedSnapshot(state, workspaceDetails!.workspaceId))
  const strings = Localized.object("THUMBNAIL_EDITOR");

  const typeOptions = useMemo<Option<ThumbnailType>[]>(() => {
      const options = [
        //TODO: Part of future work
        // {label: "Emoji", value: ThumbnailType.EMOJI},
        // {label: "Template", value: ThumbnailType.TEMPLATE},
        {label: strings.CUSTOM_IMAGE, value: ThumbnailType.CUSTOM},
      ]
    if (workspaceDetails?.isLive){
      options.push({label: strings.SNAPSHOT, value: ThumbnailType.SNAPSHOT})
    }
      return options
    },[workspaceDetails?.isLive, strings])


  const [thumbnailType, setThumbnailType] = useState<Option<ThumbnailType>[]>([typeOptions[0]])
  const [thumbnailUrl, setThumbnailUrl] = useState(workspaceDetails?.thumbnailUrl)
  const [newThumbnailBlob, setNewThumbnailBlob] = useState<Blob | null>(null);

  const blob = useMemo(() => snapshot ?? newThumbnailBlob, [snapshot, newThumbnailBlob]);

  useEffect(() => {
    let tmpUri: string;
    if (blob){
      tmpUri = URL.createObjectURL(blob);
      setThumbnailUrl(tmpUri)
    }
    return () => {
      tmpUri && URL.revokeObjectURL(tmpUri)
    }
  }, [blob])

  if (!workspaceDetails) return null;
  return (
    <Modal
      handleOutsideClick={() => close()}
      padding={"p-3"}
      allowContentToOverflow={true}
    >
      <div className="w-full inline-flex justify-end items-center pb-3">
        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-row">
            <div className="text-indicator font-bold text-base ">{strings.TITLE + ": " + workspaceDetails?.workspaceId}</div>
          </div>
        </div>
        {<CloseIcon onClose={close}/>}
      </div>
      <div className={"flex flex-row w-full justify-start items-start gap-3 p-2"}>
        <SimpleWorkspaceCard workspaceDetails={{...workspaceDetails, thumbnailUrl}}>
          <span className={"truncate"} title={workspaceDetails.workspaceName}>{workspaceDetails.workspaceName}</span>
        </SimpleWorkspaceCard>
        <div className={"flex flex-col justify-start gap-1 dropdownShrink"} style={{width: "200px", height: "170px"}}>
          <Dropdown options={typeOptions} values={thumbnailType} title={strings.TYPE} showLabel={true} onChange={setThumbnailType} allowReset={false} />
          {thumbnailType[0].value === ThumbnailType.CUSTOM && <CustomThumbnailSelector setBlob={setNewThumbnailBlob}/>}
          {thumbnailType[0].value === ThumbnailType.SNAPSHOT && <SnapshotSelector/>}
        </div>
      </div>
      <div className={"flex justify-end items-center"}>
        <Button label={strings.DELETE}
                onButtonClick={() => {
                  dispatch(removeThumbnail.request({workspaceId: workspaceDetails.workspaceId}));
                  close(false)}
                }
                additionalStyling={"btn-secondary mr-auto"}>
          {strings.DELETE}
        </Button>
        <Button label={strings.CANCEL}
                onButtonClick={close}
                additionalStyling={"btn-secondary"}>
          {strings.CANCEL}
        </Button>
        <Button label={strings.SAVE}
                onButtonClick={() => {
                  blob && dispatch(uploadThumbnail.request({workspaceId: workspaceDetails.workspaceId, file: blob}));
                  close(false);
                }}
                disabled={!blob}
                additionalStyling={"btn-primary"}>
          {strings.SAVE}
        </Button>
      </div>
    </Modal>
  )
}
