import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { Localized } from '../strings';
import { CenteredContentWithLogo } from './layouts/CenteredContentWithLogo';

const mapStateToProps = (state: RootState) => ({
  appUrl: state.context.config.serviceConfig.hoylu_app
});

type Props = ReturnType<typeof mapStateToProps>;
export const BetaDisabledContent: React.FC<Props> = ({ appUrl }) => {
  const strings = Localized.object("BETA_DISABLED_CONTENT");

  return (
    <CenteredContentWithLogo>
      <h1 className="text-primary-text text-xl m-8 text-center">
        {strings.THANK_YOU}<br></br> {strings.BETA_PERIOD_ENDED}
      </h1>
      <div className="flex justify-center items-center w-full py-2 text-center">
        <a
          href={appUrl}
          type="submit"
          className="object-fill w-full flex-col items-center bg-primary rounded-full shadow-2xl py-4 px-6 max-w-xs"
        >
          <p className="text-center text-base font-body text-gray-400">
            {strings.TAKE_ME}
          </p>
        </a>
      </div>
    </CenteredContentWithLogo>
  );
} 

export default connect(mapStateToProps)(BetaDisabledContent);
