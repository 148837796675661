import React from 'react';

type SectionLayoutProps = {
  title?: string;
  width?: string;
  className?: string;
  ariaLabel?: string;
};

export const SectionLayout = ({
  title,
  width = "w-full",
  className = "",
  ariaLabel = title,
  children
}: React.PropsWithChildren<SectionLayoutProps>) => (
    <section
      className={`flex flex-col ${width} py-6 mt-8 bg-theme-section rounded-lg shadow-lg ${className}`}
      tabIndex={0}
      aria-label={ariaLabel}
    >
      <div className="xxs:text-lg xs:text-xl w-auto px-2">
        {title && <span className="text-theme-title sm:px-3 font-bold">{title}</span>}
        {children}
      </div>
    </section>
);

export default SectionLayout;
