import React, { useMemo } from "react";
import { WorkspaceWeightIndicator } from "@hoylu/client-common";

/** Calculate workspace indicator percentage based on logarithmic formula log2(weight/40) + 1 */
export function calculateWorkspaceWeightPercent(weight: number): number {
  // forumla as specified in #13421
  // Note: is clamped to 100 since WorkspaceweightIndicator does not support values > 100
  if (weight > 0) {
    return Math.min((Math.log2(weight / 40) + 1) / 12, 1) * 100;
  }
  return 0;
}

export const WorkspaceWeight = ({
  workspaceWeight,
  inWarningMode
}: {
  workspaceWeight: number | undefined | null;
  inWarningMode: boolean;
}): JSX.Element => {
  const percent = useMemo(
    () => workspaceWeight ? calculateWorkspaceWeightPercent(workspaceWeight) : 0,
    [workspaceWeight]
  );

  return (
    <div className={"text-xl absolute top-0 left-1 cursor-pointer"}>
      <WorkspaceWeightIndicator
        percent={percent}
        foregroundColor={inWarningMode ? "var(--global--error--Color)" : undefined}
      />
    </div>
  );
};
