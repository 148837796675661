import React, { CSSProperties, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { moduleDefaultImage } from "../../utils/defaultImages";
import { Localized } from "../../strings";
import {AssetId, AssetRendererProps} from "@hoylu/client-common";
import debug from "debug";
const trace = debug("hoylu:dashboard:workspace-template-card");

export type TemplateAsset = {
  templateName: string;
  templateDescription?: string;
  fetchThumbnail?: () => Promise<Response>;
  onCreate?: () => void;
  onPreview?: () => void;
} & AssetId;

export type WorkspaceTemplateCardProps = AssetRendererProps<TemplateAsset>;

const containerHeight = "h-27";
const tileWidth = "xxs:w-48 mobile:w-36 lg:w-40 xl:w-48";

const containerStyle =
  "flex " +
  "text-main-dark-blue " +
  "bg-main-light-gray " +
  `${containerHeight} ` +
  "rounded " +
  "overflow-hidden " +
  "w-full " +
  "cursor-pointer ";

const animation = "transition-all duration-100 ease-out";

const visible = `${tileWidth} visible`;
const invisible = "w-0 invisible";

export const WorkspaceTemplateCard: React.FC<AssetRendererProps<TemplateAsset>> = ({
  asset: { templateName, templateDescription, fetchThumbnail, onCreate, onPreview }
}) => {
  const [showCreate, setShowCreate] = useState(false);
  const [imgSrc, setImgSrc] = useState<string>(
    moduleDefaultImage("") // get the default workspace image because no module info here;
  );

  const truncateStyles:CSSProperties = {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical'
  }

  useAsyncEffect(
    async (isStillMounted) => {
      if (!fetchThumbnail) return;

      try {
        // fetching here to nicely handle 404's and avoid situation when default thumbnails are blinking
        const response = await fetchThumbnail();
        if (response.status != 200 || !isStillMounted()) return;
        const blob = await response.blob();
        if (isStillMounted() && blob) setImgSrc(URL.createObjectURL(blob));
      } catch (err) {
        trace("Error fetching thumbnail", { error: err });
      }
    },
    [fetchThumbnail]
  );

  const workspaceThumbnailTileClass = `${animation} ${
    showCreate ? invisible : visible
  } relative flex-shrink-0 overflow-hidden`;

  const createWorkspaceTileClass = `bg-accent hover:bg-accent-hover flex-shrink-0  ${containerHeight} ${
    showCreate ? visible : invisible
  } ${animation}`;

  return (
    <div className={containerStyle} onClick={() => setShowCreate(!showCreate)}>
      <div
        className={workspaceThumbnailTileClass}
        onClick={(e) => {
          if (onPreview) {
            e.stopPropagation();
          }
        }}
      >
        {!!onPreview && (
          <Tile
            icon={"hoylu-ui-icons-indicator-show"}
            label={Localized.string("WORKSPACE_TEMPLATES.PREVIEW")}
            classNames={`absolute right-0 left-0 bg-white opacity-0 hover:opacity-75 ${animation}`}
            onClick={onPreview}
          />
        )}
        <img
          className={"absolute h-full top-0 left-0 w-full object-cover"}
          src={imgSrc}
          alt={templateName}
          title={templateName}
        />
      </div>
      <div className={"flex flex-col gap-2 p-2 items-start flex-grow min-w-0 min-h-0"}>
        <div
          className={"max-w-full text-sm font-bold truncate"}
          title={templateName}
        >
          {templateName}
        </div>
        <div className={"max-w-full max-h-3/4 text-xs overflow-hidden"} style={truncateStyles} title={templateDescription}>
          {templateDescription}
        </div>
      </div>
      <Tile
        icon={"hoylu-ui-icons-quill"}
        label={Localized.string("WORKSPACE_TEMPLATES.CREATE")}
        classNames={createWorkspaceTileClass}
        onClick={onCreate}
      />
    </div>
  );
};

type TileProps = {
  icon: string;
  label: string;
  onClick?: () => void;
  classNames?: string;
};

const Tile = ({ icon, label, onClick, classNames = "" }: TileProps) => {
  const containerClassNames =
    "flex " +
    "flex-col " +
    "justify-center " +
    "items-center " +
    "cursor-pointer " +
    `${containerHeight} ` +
    `${classNames}`;

  return (
    <div
      className={containerClassNames}
      title={label}
      onClick={(e) => {
        onClick?.();
        e.stopPropagation();
      }}
    >
      <div className={`text-xxl ${icon}`} />
      <div className={"text-xs font-bold"}>{label}</div>
    </div>
  );
};
