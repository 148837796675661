import React from 'react';
import HoyluLogo from '../HoyluLogo';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import { FlatRippleSpinner } from '../RippleSpinner';
import { AppMode } from '../../state/mode/mode.reducer';

type SimplifiedHeaderProps = ReturnType<typeof mapStateToProps>;

export const SimplifiedHeader: React.FC<SimplifiedHeaderProps> = ({ isLoading}) => (
    <div className={"w-full bg-theme-header z-40 top-0 relative lg:fixed"}>
      <div className={"h-8 w-full bg-dark-primary"} />
      <div className="font-body flex items-center justify-between py-2 lg:px-8 xxs:px-2">
        <HoyluLogo />
      </div>
      {isLoading && <FlatRippleSpinner />}
    </div>
  );

const mapStateToProps = (state: RootState) => ({
  isLoading:
    state.mode === AppMode.LOADING_DASHBOARD ||
    state.mode === AppMode.LOADING_WORKSPACE ||
    state.context.workspaces.isLoadingSelectedProjectWorkspaces,
});

export default connect(mapStateToProps)(SimplifiedHeader);
