import React from "react";
import { Modal } from "./Modal";
import { connect } from "react-redux";
import packageJson from "../../../package.json";
import { updateVersion } from "../../state/config/config.actions";
import { Localized } from "../../strings";

import ICON_FEATURE_1 from "../../assets/images/icons/pull-plan.svg";
import ICON_FEATURE_2 from "../../assets/images/icons/text-align.svg";
import ICON_FEATURE_3 from "../../assets/images/icons/updates.svg";

type FeatureType = {
  icon: string;
  title: string;
  description: string;
};

// MATCH ORDER OF STRING.FEATURES OBJECT
const iconPaths = [ICON_FEATURE_1, ICON_FEATURE_2, ICON_FEATURE_3];

// When updating Whats New, update dashboard and electron version number
// Run on repo root: `npx lerna version minor --no-git-tag-version --no-push`
//
// They should be the same number. e.g: bump from 1.1.0 to 1.2.0
// Note: the 3rd number is automatically set by the build process and gets overwritten
//    dashboard/package.json
//    electron/package.json

const mapDispatchToProps = {
  updateVersion: updateVersion,
};

type WhatsNewDialogProps = typeof mapDispatchToProps;

const newItem: React.FC<{
  title: string;
  description: string;
}> = ({ title, description }, index) => {
  return (
    <div
      className={
        "flex flex-row py-2 pr-2 items-center md:items-start sm:py-3 sm:pr-3 md:py-4 md:pr-4"
      }
      key={index}
    >
      <div className="flex-none pr-3">
        <img src={iconPaths[index]} alt={title + " alt"} />
      </div>
      <div>
        <p className="inline">{title}</p>
        <br />
        <pre className="whitespace-pre-wrap font-inherit hidden lg:contents">
          <p className="inline text-indicator">{description}</p>
        </pre>
      </div>
    </div>
  );
};

export const WhatsNewDialog: React.FC<WhatsNewDialogProps> = ({
  updateVersion,
}) => {
  const strings = Localized.object("WHATS_NEW_DIALOG");
  return (
    <Modal zIndex="z-whatsNew">
      <div className="flex flex-col max-w-xl xxs:p-6 xs:p-8 sm:p-10">
        <div className="flex flex-row items-baseline">
          <div className="flex pr-2 h-8 w-8 items-end">
            <img
              src={require("../../assets/images/icons/new-icon.svg")}
              alt={strings.WHATS_NEW_ICON}
            />
          </div>
          <div className="flex flex-col pb-1">
            <h1 className="font-bold text-2xl sm:text-3xl">
              {strings.WHATS_NEW}
            </h1>
          </div>
        </div>

        <div className="max-h-60vh overflow-y-auto overflow-x-visible">
          {Object.values(strings.FEATURES).map((val, i) =>
            newItem(val as FeatureType, i)
          )}
        </div>

        <button
          autoFocus
          className="btn btn-primary w-2/3 mx-auto"
          onClick={() => {
            window.localStorage.setItem("version", packageJson.version);
            updateVersion(false);
          }}
          title={strings.CONTINUE}
        >
          <div className="text-sm">{strings.CONTINUE}</div>
        </button>
        <div className="text-xs text-center text-indicator max-w-2/3 mx-auto">
          {strings.BROWSER_SUGGESTION}
        </div>
      </div>
    </Modal>
  );
};

export default connect(null, mapDispatchToProps)(WhatsNewDialog);
